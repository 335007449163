/**
*
* SessionLog
*
*/

import React from 'react';
import './SessionLog.css';

import SessionItem from './SessionItem/SessionItem';

function SessionLog({ task }) {
  const sessions = task.sessions;
  const sessionsCopy = [...sessions] || [];

  sessionsCopy.sort((a, b) => {
    const aDate = new Date(a.start);
    const bDate = new Date(b.start);

    // Invalid dates always at the top to encourage them to be fixed
    if (Number.isNaN(aDate.getTime())) { return -1; }
    if (Number.isNaN(bDate.getTime())) { return 1; }

    return bDate.getTime() - aDate.getTime();
  });

  return (
    <div id="testing" className="em-sessionlog">
      { sessionsCopy.map(session => <SessionItem key={session.id} session={session} task={task} />)}
    </div>
  );
}

// SessionLog.propTypes = {
//   task: PropTypes.object.isRequired,
// };

export default SessionLog;
