/**
*
* EditColumn
*
*/

import React, { PureComponent } from 'react';

// import './EditColumn.css';

import SimpleItemForm from 'containers/Project/SimpleItemForm/SimpleItemForm';
import IconButton from 'components/TaskButtons/IconButton/IconButton';
import FauxPopover from 'components/FauxPopover/FauxPopover';

import NumberSelect from '../NumberSelect/NumberSelect';
import DeleteColumn from '../DeleteColumn/DeleteColumn';
import { IProject, IProjectColumn } from 'utils/models/projectModel';

interface IEditColumn {
  id: string;
  project: IProject;
  group: IProjectColumn;
  close: () => void;
}

class EditColumn extends PureComponent<IEditColumn, any> {
  constructor(props) {
    super(props);

    this.state = {
      tab: undefined,
      noAnimation: false,
    };
  }

  setTab(e, tab) {
    this.setState({ tab });
  }

  moveColumn(pos) {
    const props: any = this.props;
    props.project.moveColumn(props.id, pos);
    props.close();
  }

  returnToMenu() {
    this.setState({
      tab: undefined,
      noAnimation: true,
    });
  }

  renameColumn(title) {
    const props = this.props;

    props.project.updateColumn({
      ...props.group,
      title,
    });

    props.close();
  }

  render() {
    const props = this.props;
    const { tab } = this.state;

    if (tab === 'edit') {
      return (
        <SimpleItemForm
          defaultText={props.group.title}
          noAnimation
          outsideClick={props.close}
          targetName="Column"
          submit={title => this.renameColumn(title)}
          close={() => this.returnToMenu()}
        />
      );
    }

    if (tab === 'move') {
      return (
        <NumberSelect
          range={props.project.columns.length}
          selected={props.group.position}
          targetName="Move Position"
          outsideClick={props.close}
          noAnimation
          submit={pos => this.moveColumn(pos)}
          close={() => this.returnToMenu()}
        />
      );
    }

    if (tab === 'delete') {
      return (
        <DeleteColumn
          parent="column"
          child="tasks"
          back={() => this.returnToMenu()}
          close={props.close}
          isEmpty={props.project.columnIsEmpty(props.id)}
          submit={() => props.project.removeColumn(props.id)}
        />
      );
    }

    return (
      <FauxPopover noAnimation={this.state.noAnimation} outsideClick={props.close}>
        <IconButton
          onClick={e => this.setTab(e, 'edit')}
          icon={<i className="fa fa-pencil" />}
          name="Rename Column"
        />
        <IconButton
          onClick={e => this.setTab(e, 'move')}
          icon={<i className="fa fa-arrows-h" />}
          name="Move Column"
        />
        <IconButton
          onClick={e => this.setTab(e, 'delete')}
          icon={<i className="fa fa-trash" />}
          name="Delete Column"
        />
      </FauxPopover>
    );
  }
}

export default EditColumn;
