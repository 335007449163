/**
*
* DynamicSelect
*
*/

import React from 'react';

import './DynamicSelect.css';
import classNames from 'classnames';

export interface IDynamicOption {
  id: string;
  title: string;
}

interface IDynamicSelect {
  options: IDynamicOption[];
  onSelect: (value: string) => void;
  selected: string;
  disabled?: boolean;
}

function DynamicSelect(props: IDynamicSelect) {
  const options = props.options || [];
  const selected = props.selected;
  const onSelect = props.onSelect;
  const disabled = props.disabled || false;

  return (
    <div className="radio-wrapper">
      { options.map(option => (
        <div
          key={option.id}
          className={classNames('radio-item', disabled && 'disabled', option.id === selected && 'active')}
          onClick={() => !disabled && onSelect && onSelect(option.id)}
        >
          <div
            className="radio-item-label"
          >
            { option.title }
          </div>
        </div>
      ))}
    </div>
  );
}

export default DynamicSelect;
