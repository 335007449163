/**
*
* PageHeader
*
*/

import React from 'react';

import './PageHeader.css';
import className from 'classnames';

import { ScreenContext } from 'utils/context';

function PageHeader({ header, menu, buttons, background }: any) {
  const style: any = { };

  // if (background) {
  //   style.backgroundColor = background;
  // }

  return (
    <ScreenContext.Consumer>
      {screen => (
        <div className="page-toolbar" style={style}>
          {screen.mobileView && (
            <div className="navbar">
              <div
                onClick={() => screen.toggleMobileNav()}
                className={
                  className(
                    'navbar-btn fa no-border',
                    screen.mobileNavOpen ? 'fa-times' : 'fa-bars',
                  )
                }
              />
            </div>
          )}
          <div className="main-header">
            <h2>{ header }</h2>
            { menu && <div className="page-toolbar-menu">{ menu }</div> }
            { buttons && <div className="page-toolbar-btn-row">{ buttons }</div> }
          </div>
        </div>
      )}
    </ScreenContext.Consumer>
  );
}

export default PageHeader;
