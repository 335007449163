/**
*
* EditPanelText
*
*/

import React, { PureComponent } from 'react';
// import './EditPanelText.css';

import classNames from 'classnames';

import { withFormik } from 'formik';
import FormikInput from 'components/FormikInput/FormikInput';

class EditPanelText extends PureComponent<any> {
  render() {
    const {
      close,
      forwardRef,
      handleSubmit,
      className,
      style,
    } = this.props;

    return (
      <form
        style={style}
        ref={forwardRef}
        className={classNames("panel-edit-form inline", className)}
        onSubmit={handleSubmit}
      >
        <FormikInput
          focusOnMount
          name="value"
          formikBag={this.props}
        />
        <div className="form-buttons inline">
          <button
            type="submit"
            className="primary"
          >
            <i className="fa fa-check" />
          </button>
          <button
            onClick={close}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </form>
    );
  }
}

const ValidatedPanelText = withFormik({
  mapPropsToValues: (props: any) => ({
    value: props.value || '',
  }),

  // Custom sync validation
  validate: (values, props) => {
    const errors: any = {};
    const value = values.value;
    if (!value || value === '') {
      errors.value = 'Required';
    }
    if (typeof value !== 'string') {
      errors.value = 'Invalid';
    }
    if (!value && props.validate && props.validate(value)) {
      errors.value = props.validate(value)
    }
    return errors;
  },

  handleSubmit: (values, { props }) => {
    const { value } = values;
    const { save } = props;
    save(value);
  },

  displayName: 'Edit Panel Text', // helps with React DevTools
})(EditPanelText);

// EditPanelText.propTypes = {
//   save: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
// };

export default ValidatedPanelText;
