/**
*
* WindowClickWrapper
*
*/

import { PureComponent } from 'react';

// import './WindowClickWrapper.css';

let clickWrapperCount = 0;

export function activeClickWrappers() {
  return clickWrapperCount;
}

class WindowClickWrapper extends PureComponent<any> {
  node;
  boundClickEvent;

  constructor(props) {
    super(props);

    this.boundClickEvent = this.emitEvent.bind(this);
  }

  componentDidMount() {
    clickWrapperCount += 1;
    setTimeout(() => {
      // The problem with using 'capture' to stopPropagation() is it prevents
      // the UI from handling the click.
      window.addEventListener('click', this.boundClickEvent, false);
    }, 0);
  }

  componentWillUnmount() {
    clickWrapperCount -= 1;
    window.removeEventListener('click', this.boundClickEvent, false);
  }

  setRef(node) {
    const innerRef = this.props.innerRef;

    this.node = node;
    if (innerRef) innerRef(node);
  }

  emitEvent(e) {
    const props = this.props;

    // As the component handles the click first, if the item removes itself from them
    // DOM then this code wont find it. To prevent this use stopPropagation()
    if (this.node && !this.node.contains(e.target)) {
      if (props.outsideClick) props.outsideClick(e);
    }
  }

  render() {
    const renderProps = {
      ref: this.setRef.bind(this),
    };
    const { children }: any = this.props;

    return children(renderProps);
  }
}

export default WindowClickWrapper;
