import moment from "moment";

const thisYearStr = moment().format('YYYY');

export function asNumber(num: any, fallback: number = 0): number {
  if (Number.isFinite(num)) {
    return num;
  }

  return fallback
}

export function formatDate(date, fullMonth = false) {
  const mDate = moment(date);
  const mFormat = fullMonth ? 'MMMM' : 'MMM'
  const dFormat = mDate.format(`${mFormat} Do`);
  const dYear = mDate.format('YYYY');

  if (dYear === thisYearStr) {
    return dFormat;
  }

  return `${dFormat}, ${dYear}`
}

export function isDateInRange(date, start, end) {
  if (!date) {
    return false;
  }

  const completedDate = new Date(date);
  return completedDate > start && completedDate < end;
}

export function isDurationValid(num) {
  if (typeof num !== 'number') {
    return false;
  }

  if (Number.isNaN(num) || num < 0) {
    return false;
  }

  return true;
}

export function isDateValid(date) {
  if (!date || typeof date !== 'object') {
    return false;
  }

  if (!date.getTime || Number.isNaN(date.getTime())) {
    return false;
  }

  return true;
}

export function formatTime(date, showSecs = false) {
  let testDate = date;

  if (typeof date === 'string') {
    testDate = Date.parse(date);
  }

  if (Number.isNaN(testDate)) {
    return 'Error';
  }
  const dateObj = new Date(date);
  const timeString = dateObj.toTimeString();
  return timeString.substr(0, showSecs ? 8 : 5);
}

export function explodedDuration(duration = 0, showSecs = false) {
  const decSecs = duration / 1000;
  const decMin = decSecs / 60;
  const decHour = decMin / 60;

  const hours = Math.floor(decHour);
  const minutes = Math.floor(decMin % 60);
  const seconds = showSecs && Math.floor(decSecs % 60);

  return {
    hours,
    minutes,
    seconds,
  };
}

export function formatDuration(duration = 0, showSecs = false) {
  const decSecs = duration / 1000;
  const decMin = decSecs / 60;
  const decHour = decMin / 60;

  const minutes = Math.floor(decMin % 60).toString();
  const hours = Math.floor(decHour).toString();

  let out = `${hours}:${minutes.padStart(2, '0')}`;

  if (showSecs) {
    const seconds = Math.floor(decSecs % 60).toString();
    out += `:${seconds.padStart(2, '0')}`;
  }

  return out;
}

export function formatyyyymmdd(date, sep = '') {
  const d = new Date(date);
  const year = d.getFullYear().toString();
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join(sep);
}


export function thisWeek() {
  const end =  moment().endOf('isoWeek');
  const start = moment().startOf('isoWeek');
  return { start, end };
}

export function thisMonth() {
  const end =  moment().endOf('month');
  const start = moment().startOf('month');
  return { start, end };
}

export function thisQuarter() {
  const end =  moment().endOf('quarter');
  const start = moment().startOf('quarter');
  return { start, end };
}

export function thisYear() {
  const end =  moment().endOf('year');
  const start = moment().startOf('year');
  return { start, end };
}