/**
*
* DateRangePopup
*
*/

import React, { useState } from 'react';

import './DateRangePopup.css';

import Popover from 'components/Popover/Popover';
import DayPicker from 'react-day-picker';
import { thisWeek, thisQuarter, thisMonth, thisYear } from 'utils/date';
import moment from 'moment';

function DateRangePopup({ start, end, save, close, windowBoundary}: any) {
  const [localStart, setStart] = useState(start ? new Date(start) : undefined);
  const [localEnd, setEnd] = useState(end ? new Date(end) : undefined);

  const openStart = localStart || new Date();
  const openEnd = localEnd || new Date();

  const saveAndClose = (s, e) => {
    save(moment(s).startOf('D'), moment(e).endOf('D'));
    close()
  }

  const quickWeek = () => {
    const { start, end } = thisWeek();
    saveAndClose(start, end);
  }

  const quickQuarter = () => {
    const { start, end } = thisQuarter();
    saveAndClose(start, end);
  }

  const quickMonth = () => {
    const { start, end } = thisMonth();
    saveAndClose(start, end);
  }

  const quickYear = () => {
    const { start, end } = thisYear();
    saveAndClose(start, end);
  }


  return (
    <Popover
      windowBoundary={windowBoundary}
      outsideClick={close}
      className="em-date-popup"
    >
      <DayPicker
        month={openStart}
        selectedDays={localStart}
        onDayClick={(d) => setStart(d)}
        firstDayOfWeek={1}
      />
      <DayPicker
        month={openEnd}
        selectedDays={localEnd}
        onDayClick={(d) => setEnd(d)}
        firstDayOfWeek={1}
      />
      <div className="quick-btns">
        <button onClick={quickWeek}>Week</button>
        <button onClick={quickMonth}>Month</button>
        <button onClick={quickQuarter}>Quarter</button>
        <button onClick={quickYear}>Year</button>
      
      </div>
      <button onClick={() => saveAndClose(localStart, localEnd)}>Save</button>
    </Popover>
  );
}

export default DateRangePopup;
