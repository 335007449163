/**
*
* ProjectTray
*
*/

import React, { useState } from 'react';

import './ProjectTray.css';

import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useDataContext } from 'utils/context';
import { colourPicker } from 'utils/core';

import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';

const projectFilter = (text, id) => {
  const valueUC = text.toUpperCase();

  return (project) => {
    const { title } = project.properties;
    const fields = [title];
    const fieldsLength = fields.length;

    if (project.association_id !== id) {
      return false;
    }

    if (!text) {
      return true;
    }

    for (let i = 0; i < fieldsLength; i++) {
      const fieldUC = fields[i].toUpperCase();
      if (fieldUC.includes(valueUC)) return true;
    }

    return false;
  };
};

function SimpleCard(props) {
  const { project, group, close } = props;
  const { stats } = project;
  const classList = ['simpleCard'];
  const style: any = { };

  const isOverdue = stats.overdue;
  const isCompleted = stats.completed;
  const isActive = stats.active;


  const colorKey = group.color;
  if (colorKey) {
    style.backgroundColor = colourPicker[colorKey];
  }

  return (
    <div className="listview-group-item">
      <Link
        onClick={close}
        to={`/${project.id}/tasks`}
        className={classList.join(' ')}
        style={style}
      >
        { project.properties.title }
        { isOverdue && <div className="fa fa-calendar-o" /> }
        { isCompleted && <div className="fa fa-check" /> }
        { isActive && <div className="fa fa-play" /> }
      </Link>
    </div>
  );
}

function ProjectTray({ close, show }) {
  const [filter, setFilter] = useState('')
  const data = useDataContext()

  return (
    <CSSTransition unmountOnExit appear in={show} timeout={150} classNames="fade">
      <WindowClickWrapper outsideClick={close}>
        { renderProps => (
          <div ref={renderProps.ref} className="projectTray">
            <div className="trayHead">
              <input autoFocus placeholder="Search..." value={filter} onChange={e => setFilter(e.target.value)} />
            </div>
            <div className="trayBody">
              { data.groups.map((group) => {
                const projects = data.projects.filter(projectFilter(filter, group.id));

                return (
                  <div className="trayGroup">
                    <h4>{ group.title }</h4>
                    { projects.map(project => (
                      <SimpleCard
                        close={close}
                        project={project}
                        group={group}
                      />
                    ))}
                    { !projects.length && <div className="simpleCard no-results">No Projects</div>}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </WindowClickWrapper>
    </CSSTransition>
  );
}

export default ProjectTray;
