/**
*
* ProjectCard
*
*/

import React from 'react';

import './ProjectCard.css';
import { colourPicker } from 'utils/core';

import { Link } from 'react-router-dom';
import EmProgress from 'components/EmProgress/EmProgress';
import { formatCurrency } from '../../../utils/constants';
import { IProject } from 'utils/models/projectModel';
import { IGroup } from 'utils/models/groupModel';

interface IProjectCard {
  item: IProject;
  group: IGroup;
}

function ProjectCard(props: IProjectCard) {
  const { item: project, group } = props;
  const proj_props = project.properties;
  const proj_stats = project.stats;

  const title = proj_props.title;
  const classList = ['project-card'];
  const style: any = { };

  if (proj_stats.completed) {
    classList.push('complete');
  } else {
    const colorKey = group.color;
    if (colorKey) style.backgroundColor = colourPicker[colorKey];
  }

  const isOverdue = proj_stats.overdue;
  const isCompleted = proj_stats.completed;
  const isActive = proj_stats.active;

  const completedPcnt = project.percentComplete();

  return (
    <Link
      to={`/${project.id}/tasks`}
      className={classList.join(' ')}
      style={style}
    >
      <div className="header">
        <h4>{ title }</h4>
        { isOverdue && <div className="fa fa-calendar-o" /> }
        { isCompleted && <div className="fa fa-check" /> }
        { isActive && <div className="fa fa-play" /> }
      </div>
      <div className="body">
        <div className="card-stats">
          { project.hasCostTracking() && (
            <div>
              {formatCurrency(proj_stats.valueRecieved, true)}
              { project.hasAddedTracking() && (` / ${formatCurrency(proj_stats.value, true)}`)}
            </div>
          ) }
          { project.hasTimeTracking() && (
            <div>
              {project.timeDisplay()}
              {' hrs'}
            </div>
          ) }
        </div>
        <div className="card-progress">
          <div className="pcnt">
            { completedPcnt }
            {' %'}
          </div>
          <EmProgress progress={completedPcnt} className="card-progress-bar" />
        </div>
      </div>
    </Link>
  );
}


export default ProjectCard;
