/**
 *
 * Review
 *
 */

import React, { Component } from 'react';


import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import ReviewRecent from './ReviewRecent/ReviewRecent';
import ReviewPriority from './ReviewPriority/ReviewPriority';
import ReviewDeadline from './ReviewDeadline/ReviewDeadline';

import './Review.css';
import { isPremium } from 'utils/premium';
import PremiumBlockCard from 'components/PremiumBlockCard/PremiumBlockCard';

const ROUTE_RECENT = 'recent';
const ROUTE_DEADLINE = 'deadline';
const ROUTE_PRIORITY = 'priority';

const premiumBlock = (child) => {
  if (!isPremium()) {
    return (
      <PremiumBlockCard
        info="This page provides an overview across all your projects. Easily see tasks you have recently worked on, upcoming deadlines or high priority items."
      />
    )
  }

  return child
}

class Review extends Component<any, any> {
  render() {
    const { match } = this.props;

    return (
      <div className="page-wrapper review-page">
        <PageHeader header="Task Summary" />
        <div className="sub-nav">
          <NavLink
            className="sub-nav-a"
            activeClassName="active"
            to={`${match.url}/${ROUTE_RECENT}`}
          >
            <i className="fa fa-repeat" />
            {'Recent'}
          </NavLink>
          <NavLink
            className="sub-nav-a"
            activeClassName="active"
            to={`${match.url}/${ROUTE_DEADLINE}`}
          >
            <i className="fa fa-calendar" />
            {'Deadline'}
          </NavLink>
          <NavLink
            className="sub-nav-a"
            activeClassName="active"
            to={`${match.url}/${ROUTE_PRIORITY}`}
          >
            <i className="fa fa-exclamation-triangle" />
            {'Priority'}
          </NavLink>
        </div>
        <div className="page-body">
          <Switch>
            <Route
              path={`${match.url}/${ROUTE_RECENT}`}
              render={() => premiumBlock(<ReviewRecent />)}
            />
            <Route
              path={`${match.url}/${ROUTE_DEADLINE}`}
              render={() => premiumBlock(<ReviewDeadline />)}
            />
            <Route
              path={`${match.url}/${ROUTE_PRIORITY}`}
              render={() => premiumBlock(<ReviewPriority />)}
            />
            <Redirect to={`${match.url}/recent`} />
          </Switch>
        </div>
      </div>
    );
  }
}


export default Review;
