/**
*
* NavBar
*
*/

import React from 'react';
import { NavLink } from 'react-router-dom';
import className from 'classnames';
import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';
import { useSavingError } from 'utils/api';

function links(props, savingErr, clickWrapperRef?) {
  const {
    toggleTray,
    toggleSyncStatus,
    showTray,
    showSync,
    screen
  } = props;

  const { mobileView, toggleMobileNav } = screen;

  const openTray = showTray || showSync;
  const activeClassName = !openTray && 'active';

  return (
    <div ref={clickWrapperRef} className={mobileView ? 'navbar-mobile' : 'navbar'}>
      {!mobileView && <div className="navbar-btn eclipse eclipse-logo" />}
      <NavLink
        to="/projects"
        onClick={toggleMobileNav}
        activeClassName={activeClassName}
        className="navbar-btn"
      >
        <i className="fa fa-home" />
        <p>Projects</p>
      </NavLink>
      {!mobileView && (
        <div
          onClick={toggleTray}
          className={className('navbar-btn fa fa-bolt', showTray && 'active')}
        />
      )}
      <NavLink
        to="/summary"
        onClick={toggleMobileNav}
        activeClassName={activeClassName}
        className="navbar-btn"
      >
        <i className="fa fa-eye" />
        <p>Task Summary</p>
      </NavLink>
      <NavLink
        to="/reports"
        onClick={toggleMobileNav}
        activeClassName={activeClassName}
        className="navbar-btn"
      >
        <i className="fa fa-bar-chart" />
        <p>Reports</p>
      </NavLink>
      <div className="bottom-section">
        <NavLink
          to="/upgrade"
          onClick={toggleMobileNav}
          activeClassName={activeClassName}
          className="navbar-btn"
        >
          <i className="fa fa-star" />
          <p>Upgrade</p>
        </NavLink>
        {!mobileView && (
          <div
            onClick={toggleSyncStatus}
            className={className('navbar-btn fa fa-refresh', savingErr && 'error', showSync && 'active')}
          />
        )}
        <NavLink
          to="/user"
          onClick={toggleMobileNav}
          activeClassName={activeClassName}
          className="navbar-btn"
        >
          <i className="fa fa-user" />
          <p>Profile</p>
        </NavLink>
      </div>
    </div>
  );
}

function NavBar(props) {
  const { screen } = props;
  const { mobileView, toggleMobileNav } = screen;
  const [savingErr] = useSavingError();

  if (mobileView) {
    return (
      <WindowClickWrapper outsideClick={toggleMobileNav}>
        {renderProps => links(props, savingErr, renderProps.ref)}
      </WindowClickWrapper>
    );
  }

  return links(props, savingErr);
}

export default NavBar;
