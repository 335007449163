/**
*
* AddProjectCard
*
*/

import React from 'react';
import FullscreenModal from 'components/FullscreenModal/FullscreenModal';

import './PremiumBlockModal.css';
import PremiumBlockCard from 'components/PremiumBlockCard/PremiumBlockCard';

function PremiumBlockModal({ close, info }) {
  return (
    <FullscreenModal close={close} className="add-project-form">
      <h2>Export Project</h2>
      <PremiumBlockCard info={info} />
    </FullscreenModal>
  );
}

export default PremiumBlockModal;
