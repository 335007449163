
export const isLocal = () => window.origin && window.origin.includes('http://localhost:3000');

// THESE SHOULD ALL BE FALSE IN PROD
export const localServer = isLocal() ? true : false;
export const stagingServer = false;
export const localDB = false;

const stagingAzureServerURL = 'https://em-pwa-beta.azurewebsites.net';
const prodAzureServerURL = 'https://eclipsemanager.azurewebsites.net';
const localServerURL = 'http://localhost:4000';

let activeURL = prodAzureServerURL;

if (stagingServer) {
    activeURL = stagingAzureServerURL;
} else if (localServer) {
    activeURL = localServerURL;
}

export const serverURL = activeURL;
