/**
*
* WelcomeDisplay
*
*/

import React from 'react';
import { Link } from 'react-router-dom';

import './WelcomeDisplay.css';

function WelcomeDisplay({ showAddProject }) {
  return (
    <div className="weclome-wrapper">
      <h2>Welcome to Eclipse Manager</h2>
      <p className="welcome-hero">You dont have any Projects yet. Click <button onClick={() => showAddProject()}>"Add Project"</button> to get started.</p>
      <p className="welcome-hero">{'Need some help? Check out some of the resources below.'}</p>
      <ul>
        <li><a href="https://eclipsemanager.com/guide" target="_blank" rel="noopener noreferrer">View our help page</a></li>
        <li><Link to="/user">Migrate data from the legacy Windows app</Link></li>
        <li><Link to="/user">Get more help</Link></li>
      </ul>
    </div>
  );
}


export default WelcomeDisplay;
