import axios from 'axios';
import { localServer, serverURL } from './config';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from './useObservable';
import { authProvider } from './auth-wrapper';

const savingSubject = new BehaviorSubject(false);
const savingError = new BehaviorSubject(false);

export const useIsSaving = () => useObservable(savingSubject);
export const useSavingError = () => useObservable(savingError);

const onSuccess = (succ) => {
  savingSubject.next(false);
  savingError.next(false);
  return succ;
}

const onError = (err) => {
  savingSubject.next(false);
  savingError.next(true);
  console.debug(err);
  return Promise.reject(err);

}

const wrapReq = (req) => {
  savingSubject.next(true);
  return req.then(onSuccess, () => {
    return req.then(onSuccess, onError);
  });
};

async function defaultOptions() {
  let token = { accessToken: 'token' };
  
  if (!localServer) {
    token = await authProvider.getAccessToken();
  }

  return {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
}

export async function getData() {
  const url = `${serverURL}/data`;
  const options = await defaultOptions();

  console.debug('Main Get Data');

  return wrapReq(axios.get(url, options));
}

// Group Functions

export async function dbDeleteGroup(id) {
  const url = `${serverURL}/associations/delete/${id}`;
  const options = await defaultOptions();

  console.debug(`DB Delete Group ${id}`);

  return wrapReq(axios.delete(url, options));
}

export async function dbUpdateGroup(group) {
  const url = `${serverURL}/associations/update/${group.id}`;
  const options = await defaultOptions();

  console.debug(`DB Update Group ${group.id}`);

  return wrapReq(axios.put(url, group, options));
}

export async function dbInsertGroup(group) {
  const url = `${serverURL}/associations/insert`;
  const options = await defaultOptions();

  console.debug('DB Insert Group');

  return wrapReq(axios.post(url, group, options));
}

// Project Functions

export async function dbUpdateProject(project) {
  const url = `${serverURL}/projects/update/${project.id}`;
  const options = await defaultOptions();

  console.debug(`DB Update Project ${project.id}`);

  return wrapReq(axios.put(url, project, options));
}

export async function dbDeleteProject(id) {
  const url = `${serverURL}/projects/delete/${id}`;
  const options = await defaultOptions();

  console.debug(`DB Delete Project ${id}`);

  return wrapReq(axios.delete(url, options));
}

export async function dbInsertProject(project, tasks) {
  const url = `${serverURL}/projects/insert`;
  const options = await defaultOptions();

  console.debug('DB Insert Project');

  return wrapReq(axios.post(url, { project, tasks }, options));
}

// Task Functions

export async function dbDeleteTask(id) {
  const url = `${serverURL}/tasks/delete/${id}`;
  const options = await defaultOptions();

  console.debug(`DB Delete Task ${id}`);
  savingSubject.next(true);

  return wrapReq(axios.delete(url, options));
}

export async function dbUpdateTask(task) {
  const url = `${serverURL}/tasks/update/${task.id}`;
  const options = await defaultOptions();

  console.debug(`DB Update Task ${task.id}`);

  return wrapReq(axios.put(url, task, options));
}

export async function dbMultiUpdateTask(tasks) {
  const url = `${serverURL}/tasks/multiUpdate`;
  const options = await defaultOptions();

  console.debug(`DB Update Task ${tasks.map(task => task.id).join(', ')}`);

  return wrapReq(axios.put(url, tasks, options));
}

export async function dbInsertTask(task) {
  const url = `${serverURL}/tasks/insert`;
  const options = await defaultOptions();

  console.debug('DB Insert Task');

  return wrapReq(axios.post(url, task, options));
}

// Other

export async function dbMigrate(liveToken) {
  const url = `${serverURL}/migrate/legacy`;
  const options = await defaultOptions();
  const body = { liveToken }

  console.debug('Migrated Data');

  return wrapReq(axios.put(url, body, options));
}

export async function dbPreferencesUpsert(preferences) {
  const url = `${serverURL}/preferences/upsert`;
  const options = await defaultOptions();

  console.debug('Upsert Preferences');

  return wrapReq(axios.put(url, preferences, options));
}
