

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isLocal } from './config';

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'fe92d05b-917d-4f68-9466-af903fcacae3',
        enableAutoRouteTracking: true
    } 
});

if (isLocal()) {
    console.log('Telemetry Disabled')
    appInsights.config.disableTelemetry = true
}

appInsights.loadAppInsights();
appInsights.trackMetric({ name: 'app-start', average: 1 })
  