/**
 *
 * Reports
 *
 */

import React, { useState } from 'react';

// import styles from './Reports.css';

import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import ReportWrapper, { ROUTE_PROGRESS, ROUTE_FINANCE, ROUTE_TIME } from './ReportWrapper/ReportWrapper';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Manager, Reference } from 'react-popper';

import './Reports.css';
import DateRangePopup from 'components/DateRangePopup/DateRangePopup';
import { formatDate, thisWeek } from 'utils/date';
import { isPremium } from 'utils/premium';
import PremiumBlockCard from 'components/PremiumBlockCard/PremiumBlockCard';

const { start: dStart, end: dEnd } = thisWeek();

const DateBtn = ({ start, end, save }) => {
  const [open, setOpen] = useState(false)
  return  (
    <Manager>
      <Reference>
        { renderProps => (
          <button className="dateRangeSelect" onClick={() => setOpen(true)} ref={renderProps.ref}>
            { formatDate(start, true) } - { formatDate(end, true) }
          </button>
        )}
      </Reference>
      { open && ( 
        <DateRangePopup
          start={start}
          end={end}
          close={() => setOpen(false)} 
          save={save}
        /> 
      )}
    </Manager>
  );
}

const premiumBlock = (child) => {
  if (!isPremium()) {
    return (
      <PremiumBlockCard
        info="This page provides charts and breakdowns of what you have achieved. View completed tasks, time spent or value recieved."
      />
    )
  }

  return child
}

function Reports({ match, history }: any) {
  const [startDate, setStartDate] = useState(dStart);
  const [endDate, setEndDate] = useState(dEnd);

  const setDates = (s, e) => {
    setStartDate(s);
    setEndDate(e);
  }

  return (
    <div className="page-wrapper reports-page">
      <PageHeader
        header="Reports"
      />
      <div className="sub-nav">
        <NavLink
          className="sub-nav-a"
          activeClassName="active"
          to={`${match.url}/${ROUTE_PROGRESS}`}
        >
          <i className="fa fa-check-square-o" />
          {'Progress'}
        </NavLink>
        <NavLink
          className="sub-nav-a"
          activeClassName="active"
          to={`${match.url}/${ROUTE_FINANCE}`}
        >
          <i className="fa fa-usd" />
          {'Finance'}
        </NavLink>
        <NavLink
          className="sub-nav-a"
          activeClassName="active"
          to={`${match.url}/${ROUTE_TIME}`}
        >
          <i className="fa fa-clock-o" />
          {'Time'}
        </NavLink>
        <DateBtn start={startDate} end={endDate} save={setDates} />
      </div>
      <div className="page-body vert-scroll-always">
        <Switch>
          <Route
            path={`${match.url}/${ROUTE_PROGRESS}`}
            render={() => premiumBlock(<ReportWrapper key={ROUTE_PROGRESS} startDate={startDate} endDate={endDate} value={ROUTE_PROGRESS} />)}
          />
          <Route
            path={`${match.url}/${ROUTE_FINANCE}`}
            render={() => premiumBlock(<ReportWrapper key={ROUTE_FINANCE} startDate={startDate} endDate={endDate} value={ROUTE_FINANCE} />)}
          />
          <Route
            path={`${match.url}/${ROUTE_TIME}`}
            render={() => premiumBlock(<ReportWrapper key={ROUTE_TIME} startDate={startDate} endDate={endDate} value={ROUTE_TIME} />)}
          />
          <Redirect to={`${match.url}/progress`} />
        </Switch>
      </div>
    </div>
  );
}

export default Reports;
