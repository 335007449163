/**
 *
 * Overview
 *
 */

import React, { useState } from 'react';

import './Overview.css';

import classNames from 'classnames';

import { useDataContext } from 'utils/context';

import PageHeader from 'components/PageHeader/PageHeader';
import ListView from 'components/ListView/ListView';
import EditGroup from 'components/EditColumn/EditGroup/EditGroup';
import WelcomeDisplay from 'components/WelcomeDisplay/WelcomeDisplay';

import { CSSTransition } from 'react-transition-group';
import ProjectCard from './ProjectCard/ProjectCard';
import AddProjectCard from './AddProjectCard/AddProjectCard';
import AssocHeader from './AssocHeader/AssocHeader';
import AddGroupCard from './AddGroupCard/AddGroupCard';
import { newGroup } from '../../utils/models/groupModel';

function getDefaultTemplate(projects, groups, groupId) {
  const groupLength = groups.length;
  const insideGroup = projects.find(project => project.association_id === groupId);
  if (insideGroup) return insideGroup.id;

  for (let i = 0; i < groupLength; i++) {
    const nextId = groups[0].id;
    const exists = projects.find(project => project.association_id === nextId);
    if (exists) return exists.id;
  }

  return 'none';
}

function addGroup(name, color, groups) {
  const next = newGroup(name, color);
  groups.insert(next);
}

function Overview() {
  const data = useDataContext();
  const [addProject, setAddProject] = useState(false);
  const [groupId, setGroupId] = useState(-1);
  const hasData = data.hasData();

  function showAddProject(groupId) {
    setAddProject(true);
    setGroupId(groupId);
  }

  function hideAddProject() {
    setAddProject(false);
    setGroupId(-1);
  }

  return (
    <div className={classNames('page-wrapper', !hasData && 'no-data')}>
      <PageHeader header="Projects" />
      <div className="page-body">
        <CSSTransition in appear timeout={300} classNames="fade">
          <ListView
            className="overview-listview"
            items={data.projects}
            groups={data.groups}
            groupKeySelector="id"
            itemGroupSelector="association_id"
            addText="Add Project"
            tailName={hasData && 'Association'}
            addClick={id => showAddProject(id)}
            groupRender={renderProps => <AssocHeader {...renderProps} />}
            itemRender={renderProps => <ProjectCard {...renderProps} />}
            addColumnForm={renderProps => (
              <AddGroupCard {...renderProps} submit={(title, color) => addGroup(title, color, data.groups)} />
            )}
            groupEditForm={renderProps => (
              <EditGroup {...renderProps} />
            )}
            bodyComponent={!hasData && <WelcomeDisplay showAddProject={showAddProject} />}
          />
        </CSSTransition>
      </div>
      { addProject && (
        <AddProjectCard
          data={data}
          templateId={getDefaultTemplate(data.projects, data.groups, groupId)}
          groupId={groupId}
          close={() => hideAddProject()}
        />
      )}
    </div>
  );
}


export default Overview;
