/**
*
* TaskFilterPopup
*
*/

import React, { PureComponent, Fragment } from 'react';
//import './TaskFilterPopup.css';

import classNames from 'classnames';
import Popover from 'components/Popover/Popover';

import { PRIORITY_IGNORE } from '../../../utils/constants';

const completedFilterId = 'completedFilter';
const ignoredFilterId = 'ignoredFilter';
const freeTextSearchId = 'freeTextSearch';

class TaskFilterPopup extends PureComponent<any> {
  input
  
  componentDidMount() {
    this.input.focus();
  }

  freeTextFilter(e) {
    const { project } = this.props;
    const { value } = e.target;

    if (!value) {
      project.clearFilter(freeTextSearchId);
    } else {
      const valueUC = value.toUpperCase();
      project.setFilter({
        id: freeTextSearchId,
        value,
        filter: (task) => {
          const { title, description } = task.properties;
          const fields = [title, description];
          const fieldsLength = fields.length;

          for (let i = 0; i < fieldsLength; i++) {
            const fieldUC = fields[i].toUpperCase();
            if (fieldUC.includes(valueUC)) return true;
          }

          return false;
        },
      });
    }
  }

  toggleCompletedFilter() {
    const { project } = this.props;
    const hasFilter = !!project.getFilter(completedFilterId);

    if (hasFilter) {
      project.clearFilter(completedFilterId);
    } else {
      project.setFilter({
        id: completedFilterId,
        on: true,
        filter: task => !task.properties.completed,
      });
    }
  }

  toggleIgnoredFilter() {
    const { project } = this.props;
    const hasFilter = !!project.getFilter(ignoredFilterId);

    if (hasFilter) {
      project.clearFilter(ignoredFilterId);
    } else {
      project.setFilter({
        id: ignoredFilterId,
        on: true,
        filter: task => task.properties.priority !== PRIORITY_IGNORE,
      });
    }
  }

  render() {
    const { project, outsideClick } = this.props;

    const hasFilter = project.hasFilter();
    const ignoredFilter = !!project.getFilter(ignoredFilterId);
    const completedFilter = !!project.getFilter(completedFilterId);
    const freeTextFilter = project.getFilter(freeTextSearchId);

    return (
      <Popover
        outsideClick={outsideClick}
        className="task-filter-form"
      >
        <input
          ref={ref => (this.input = ref)}
          onChange={e => this.freeTextFilter(e)}
          value={freeTextFilter ? freeTextFilter.value : ''}
          placeholder="Search..."
        />
        <div className="break" />
        <button
          type="button"
          className={classNames('toggle', completedFilter && 'active')}
          onClick={() => this.toggleCompletedFilter()}
        >
          { 'Completed Tasks' }
          <i className="fa fa-check" />
        </button>
        <button
          type="button"
          className={classNames('toggle', ignoredFilter && 'active')}
          onClick={() => this.toggleIgnoredFilter()}
        >
          { 'Ignored Tasks' }
          <i className="fa fa-check" />
        </button>
        { hasFilter && (
          <Fragment>
            <div className="break" />
            <button
              type="button"
              onClick={() => project.clearAllFilters()}
            >
              <i className="fa fa-undo" />
              { ' Reset All' }
            </button>
          </Fragment>
        )}
      </Popover>
    );
  }
}

export default TaskFilterPopup;
