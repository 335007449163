import { BehaviorSubject } from 'rxjs';
import { useObservable } from './useObservable';

export const userPreferences = new BehaviorSubject({});

export const getPreference = (key, fallback) => {
  const preferences = userPreferences.getValue();
  return preferences[key] || fallback;
}

export const useUserPreferences = () => useObservable(userPreferences);