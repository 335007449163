/**
*
* MoveTaskForm
*
*/

import React, { PureComponent } from 'react';
import './SessionItemEdit.css';

import DayPicker from 'react-day-picker';
import { explodedDuration, isDateValid, isDurationValid, asNumber } from 'utils/date';


import Popover from 'components/Popover/Popover';
import TimePicker from '../../../TimePicker/TimePicker';
import WindowClickWrapper from '../../../WindowClickWrapper/WindowClickWrapper';

function calcDuration(hours, minutes) {
  return 60 * 1000 * (asNumber(minutes) + (asNumber(hours) * 60));
}

const newBlankSession = (task) => {
  const parent = task.getParent();
  return {
    rate: parent.properties.defaultRate,
    duration: 0,
    start: new Date().toISOString(),
  };
};

class SessionItemEdit extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    let { session, task } = props;

    if (!session) {
      session = newBlankSession(task);
    }

    const duration = session.duration;
    const rate = session.rate;
    const startDate = new Date(session.start);
    const endDate = new Date(startDate.getTime() + duration);

    this.state = {
      deleteConfirm: false,
      startDate,
      endDate,
      duration,
      rate,
      ...explodedDuration(duration),
    };
  }

  changeDate(startDate) {
    const { duration } = this.state;
    this.setState({
      startDate,
      endDate: new Date(startDate.getTime() + duration),
    });
  }

  changeRate(e) {
    const rateStr = e.target.value;
    const rate = parseFloat(rateStr);

    if (isDurationValid(rate)) {
      this.setState({ rate });
    } else {
      this.setState({ rate: '' });
    }
  }

  changeStartTime({ hours: newHours, mins: newMins }) {
    const { duration, startDate } = this.state;
    const newStartHours = newHours - startDate.getHours();
    const newStartMins = newMins - startDate.getMinutes();
    const diff = calcDuration(newStartHours, newStartMins);
    const newDate = new Date(startDate.getTime() + diff);

    if (!isDateValid(newDate)) {
      return;
    }

    this.setState({
      startDate: newDate,
      endDate: new Date(newDate.getTime() + duration),
    });
  }

  changeEndTime({ hours: newHours, mins: newMins }) {
    const { startDate } = this.state;
    const newStartHours = newHours - startDate.getHours();
    const newStartMins = newMins - startDate.getMinutes();
    let duration = calcDuration(newStartHours, newStartMins);

    if (duration < 0) {
      // Wrap around to next day
      duration += 1000 * 60 * 60 * 24;
    }

    if (!isDurationValid(duration)) {
      return;
    }

    this.setState({
      duration,
      endDate: new Date(startDate.getTime() + duration),
      ...explodedDuration(duration),
    });
  }

  changeHour(e) {
    let { value: hours } = e.target;
    const { minutes, startDate } = this.state;
    hours = parseFloat(hours);
    const duration = calcDuration(hours, minutes);

    this.setState({
      hours: isDurationValid(hours) ? hours : '',
      duration,
      endDate: new Date(startDate.getTime() + duration),
    });
  }

  changeMin(e) {
    let { value: minutes } = e.target;
    const { hours, startDate } = this.state;
    minutes = parseFloat(minutes);
    const duration = calcDuration(hours, minutes);

    this.setState({
      minutes: isDurationValid(minutes) ? minutes : '',
      duration,
      endDate: new Date(startDate.getTime() + duration),
    });
  }

  toggleDeleteConfirm(e, deleteConfirm) {
    // Only stop propagation is coming from a click event.
    // Otherwise we run the risk of inteferring with the WindowClickWrapper
    e && e.stopPropagation();
    this.setState({ deleteConfirm });
  }

  close(e) {
    const { close } = this.props;
    close();
  }

  deleteSession(e) {
    const { task, close, session } = this.props;
    e.preventDefault();

    session && task.deleteSession(session.id);
    close();
  }

  submit(e) {
    const { duration, startDate, endDate, rate } = this.state;
    const { task, close, session } = this.props;
    e.preventDefault();

    task.upsertSession(session, startDate, endDate, duration, rate);
    close();
  }

  render() {
    const { close, task, session } = this.props;
    const { startDate, endDate, hours, minutes, rate, deleteConfirm } = this.state;
    const parent = task.getParent();

    const startTimeHours = startDate.getHours();
    const startTimeMins = startDate.getMinutes();

    const endTimeHours = endDate.getHours();
    const endTimeMins = endDate.getMinutes();

    return (
      <Popover
        placement="auto"
        windowBoundary
        outsideClick={e => this.close(e)}
        className="compact-form wide edit-session"
      >
        <form onSubmit={e => this.submit(e)}>
          <div className="form-body">
            <div className="picker-container">
              <DayPicker
                month={startDate}
                selectedDays={startDate}
                onDayClick={e => this.changeDate(e)}
                firstDayOfWeek={1}
              />
            </div>
            <div className="col-1">
              <div className="basic-row">
                {'Start:'}
                <TimePicker
                  hours={startTimeHours}
                  mins={startTimeMins}
                  onChange={e => this.changeStartTime(e)}
                />
              </div>
              <div className="basic-row">
                {'End:'}
                <TimePicker
                  hours={endTimeHours}
                  mins={endTimeMins}
                  onChange={e => this.changeEndTime(e)}
                />
              </div>
              { parent.hasHourlyTracking() && (
                <div className="basic-row">
                  {'Rate:'}
                  <input type="number" min="0" step="0.01" value={rate} onChange={e => this.changeRate(e)} />
                </div>
              )}
              <div className="basic-row centered-row">
                {'Duration:'}
              </div>
              <div className="basic-row centered-row">
                <input type="number" min="0" max="23" value={hours} onChange={e => this.changeHour(e)} />
                {'hrs'}
                <input type="number" min="0" max="59" value={minutes} onChange={e => this.changeMin(e)} />
                {'mins'}
              </div>
            </div>
          </div>
          <div className="action-row">
            <button type="submit">
              <i className="fa fa-check" />
              {' Save'}
            </button>
            <button onClick={close} type="button">
              <i className="fa fa-times" />
              {' Close'}
            </button>
            { !deleteConfirm && session && (
              <button onClick={e => this.toggleDeleteConfirm(e, true)} className="firstOfGroup" type="button">
                <i className="fa fa-trash" />
                {' Delete'}
              </button>
            )}
            { deleteConfirm && (
              <WindowClickWrapper outsideClick={() => this.toggleDeleteConfirm(null, false)}>
                { renderProps => (
                  <div ref={renderProps.ref} className="firstOfGroup nestedFlex">
                    <button onClick={e => this.deleteSession(e)} className="btn-danger" type="button">
                      <i className="fa fa-trash" />
                      {' Confirm'}
                    </button>
                    <button onClick={e => this.toggleDeleteConfirm(e, false)} type="button">
                      <i className="fa fa-times" />
                      {' Cancel'}
                    </button>
                  </div>
                )}
              </WindowClickWrapper>
            )}
          </div>
        </form>
      </Popover>
    );
  }
}

// SessionItemEdit.propTypes = {
//   session: PropTypes.object.isRequired,
//   close: PropTypes.func.isRequired,
// };

export default SessionItemEdit;
