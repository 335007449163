/**
 *
 * Login
 *
 */


import React from 'react';

import logo from '../../assets/images/main-logo.png';

import './Login.css';

const windowsSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <title>MS-SymbolLockup</title>
    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
  </svg>
)


function Login({ login }) {
  const logUserIn = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <div className="login-page-wrapper">
      <div className="scroll-wrapper">
        <div className="login-wrapper">
          <img src={logo} height="160px" alt="Eclipse Manager Icon" />
          <form className="login-block">
            <div className="title-block">
              <h1>Welcome to Eclipse Manager</h1>
              <p className="caption">Start Tracking Your Projects and Time.</p>
            </div>
            <button onClick={e => logUserIn(e)} type="submit">{windowsSvg()} Log in with Microsoft</button>
          </form>
          <div className="spacing" />
        </div>
      </div>
    </div>
  );
}


export default Login;
