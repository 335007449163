/**
*
* EditPanelDynamicSelect
*
*/

import React  from 'react';
import './EditPanelDynamicSelect.css';

import DynamicSelect, { IDynamicOption } from 'components/DynamicSelect/DynamicSelect';

interface IPanelDynamicSelect {
  save: (value: any) => void;
  close: () => void;
  forwardRef: any;
  options: IDynamicOption[];
  value: string;
}

function EditPanelDynamicSelect (props: IPanelDynamicSelect) {
  const { forwardRef, save, close, options, value } = props;

  function checkSave(value) {
    const valueExists = options.find(a => a.id === value);

    if (valueExists) {
      save(value);
    } else {
      close();
    }
  }

  return (
    <div ref={forwardRef} className="panel-edit-form priority-select">
      <DynamicSelect
        selected={value}
        onSelect={checkSave}
        options={options}
      />
    </div>
  );
}

// EditPanelDynamicSelect.defaultProps = {
//   forwardRef: () => {},
// };

// EditPanelDynamicSelect.propTypes = {
//   save: PropTypes.func.isRequired,
//   close: PropTypes.func.isRequired,
//   forwardRef: PropTypes.func,
//   options: PropTypes.array.isRequired,
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.number,
//   ]).isRequired,
// };

export default EditPanelDynamicSelect;
