/**
*
* ReviewDeadline
*
*/

import React, { useState, useEffect } from 'react';

// import './ReviewDeadline.css';
import ListView from 'components/ListView/ListView';

import { CSSTransition } from 'react-transition-group';
import { useDataContext } from 'utils/context';

import TaskCard from '../../Project/TaskCard/TaskCard';
import AssocHeader from '../../Overview/AssocHeader/AssocHeader';
import EmptyReview from '../EmptyReview/EmptyReview';

function dayOffset(date) {
  let day = new Date(date).getDay();
  day = day === 0 ? 7 : day;

  return day;
}

function getTemplate() {
  const now = new Date();
  const today = new Date(now.setHours(23, 59, 59, 999));
  const nowDate = now.getDate();
  const EoW = new Date(today.setDate(nowDate + 7 - dayOffset(today)));

  const dateGroupTemplate = [
    {
      id: 'overdue',  
      title: 'Overdue',
      date: new Date(today).setDate(nowDate - 1),
    },
    {
      id: 'today',
      title: 'Due Today',
      date: today,
    },
    {
      id: 'tomorrow',
      title: 'Due Tomorrow',
      date: new Date(today).setDate(nowDate + 1),
    },
    {
      id: 'week1',
      title: 'This Week',
      date: EoW,
    },
    {
      id: 'week2',
      title: 'Next Week',
      date: new Date(EoW).setDate(EoW.getDate() + 7),
    },
    {
      id: 'month1',
      title: 'This Month',
      date: new Date(today.getFullYear(), today.getMonth() + 1, 1, 0),
    },
    {
      id: 'month2',
      title: 'Next Month',
      date: new Date(today.getFullYear(), today.getMonth() + 2, 1, 0),
    },
    {
      id: 'year1',
      title: 'This Year',
      date: new Date(today.getFullYear() + 1, 0, 1, 0),
    },
    {
      id: 'year2',
      title: 'Next Year',
      date: new Date(today.getFullYear() + 2, 0, 1, 0),
    },
    {
      id: 'future',
      title: 'Future',
    },
  ];

  return dateGroupTemplate;
}

function ReviewDeadline() {
  const data = useDataContext();
  const [recentTasks, setRecentTasks] = useState(null);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    const dateGroupTemplate = getTemplate();
    const dateGroupLength = dateGroupTemplate.length;
    const dateGroups = dateGroupTemplate.map(bucket => ({ ...bucket, count: 0 }));
    const activeTasks = data.tasks.filter(task => task.properties.deadline && !task.properties.completed);
    const nextRecentTasks = activeTasks.reduce((acc, task) => {
      const deadline = task.properties.deadline;
      const deadlineDate = new Date(deadline).getTime();

      if (deadlineDate > dateGroups[dateGroupLength - 1].date) {
        return acc;
      }

      for (let i = 0; i < dateGroupLength; i++) {
        const bucket = dateGroups[i];

        if (!bucket.date || deadlineDate < bucket.date) {
          acc.push({ ...task, tempId: bucket.id });
          bucket.count += 1;
          break;
        }
      }

      return acc;
    }, []);
    const nextGroups = dateGroups.filter(bucket => bucket.count > 0);

    setRecentTasks(nextRecentTasks);
    setGroups(nextGroups);
  }, [data.tasks]);
  
  if (!recentTasks) {
    return null;
  }

  if (recentTasks.length === 0) {
    return (
      <EmptyReview
        details={['This page shows upcoming task deadlines - start adding deadlines to your tasks to see them here.']}
      />
    );
  }

  return (
    <CSSTransition in appear timeout={300} classNames="fade">
      <ListView
        className="recent-listview"
        items={recentTasks}
        groups={groups}
        groupKeySelector="id"
        itemGroupSelector="tempId"
        groupRender={renderProps => <AssocHeader {...renderProps} />}
        itemRender={(renderProps) => {
          const project = renderProps.item.getParent();
          const group = project.getParent();

          return (<TaskCard {...renderProps} project={project} group={group} explicitProject />);
        }}
      />
    </CSSTransition>
  );
}

export default ReviewDeadline;
