/**
 *
 * ProjectTasks
 *
 */

import React, { PureComponent, Fragment } from 'react';
// import './ProjectTasks.css';

import { CSSTransition } from 'react-transition-group';
import { Route  } from 'react-router-dom';

import { newTask, ITaskArray } from 'utils/models/taskModel';
import ListView from 'components/ListView/ListView';
import SidePanel from 'components/SidePanel/SidePanel';
import { activeClickWrappers } from 'components/WindowClickWrapper/WindowClickWrapper';
import EditColumn from 'components/EditColumn/EditColumn/EditColumn';

import TaskCard from '../TaskCard/TaskCard';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import TaskDetail from '../TaskDetail/TaskDetail';
import SimpleItemForm from '../SimpleItemForm/SimpleItemForm';
import { IGroup } from 'utils/models/groupModel';
import { IProject } from 'utils/models/projectModel';

interface RouterProps {
  match: any;
  location: any;
  history: any;
}

interface IProjectTasks extends RouterProps {
  tasks: ITaskArray;
  group: IGroup;
  project: IProject;
  filteredTasks: ITaskArray;
}

class ProjectTasks extends PureComponent<IProjectTasks> {
  matchCache = false;

  onMove(a, b, c) {
    const { tasks } = this.props;
    const taskA = tasks.get(a);
    const taskB = tasks.get(b);

    if (!taskA) {
      return;
    }

    taskA.reorder(taskB, c);
  }

  deselect(hard = false) {
    const { match, location, history } = this.props;
    const atRoot = match.url === location.pathname;
    const openPopups = activeClickWrappers();

    // This acts as a layer below clickWrappers.
    // If there is a clickWrapper open we let that consume the click.
    if (!hard && openPopups > 0) {
      return;
    }

    if (!atRoot) {
      history.push(match.url);
    }
  }

  addColumn(title) {
    const props = this.props;
    const project = props.project;
    const columns = project.columns;
    const position = columns.length + 1;
    const id = `sub${Date.now()}`;

    if (columns.find(a => a.id === id)) {
      console.debug('t')
      return;
    }

    const column = { id, title, position };

    project.addColumn(column);
  }

  addTask(title, column) {
    const props = this.props;
    const project = props.project;
    const children = project.getChildren();
    const next = newTask(project.id, { title, column, pos: children.length });
    props.tasks.insert(next);
  }

  render() {
    const {
      match, filteredTasks, tasks, project, group,
    } = this.props;

    return (
      <Fragment>
        <CSSTransition in appear timeout={300} classNames="fade">
          <ListView
            className="project-listview"
            items={filteredTasks}
            groups={project.columns}
            groupKeySelector="id"
            itemGroupSelector="column"
            emptyClick={() => this.deselect()}
            onMove={(a, b, c) => this.onMove(a, b, c)}
            addText="Add Task"
            tailName="Column"
            groupRender={renderProps => <ColumnHeader {...renderProps} />}
            itemRender={renderProps => <TaskCard {...renderProps} group={group} project={project} />}
            addItemForm={renderProps => (
              <SimpleItemForm {...renderProps} submit={(name, columnId) => this.addTask(name, columnId)} />
            )}
            addColumnForm={renderProps => (
              <SimpleItemForm {...renderProps} submit={title => this.addColumn(title)} />
            )}
            groupEditForm={renderProps => (
              <EditColumn {...renderProps} project={project} />
            )}
          />
        </CSSTransition>
        <Route
          path={`${match.url}/:taskId`}
          children={(routeProps) => {
            const realMatch = routeProps.match;
            const safeMatch = realMatch || this.matchCache;
            const id = safeMatch && parseInt(safeMatch.params.taskId, 10);

            if (realMatch) { this.matchCache = realMatch; }

            return (
              <SidePanel in={!!realMatch}>
                <TaskDetail
                  task={tasks.get(id)}
                  project={project}
                  group={group}
                  close={() => this.deselect(true)}
                />
              </SidePanel>
            );
          }}
        />
      </Fragment>
    );
  }
}

// ProjectTasks.propTypes = {
//   match: PropTypes.any.isRequired,
//   group: PropTypes.any.isRequired,
//   project: PropTypes.any.isRequired,
//   filteredTasks: PropTypes.any.isRequired,
//   tasks: PropTypes.any.isRequired,
// };

export default ProjectTasks;
