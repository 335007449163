/**
*
* Dashboard
*
*/

import React from 'react';

import './Dashboard.css';

interface IDashboard {
  horizontal: boolean;
  children: any;
}

export default function Dashboard({ horizontal, children }: IDashboard) {
  const classList = ['dash-surface'];

  if (horizontal) classList.push('horizontal');

  return <div className={classList.join(' ')}>{children }</div>;
}
