/**
*
* EmptyReview
*
*/

import React from 'react';

import './EmptyReview.css';

function EmptyReview(props) {
  return (
    <div className="empty-review">
      <h4>No Matching Tasks.</h4>
      { props.details.map(detail => <div>{ detail }</div>) }
    </div>
  );
}


export default EmptyReview;
