import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// import { isWindows } from './utils';

const origin = window.location.origin;
 
// Msal Configurations
// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
const config: any = {
  auth: {
    clientId: '28fe3841-9be3-4333-8168-2d4f6c100145',
    authority: 'https://eclipseauth.b2clogin.com/tfp/eclipseauth.onmicrosoft.com/B2C_1_signupinv2',
    b2cScopes: ['https://eclipseauth.onmicrosoft.com/v1/user'],
    redirectUri: origin,
    postLogoutRedirectUri: origin,
    // Ability to turn off default navigation to start page after login. Default is true. This is used only for redirect flows.
    navigateToLoginRequestUrl: false,
    // For B2C applications, since the authority value is known and can be different per policy, 
    // the authority validation will not work and has to be set to false.
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage",
    // This flag was introduced in MSAL.js v0.2.2 as a fix for the authentication loop issues on Microsoft Internet Explorer and Microsoft Edge.
    // Enable the flag storeAuthStateInCookie to true to take advantage of this fix.
    // When this is enabled, MSAL.js will store the auth request state required for validation of the auth flows in the browser cookies.
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: config.auth.b2cScopes
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${origin}/auth.html`
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
