/**
*
* TasksFilter
*
*/

import React, { PureComponent } from 'react';

import './TasksFilter.css';

import classNames from 'classnames';

import { Manager, Reference } from 'react-popper';
import TaskFilterPopup from '../TaskFilterPopup/TaskFilterPopup';


class TasksFilter extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      filterPopover: false,
    };
  }

  togglePopover(bool) {
    // const { filterPopover } = this.state;
    this.setState({ filterPopover: bool });
  }

  render() {
    const { project } = this.props;
    const { filterPopover } = this.state;
    const hasFilter = project.hasFilter();

    return (
      <Manager>
        <Reference>
          {config => (
            <button
              ref={config.ref}
              type="button"
              className={classNames('page-toolbar-btn', hasFilter && 'highlight')}
              onClick={() => this.togglePopover(true)}
            >
              <i className="fa fa-filter" />
              { hasFilter ? (
                <span className="filter-desc">
                  {'Filtering '}
                  {project.filterCount()}
                  {' Tasks'}
                </span>
              ) : (
                <span className="filter-desc">Filter</span>
              )}
            </button>
          )}
        </Reference>
        {filterPopover && (
          <TaskFilterPopup
            project={project}
            outsideClick={() => this.togglePopover(false)}
          />
        )}
      </Manager>
    );
  }
}


export default TasksFilter;
