/**
*
* FormikInput
*
*/

import React, { useEffect, useRef } from 'react';
// import './FormikInput.css';

interface IFormikInput {
  placeholder?: string,
  focusOnMount?: boolean,
  name: string,
  formikBag: any
};

function FormikInput(props: IFormikInput) {
  const {
    name,
    focusOnMount,
    placeholder = '',
    formikBag,
  } = props;

  const {
    errors,
    values,
    touched,
    handleChange,
  } = formikBag;


  const inputRef = useRef(null);
  useEffect(() => focusOnMount && inputRef?.current?.focus(), [focusOnMount]);

  return (
    <input
      ref={inputRef}
      name={name}
      type="text"
      autoComplete="off"
      className={errors[name] && touched[name] && 'input-error'}
      placeholder={placeholder}
      value={values[name]}
      onChange={handleChange}
    />
  );
}

export default FormikInput;
