/**
*
* EditPanelTextarea
*
*/

import React, { PureComponent } from 'react';

// import './EditPanelTextarea.css';

class EditPanelTextarea extends PureComponent<any, any> {
  textarea;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <div ref={props.forwardRef} className="panel-edit-form">
        <textarea
          rows={5}
          ref={node => this.textarea = node}
          value={state.value}
          onChange={e => this.handleChange(e)}
        />
        <div className="form-buttons">
          <button onClick={() => props.save(state.value)} type="submit" className="primary">Save</button>
          <button onClick={props.close} type="button">Cancel</button>
        </div>
      </div>
    );
  }

  save() {
    this.props.close();
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidMount() {
    this.textarea.focus();
  }
}

export default EditPanelTextarea;
