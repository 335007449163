/**
*
* ProjectWidgetRecent
*
*/

import React, { PureComponent } from 'react';
import './ProjectWidgetRecent.css';

import ProjectWidgetPlaceholder from 'components/ProjectWidgets/ProjectWidgetPlaceholder/ProjectWidgetPlaceholder';

import { formatDate, formatDuration } from 'utils/date';
import { reduceSum } from 'utils/manipulation';
import { formatCurrency } from 'utils/constants';

function calcRecentGroups(tasks, project) {
  const groups = {};
  let recentTasks = tasks.reduce((acc, { id, properties, sessions }) => {
    if (properties.completed) {
      acc.push({
        id,
        title: properties.title,
        date: new Date(properties.completedDate),
        dateString: properties.completedDate.substr(0, 10),
        event: 'complete',
        value: project.hasAddedTracking() && properties.fixedValue,
      });
    }

    sessions.forEach((session) => {
      acc.push({
        id: `${id}&&sid:${session.id}`,
        title: properties.title,
        date: new Date(session.start),
        dateString: session.start.substr(0, 10),
        event: 'timed',
        value: project.hasHourlyTracking() && session.value,
        duration: session.duration,
      });
    });
    return acc;
  }, []);

  recentTasks.sort((a, b) => {
    const starta = a.date.getTime();
    const startb = b.date.getTime();

    if (starta === startb) {
      return 0;
    }

    if (starta > startb) {
      return -1;
    }

    return 1;
  });

  // Only show top 50;
  recentTasks = recentTasks.slice(0, 50);

  recentTasks.forEach((task) => {
    const bucket = groups[task.dateString];
    if (bucket) {
      bucket.tasks.push(task);
      bucket.duration = reduceSum(bucket.duration, task.duration);
      bucket.value = reduceSum(bucket.value, task.value);
    } else {
      groups[task.dateString] = {
        id: task.dateString,
        date: task.dateString,
        tasks: [task],
        duration: reduceSum(0, task.duration),
        value: reduceSum(0, task.value),
      };
    }
  });

  return Object.values(groups);
}

class ProjectWidgetRecent extends PureComponent<any, any> {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { project, tasks } = nextProps;
    const { projectRef } = prevState;

    const nextState: any = { projectRef: project };

    const costTrackingMatch = project.properties.costTracking === projectRef.properties.costTracking;
    const timeTrackingMatch = project.hasTimeTracking() === projectRef.hasTimeTracking();

    if (!costTrackingMatch || !timeTrackingMatch) {
      nextState.recentGroups = calcRecentGroups(tasks, project);
    }

    return nextState;
  }

  constructor(props) {
    super(props);
    const { tasks, project } = props;

    this.state = {
      projectRef: project,
      recentGroups: calcRecentGroups(tasks, project),
    };
  }

  render() {
    const { project } = this.props;
    const { recentGroups } = this.state;

    if (recentGroups.length === 0) {
      return (<ProjectWidgetPlaceholder />);
    }

    return (
      <div className="activity-log">
        { recentGroups.map(group => (
          <div key={group.date} className="recent-group">
            <div className="group-header">
              <div className="row-title">{ formatDate(group.date) }</div>
              <div className="row-details">
                { project.hasTimeTracking() && (
                  <div>
                    { formatDuration(group.duration) }
                    <span>hrs</span>
                  </div>
                )}
                { project.hasCostTracking() && (
                  <div>{ formatCurrency(group.value) }</div>
                )}
              </div>
            </div>
            { group.tasks.map(recentTask => (
              <div key={recentTask.id} className="recent-item">
                <div className="row-title">
                  { recentTask.event === 'timed' && (
                    <i className="fa fa-clock-o" />
                  )}
                  { recentTask.event === 'complete' && (
                    <i className="fa fa-check" />
                  )}
                  { recentTask.title }
                </div>
                <div className="row-details">
                  { recentTask.event === 'timed' && (
                    <div>
                      { formatDuration(recentTask.duration) }
                      <span>hrs</span>
                    </div>
                  )}
                  { recentTask.value !== false ? (
                    <div>{ formatCurrency(recentTask.value) }</div>
                  ) : (
                    project.hasCostTracking() && <div />
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default ProjectWidgetRecent;
