/**
*
* StampButton
*
*/

import React from 'react';

// import './StampButton.css';

function StampButton(props) {
  const isComplete = props.isComplete;
  const hasTiming = props.hasTiming;

  if (isComplete || hasTiming) return null;

  return (
    <button
      type="button"
      className="action-btn"
      onClick={() => props.task.markActive()}
    >
      <i className="fa fa-circle-o" />
      Stamp
    </button>
  );
}

export default StampButton;
