/**
*
* EditPanelNumber
*
*/

import React, { PureComponent } from 'react';
// import './EditPanelNumber.css';

class EditPanelNumber extends PureComponent<any, any> {
  input;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  save(e) {
    const { value } = this.state;
    const { save } = this.props;

    e.preventDefault();
    save(parseFloat(value));
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidMount() {
    this.input.focus();
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <form
        ref={props.forwardRef}
        className="panel-edit-form inline"
        onSubmit={e => this.save(e)}
      >
        <input
          type="number"
          value={state.value}
          step="0.01"
          ref={node => (this.input = node)}
          onChange={e => this.handleChange(e)}
        />
        <div className="form-buttons inline">
          <button
            type="submit"
            className="primary"
          >
            <i className="fa fa-check" />
          </button>
          <button
            onClick={props.close}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </form>
    );
  }
}

// EditPanelNumber.defaultProps = {
//   save: () => {},
//   value: 0,
// };

// EditPanelNumber.propTypes = {
//   save: PropTypes.func,
//   value: PropTypes.number,
// };

export default EditPanelNumber;
