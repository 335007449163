
import { formatDuration } from 'utils/date';
import { reduceSum } from 'utils/manipulation';
import { dbUpdateProject, dbInsertProject, dbDeleteProject } from 'utils/api';
import { objectsMatch } from '../manipulation';
import { NO_TRACKING, HOURLY_TRACKING, INCREMENTAL_TRACKING } from '../constants';
import { taskModel, taskArrayModel, ITaskArray, ITaskObject } from './taskModel';
import { IDataControllerState } from 'utils/context';
import { parseId } from 'utils/utils';
import DataController from 'containers/DataController/DataController';

export type ICostTrackingTypes = typeof NO_TRACKING | typeof HOURLY_TRACKING | typeof INCREMENTAL_TRACKING;

interface IProjectStats {
  completed: boolean;
  length: number;
  overdue: boolean;
  active: boolean;
  timespent: number;
  completedCount?: number;
  valueRecieved?: number;
  value?: number;
}

interface IProjectProperties {
  title: string;
  description: string;
  costTracking: ICostTrackingTypes;
  defaultRate: number;
  timeTracking: boolean;
  public: boolean;
}

export interface IProjectColumn {
  id: string;
  position: number;
  title: string;
}

interface IProjectObject {
  id: number;
  association_id: number;
  properties: IProjectProperties;
  stats: IProjectStats;
  columns: IProjectColumn[];
  archived?: boolean;
}

type IProjectPropKeys = keyof IProjectProperties; 

type KeysOfType<T, TProp> = {
  [P in keyof T]: T[P] extends TProp? P : never
}[keyof T];

export type IProjectUpdate = {
  value: IProjectProperties[IProjectPropKeys],
  prop: IProjectPropKeys
};

export interface IProject extends IProjectObject {
  getParent: () => any;
  getChildren: () => ITaskArray;
  getFilteredChildren: () => ITaskArray;
  updateStats: (tasks?: ITaskArray, save?: boolean) => IProject;
  moveGroup: (value: number | string) => IProject;
  saveBoolProperty: (value: boolean, prop: KeysOfType<IProjectProperties, boolean>) => IProject;
  simpleUpdate<T extends IProjectPropKeys>(value: IProjectProperties[T], prop: T): IProject;
  simpleUpdates(updates: Array<IProjectUpdate>): IProject;
  moveColumn: (columnId: string, newPos: string | number) => IProject;
  updateColumn: (column: IProjectColumn, save?: boolean) => IProject;
  addColumn: (column: IProjectColumn, save?: boolean) => IProject;
  removeColumn: (id: string, save?: boolean) => IProject;
  columnIsEmpty: (id: string) => boolean;
  setFilter: (filter: any) => void;
  clearFilter: (id: string) => void;
  clearAllFilters: () => void;
  getFilter: (id: string) => any;
  hasFilter: () => boolean;
  filterCount: () => number;
  timeDisplay: () => string;
  hasCostTracking: () => boolean;
  hasTimeTracking: () => boolean;
  hasHourlyTracking: () => boolean;
  hasAddedTracking: () => boolean;
  percentRecieved: () => number;
  percentComplete: () => number;
  remaining: () => number;
  getDefaultRate: () => number;
  delete: () => void;
}

export interface IProjectArray extends Array<IProject> {
  get: (id: string | number) => IProject;
  mutate: (arr: IProject[]) => IProjectArray;
  updateStats: (nextTasks: ITaskArray, effectedProjects: number[]) => {
    saveFunctions: () => Promise<any>[],
    nextProjects: IProjectArray,
  };
  update: (project: IProject, callback?, save?: boolean) => void;
  insert: (projectNoId: IProjectObject, tasks: ITaskObject[], callback?) => void;
  delete: (id: number, callback?) => void
}

const projectState = {
  filters: [],
};

export function projectArrayModel(projectArray: IProject[] = [], context: DataController) {
  // Everytime a project is updated the whole cache will be reset
  // This is fine as it keeps simplicity down for minimal performance hit
  const simpleCache = { };
  console.debug('New Project Array');

  if (!context) {
    throw new Error('Project Array Model missing context');
  }

  const localArray = [...projectArray] as IProjectArray

  // Sort Tasks
  localArray.sort((proj1, proj2) => {
    const ATitle = proj1.properties.title || '';
    const BTitle = proj2.properties.title || '';
    const AComp = proj1.stats.completed ? 1 : 0;
    const BComp = proj2.stats.completed ? 1 : 0;

    if (AComp === BComp) {
      const lcA = ATitle.toLowerCase();
      const lcB = BTitle.toLowerCase();

      if (lcA === lcB) return 0;

      return lcA > lcB ? 1 : -1;
    }

    return AComp < BComp ? -1 : 1;
  });


  // Remove mutating methods - these introduce hard to catch bugs.
  // Much easier to cause the app top fail fast by removing them.
  localArray.sort = undefined;
  localArray.splice = undefined;

  // Local Methods
  // These methods refer to the localArray

  localArray.get = (unsafeId) => {

    let id = parseId(unsafeId);
    
    const cached = simpleCache[id];
    if (cached) {
      return cached;
    }

    console.debug(`Cache getProject ${id}`);

    const project = localArray.find(a => a.id === id);
    simpleCache[id] = project;
    return project;
  };

  localArray.mutate = arr => projectArrayModel(arr, context);

  localArray.updateStats = (nextTasks, effectedProjects) => {
    const saveFunctions = [];
    const nextProjects = localArray.map((existing) => {
      const mutated = effectedProjects.includes(existing.id);

      if (!mutated) return existing;

      const updated = existing.updateStats(nextTasks, false);
      saveFunctions.push(projectSavePromise(existing, updated));
      return updated;
    });

    return {
      saveFunctions: () => saveFunctions.map(a => a()),
      nextProjects: localArray.mutate(nextProjects),
    };
  };

  // Save Methods
  // These methods pass to functions which update the global state
  // The methods are kept seperate to avoid accidently using localArray

  localArray.update = (project, callback, save) => ProjectArrayUpdate(project, context, callback, save);
  localArray.insert = (projectNoId, tasks, callback) => ProjectArrayInsert(projectNoId, tasks, callback, context);
  localArray.delete = (id, callback) => ProjectModelDelete(id, callback, context);

  return localArray;
}

export function projectModel(projectObject: IProjectObject, context: DataController, tempState = projectState): IProject {
  // Never set a variable to a property of the context object.
  // If that property is updated we will be left with an outdated copy.
  // NEVER: const tasks = context.state.tasks;

  const project: IProject = (projectObject as IProject);

  if (!context) {
    throw new Error('Project Model missing context');
  }

  if (project.columns) {
    const columnCopy = [...project.columns];
    project.columns = columnCopy.sort((col1, col2) => {
      const a = col1.position;
      const b = col2.position;

      // Positions should never be equal,
      // Maybe correct here
      if (a === b) return 0;

      return a > b ? 1 : -1;
    });

    // Remove sort method to ensure order cannot be mutated
    columnCopy.sort = undefined;
  }

  const { stats: projectStats } = project;

  project.setFilter = (filter) => {
    const filters = tempState.filters.filter(a => a.id !== filter.id);
    filters.push(filter);
    wrapModel(project, true, { ...tempState, filters });
  };
  project.clearFilter = (filterId) => {
    const filters = tempState.filters.filter(a => a.id !== filterId);
    wrapModel(project, true, { ...tempState, filters });
  };
  project.clearAllFilters = () => {
    wrapModel(project, true, { ...tempState, filters: [] });
  };
  project.getFilter = filterId => tempState.filters.find(a => a.id === filterId);
  project.hasFilter = () => tempState.filters.length > 0;
  project.filterCount = () => {
    const allTasks = project.getChildren();
    const filteredTasks = project.getFilteredChildren();

    return allTasks.length - filteredTasks.length;
  };

  project.getParent = () => context.state.groups.get(project.association_id);
  project.getChildren = () => {
    return context.state.tasks.getProjectTasks(project.id);
  }
  project.getFilteredChildren = () => context.state.tasks.getFilteredProjectTasks(project.id, tempState.filters);

  project.timeDisplay = () => formatDuration(projectStats.timespent);

  project.hasCostTracking = () => project.properties.costTracking !== NO_TRACKING;
  project.hasTimeTracking = () => !!project.properties.timeTracking;
  project.hasHourlyTracking = () => project.properties.costTracking === HOURLY_TRACKING;
  project.hasAddedTracking = () => project.properties.costTracking === INCREMENTAL_TRACKING;

  project.percentRecieved = () => {
    const pcnt = Math.round(projectStats.valueRecieved * 100 / projectStats.value);
    return Number.isNaN(pcnt) ? 0 : pcnt;
  };

  project.percentComplete = () => {
    const pcnt = Math.round(100 * projectStats.completedCount / projectStats.length);
    return Number.isNaN(pcnt) ? 0 : pcnt;
  };

  project.remaining = () => projectStats.length - projectStats.completedCount;

  project.getDefaultRate = () => {
    if (project.hasHourlyTracking()) {
      return project.properties.defaultRate || 0;
    }

    return 0;
  };

  project.columnIsEmpty = (columnId) => {
    const tasks = context.state.tasks;

    if (tasks.find(a => a.project_id === project.id && a.properties.column === columnId)) {
      return false;
    }

    return true;
  };

  project.delete = () => {
    const id = project.id;
    context.state.projects.delete(id);
  };

  // Update Property Functions

  project.moveGroup = (value) => {
    let groupId;

    if (value !== '') {
      groupId = typeof value === 'string' ? parseInt(value, 10) : value;
      const target = context.state.groups.find(a => a.id === groupId);

      if (!target || groupId === project.association_id) {
        return project;
      }
    }

    return wrapModel({
      ...project,
      association_id: groupId,
    });
  };

  project.saveBoolProperty = (value, prop) => {
    let boolValue: boolean;

    if (typeof value !== 'boolean') {
      boolValue = (value === 'on');
    }

    return project.simpleUpdate(boolValue, prop);
  };

  project.simpleUpdate = (value, prop) => (
    wrapModel({
      ...project,
      properties: {
        ...project.properties,
        [prop]: value,
      },
    }, true)
  );

  project.simpleUpdates = (updates) => {
    const next = updates.reduce((obj, { value, prop }) => ({
      ...obj,
      properties: {
        ...obj.properties,
        [prop]: value,
      },
    }), project);

    wrapModel(next, true);
    return next;
  };

  project.moveColumn = (columnId, newPos) => {
    const newPosInt = typeof newPos === 'string' ? parseInt(newPos, 10) : newPos;
    const columns = project.columns.map((a, i) => ({ ...a, position: i + 1 }));
    const safeColumn = columns.find(a => a.id === columnId);
    const positiveDirection = newPosInt > safeColumn.position;

    if (safeColumn.position === newPosInt) return project;

    return wrapModel({
      ...project,
      columns: columns.map((target) => {
        const currentPosition = target.position;

        if (target.id === columnId) {
          target.position = newPosInt;
        } else if (positiveDirection) {
          target.position = currentPosition >= newPosInt ? currentPosition - 1 : currentPosition;
        } else {
          target.position = currentPosition <= newPosInt ? currentPosition + 1 : currentPosition;
        }

        return target;
      }),
    });
  };

  project.updateColumn = (column, save) => {
    const next = {
      ...project,
      columns: project.columns.map(a => (a.id === column.id ? column : a)),
    };

    return wrapModel(next, save);
  };

  project.addColumn = (column, save) => {
    const next = {
      ...project,
      columns: [...project.columns, column],
    };

    return wrapModel(next, save);
  };

  project.removeColumn = (id, save) => {
    if (!project.columnIsEmpty(id)) {
      return project;
    }

    return wrapModel({
      ...project,
      columns: project.columns.filter(a => a.id !== id),
    }, save);
  };

  // Update Stats Functions

  project.updateStats = (nextTasks, save = false) => {
    // Tasks could have been mutated and are pending a setState()
    // If this is the case use nextTasks to calculate correctly
    const { id: projectId } = project;
    const tasks: ITaskArray = nextTasks || context.state.tasks;
    const projectTasks = tasks.getProjectTasks(projectId);
    const completedTasks = projectTasks.filter(({ properties }) => properties.completed === true);
    const projectLength = projectTasks.length;
    const completedLength = completedTasks.length;
    const costTrackingType = project.properties.costTracking;

    const nextStats: any = {
      length: projectLength,
      completed: completedLength > 0 && completedLength === projectLength,
      completedCount: completedLength,
      overdue: !!projectTasks.find((task) => task.isOverdue()),
      active: !!projectTasks.find(({ properties }) => properties.active),
      timespent: projectTasks.reduce((acc, { stats }) => reduceSum(acc, stats.timespent), 0),
    };

    if (costTrackingType === 'added') {
      nextStats.value = projectTasks.reduce((acc, { properties }) => reduceSum(acc, properties.fixedValue), 0);
      nextStats.valueRecieved = completedTasks.reduce((acc, { properties }) => reduceSum(acc, properties.fixedValue), 0);
    } else if (costTrackingType === 'perhour') {
      nextStats.valueRecieved = projectTasks.reduce((acc, { stats }) => reduceSum(acc, stats.hourlyValue), 0);
    }

    const nextProject = {
      ...project,
      stats: nextStats,
    };

    return wrapModel(nextProject, save);
  };

  function wrapModel(rawProject: IProjectObject | IProject, updateState = true, newState?) {
    let nextProject: IProjectObject = rawProject;

    // nextProject must be a shallow copy of project. This prevents state being mutated.
    // This can be checked by seeing if it has any custom methods.
    if ((rawProject as IProject).getChildren) {
      nextProject = { ...rawProject };
    }

    let next = projectModel(nextProject, context, newState || tempState);

    if (updateState === true) {
      // For its small occasional cost, always update stats
      next = next.updateStats();
      // If we are just updating the tempState - dont save
      context.state.projects.update(next, null, !newState);
    }

    return next;
  }

  return project;
}

function projectSavePromise(existing, updated) {
  return () => {
    const propDiff = !objectsMatch(existing.properties, updated.properties);
    const statDiff = !objectsMatch(existing.stats, updated.stats);

    if (propDiff || statDiff) {
      return dbUpdateProject(updated);
    }

    return Promise.resolve();
  };
}

function ProjectArrayUpdate(project: IProject, context: DataController, callback?, save = true) {
  const { id } = project;
  const { projects } = context.state;
  const next = projects.map(existing => (id === existing.id ? project : existing));

  context.setState({
    projects: projects.mutate(next),
  }, () => {
    const onError = (err) => {
      console.debug('Error Updating Project.', err);
      context.setState({ projects });
    };

    callback && callback();
    if (save) {
      dbUpdateProject(project).then(null, onError);
    }
  });
}

export function newProject(groupId, properties, columns?): IProjectObject {
  return {
    id: -1 * Date.now(),
    association_id: parseInt(groupId, 10),
    archived: false,
    properties: {
      title: properties.title,
      description: properties.description,
      timeTracking: properties.timeTracking,
      costTracking: properties.costTracking,
      defaultRate: properties.costTracking === HOURLY_TRACKING ? properties.rate : 0,
      public: false,
    },
    stats: {
      completed: false,
      length: 0,
      completedCount: 0,
      overdue: false,
      active: false,
      value: 0,
      valueRecieved: 0,
      timespent: 0,
    },
    columns: columns || [{
      id: 'sub1',
      position: 1,
      title: 'All',
    }],
  };
}

function ProjectArrayInsert(nextProject: IProjectObject, nextTasks: ITaskObject[], callback, context: DataController) {
  const timestamp = Date.now().toString();
  const tempId: number = (`temp_project_${timestamp}` as any);
  const { projects, tasks } = context.state;

  let tempProject = projectModel({ ...nextProject, id: tempId }, context);
  const nextState = { } as Pick<IDataControllerState, 'tasks' | 'projects'>;

  // If we are supplying tasks with the project, then inject the temp id into the tasks.
  // Then run updateStats() on the project to get the correct stats.
  if (nextTasks) {
    const tempTasks = taskArrayModel(nextTasks.map((task, i) => {
      const id = `temp_task_${timestamp}_${i}`;
      return taskModel({ ...task, id, project_id: tempId }, context);
    }), context);
    nextState.tasks = tasks.mutate([...tasks, ...tempTasks]);
    tempProject = tempProject.updateStats(tempTasks, false);
  }

  nextState.projects = projects.mutate([...projects, tempProject]);
  context.setState(nextState, commitInsert);

  function commitInsert() {
    // Strip the id from temp project before save - don't use projectNoId it has outdated stats
    dbInsertProject({ ...tempProject, id: undefined }, nextTasks).then(({ data }) => {
      let { project, tasks } = data;
      const {
        projects: currProjects,
        tasks: currTasks,
      } = context.state;

      project = projectModel(project, context);
      const finalState: any = {
        projects: currProjects.mutate(
          currProjects.map(a => (a.id === tempId ? project : a)),
        ),
      };

      if (nextTasks) {
        const finalTasks = tasks.map(task => taskModel(task, context));
        const filteredTasks = currTasks.filter(item => item.project_id !== tempId);
        finalState.tasks = currTasks.mutate([...filteredTasks, ...finalTasks]);
      }

      context.setState(finalState, () => {
        callback && callback(project.id);
      });
    }, (err) => {
      console.debug('Error Inserting Project.', err);
      context.setState({ projects, tasks });
    });
  }
}

function ProjectModelDelete(id: number, callback, context: DataController) {
  const { tasks, projects } = context.state;
  const nextProjects = projects.filter(a => a.id !== id);

  context.setState({
    projects: projects.mutate(nextProjects),
    tasks: tasks.mutate(tasks.filter(a => a.project_id !== id), [id]),
  }, () => {
    const onError = (err) => {
      console.debug('Error Deleting Project.', err);
      context.setState({ projects, tasks });
    };

    callback && callback();
    dbDeleteProject(id).then(null, onError);
  });
}
