/**
*
* ReviewRecent
*
*/

import React, { useEffect, useState } from 'react';

// import './ReviewRecent.css';

import ListView from 'components/ListView/ListView';

import { CSSTransition } from 'react-transition-group';
import { useDataContext } from 'utils/context';

import TaskCard from '../../Project/TaskCard/TaskCard';
import AssocHeader from '../../Overview/AssocHeader/AssocHeader';
import EmptyReview from '../EmptyReview/EmptyReview';

function getTemplate() {
  const now = new Date();
  const nowDate = now.getDate();
  const nowMonth = now.getMonth();
  const today = new Date().setHours(0, 0, 0, 0);
  const dateGroupTemplate = [
    {
      id: 'today',
      title: 'Today',
      date: today,
    },
    {
      id: 'yesterday',
      title: 'Yesterday',
      date: new Date(today).setDate(nowDate - 1),
    },
    {
      id: 'week1',
      title: 'This Week',
      date: new Date(today).setDate(nowDate - 7),
    },
    {
      id: 'week2',
      title: 'Last Week',
      date: new Date(today).setDate(nowDate - 14),
    },
    {
      id: 'month1',
      title: 'This Month',
      date: new Date(today).setMonth(nowMonth - 1),
    },
  ];

  return dateGroupTemplate;
}

function ReviewRecent() {
  const data = useDataContext();
  const [recentTasks, setRecentTasks] = useState(null);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    const dateGroupTemplate = getTemplate();
    const dateGroupLength = dateGroupTemplate.length;
    const dateGroups = dateGroupTemplate.map(bucket => ({ ...bucket, count: 0 }));
    const activeTasks = data.tasks.filter(task => task.properties.lastActive);
    const nextRecentTasks = activeTasks.reduce((acc, task) => {
      const lastActive = task.properties.lastActive;
      const lastActiveDate = new Date(lastActive).getTime();

      if (lastActiveDate < dateGroups[dateGroupLength - 1].date) {
        return acc;
      }

      for (let i = 0; i < dateGroupLength; i++) {
        const bucket = dateGroups[i];
        if (lastActiveDate > bucket.date) {
          acc.push({ ...task, tempId: bucket.id });
          bucket.count += 1;
          break;
        }
      }

      return acc;
    }, []);
    const nextGroups = dateGroups.filter(bucket => bucket.count > 0);

    setRecentTasks(nextRecentTasks);
    setGroups(nextGroups);
  }, [data.tasks]);

  if (!recentTasks) {
    return null;
  }

  if (recentTasks.length === 0) {
    return (
      <EmptyReview
        details={[
          'This page shows tasks you have recently worked on - use time tracking on your tasks to view them here.',
          'For projects with time tracking turned off you can use the "Stamp" button instead, this simply marks a task as recently worked on.',
        ]}
      />
    );
  }

  return (
    <CSSTransition in appear timeout={300} classNames="fade">
      <ListView
        className="recent-listview"
        items={recentTasks}
        groups={groups}
        groupKeySelector="id"
        itemGroupSelector="tempId"
        groupRender={renderProps => <AssocHeader {...renderProps} />}
        itemRender={(renderProps) => {
          const project = renderProps.item.getParent();
          const group = project.getParent();

          return (<TaskCard key={renderProps.item.id} {...renderProps} project={project} group={group} explicitProject />);
        }}
      />
    </CSSTransition>
  );
}


export default ReviewRecent;
