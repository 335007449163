
export const iconPicker = {
  Active: '&#xe101;',
  Flag: '&#xe129;',
  None: '',
  Ignore: '&#x21D3;',
  Low: '&#x2191;',
  Medium: '&#x2191;&#x2191;',
  High: '&#x2191;&#x2191;&#x2191;',
  Completed: '&#x2713;',
  Task: '&#x2756;',
};
export const eclipseIcon = {
  logo: '&#xe600;',
  project: '&#xe601;',
  unassociated: '&#xe602;',
  association: '&#xe603;',
  arrowBack: '&#xe604;',
  pin: '&#xe605;',
  reports: '&#xe606;',
  check: '&#xe607;',
  columnAdd: '&#xe608;',
  menu: '&#xe609;',
};
export const iconTextPicker = {
  folder: '',
  project: '',
  settings: '',
};
export const colourPicker = {
  orange: '#D77B00',
  turquoise: '#029BAC',
  grey: '#999',
  blue: '#0051B3',
  purple: '#742E85',
  brown: '#8C4600',
  burgundy: '#88333E',
  green: '#3EB100',
  hold: '#008427',
  red: '#B00000',
};
export const colourPickerArray = Object.keys(colourPicker);
