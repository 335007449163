/**
*
* ListviewItem
*
*/

import React, { PureComponent } from 'react';

// import './ListviewItem.css';

class ListviewItem extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // Following code blocks dragging on touch events
  // componentDidMount() {
  //   this.itemRef.addEventListener('mousedown', this.draggableOn.bind(this));
  //   this.itemRef.addEventListener('touchstart', this.draggableOff.bind(this));
  // }

  // draggableOn() {
  //   this.itemRef.draggable = true;
  // }

  // draggableOff() {
  //   this.itemRef.draggable = false;
  // }

  render() {
    const { onClick, item, children } = this.props;

    return (
      <div
        className="listview-group-item em-interactive"
        onClick={onClick}
        data-card-id={item.id}
      >
        { children }
      </div>
    );
  }
}

export default ListviewItem;
