import { useState, useEffect } from "react";

export const useObservable = (observable) => {
    const [state, setState] = useState(observable.getValue());
  
    const handleNext = value => {
      observable.next(value);
    };
  
    useEffect(() => {
      const sub = observable.subscribe(setState);
      return () => sub.unsubscribe();
    }, [observable]);
  
  
    return [state, handleNext];
  };