/**
*
* DatePopup
*
*/

import React from 'react';

import './DatePopup.css';

import Popover from 'components/Popover/Popover';
import DayPicker from 'react-day-picker';

function DatePopup(props: any) {
  const selectedDay = props.value ? new Date(props.value) : undefined;
  const openDate = selectedDay || new Date();

  return (
    <Popover
      windowBoundary={props.windowBoundary}
      outsideClick={props.close}
      className="em-date-popup"
    >
      <DayPicker
        month={openDate}
        selectedDays={selectedDay}
        onDayClick={props.save}
        firstDayOfWeek={1}
      />
    </Popover>
  );
}

export default DatePopup;
