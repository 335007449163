/**
*
* TaskDetail
*
*/

import React, { Fragment } from 'react';

import './TaskDetail.css';

import { colourPicker } from 'utils/core';

import DatePopup from 'components/DatePopup/DatePopup';

import PanelSection from 'components/SidePanel/PanelSection/PanelSection';
import Checklist from 'components/SidePanel/Checklist/Checklist';
import SessionLog from 'components/SidePanel/SessionLog/SessionLog';
import CurrentSession from 'components/SidePanel/CurrentSession/CurrentSession';
import EditPanelTextarea from 'components/SidePanel/EditPanelTextarea/EditPanelTextarea';
import EditPanelNumber from 'components/SidePanel/EditPanelNumber/EditPanelNumber';
import EditPanelDynamicSelect from 'components/SidePanel/EditPanelDynamicSelect/EditPanelDynamicSelect';

import CompleteButton from 'components/TaskButtons/CompleteButton/CompleteButton';
import PlayPauseButton from 'components/TaskButtons/PlayPauseButton/PlayPauseButton';
import StampButton from 'components/TaskButtons/StampButton/StampButton';
import MoveButton from 'components/TaskButtons/MoveButton/MoveButton';
import TaskTitleCard from '../TaskTitleCard/TaskTitleCard';
import TaskDeleteButton from '../TaskDeleteButton/TaskDeleteButton';
import CardSession from '../CardSession/CardSession';
import { formatCurrency } from '../../../utils/constants';
import { ITask } from 'utils/models/taskModel';
import { IGroup } from 'utils/models/groupModel';
import { IProject } from 'utils/models/projectModel';

const priorityOptions = [
  { id: 'Ignore', title: 'Ignore' },
  { id: 'None', title: 'None' },
  { id: 'Low', title: 'Low' },
  { id: 'Medium', title: 'Medium' },
  { id: 'High', title: 'High' },
];

interface ITaskDetail {
  task: ITask;
  project: IProject;
  group: IGroup;
  close: () => void;
}

function TaskDetail({ task, project, group, close }: ITaskDetail) {

  if (!task) {
    close();
    return null;
  }

  const style: any = { };

  const task_props = task.properties;
  const proj_props = project.properties;

  const isComplete = task_props.completed;
  const isActive = task_props.active;

  const hasTiming = proj_props.timeTracking;
  const hasTaskTracking = project.hasAddedTracking();
  const hasHourlyTracking = project.hasHourlyTracking();

  if (task_props.completed) {
    style.backgroundColor = colourPicker.green;
  } else {
    style.backgroundColor = colourPicker[group.color];
  }

  return (
    <Fragment>
      <div className="panel-group">
        <div className="panel-header">
          <div className="panel-title" onClick={close}>
            <i className="fa fa-close" />
            Close Panel
          </div>
          <div className="panel-controls">
            <TaskDeleteButton onDelete={() => task.delete()} />
          </div>
        </div>
        <TaskTitleCard
          task={task}
          color={group.color}
        />
        <div className="task-actions">
          <CompleteButton task={task} isComplete={isComplete} />
          <PlayPauseButton
            manualOption
            task={task}
            hasTiming={hasTiming}
            isComplete={isComplete}
            isActive={isActive}
          />
          <StampButton
            task={task}
            hasTiming={hasTiming}
            isComplete={isComplete}
          />
          <MoveButton task={task} />
        </div>
      </div>

      {isActive && <CurrentSession task={task} project={project} />}

      {/* Descripotion */}
      <PanelSection
        title="Notes"
        inline={false}
        value={task_props.description}
        editForm={EditPanelTextarea}
        save={value => task.simpleUpdate(value, 'description')}
      />

      {/* Urgency - State Dependant */}
      { !isComplete
      && (
      <PanelSection
        title="Priority"
        value={task_props.priority}
        editForm={renderProps => (
          <EditPanelDynamicSelect
            {...renderProps}
            options={priorityOptions}
          />
        )}
        inlineEdit={false}
        save={value => task.simpleUpdate(value, 'priority')}
      />
      )}

      { !isComplete
      && (
        <PanelSection
          title="Deadline"
          value={task_props.deadline}
          formattedValue={formatDate(task_props.deadline)}
          editForm={DatePopup}
          dockedEdit={false}
          save={value => task.dateUpdate(value, 'deadline')}
        />
      )}

      { isComplete
      && (
        <PanelSection
          title="Completed"
          value={task_props.completedDate}
          formattedValue={formatDate(task_props.completedDate)}
          editForm={DatePopup}
          dockedEdit={false}
          save={value => task.dateUpdate(value, 'completedDate')}
        />
      )}

      {/* Tracking Metrics */}
      { hasTaskTracking
      && (
        <PanelSection
          title="Task Value"
          value={task_props.fixedValue}
          formattedValue={formatCurrency(task_props.fixedValue)}
          editForm={EditPanelNumber}
          save={value => task.simpleUpdate(value, 'fixedValue')}
        />
      )}

      { hasTiming
      && (
        <PanelSection
          title="Timing Log"
          selectable={false}
          bodyscroll
          value={(
            <CardSession key={task.id} task={task} project={project}>
              {({ durationDisplay, value: sessionValue }) => {
                const hourlyString = ` | ${formatCurrency(sessionValue)}`;
                return `${durationDisplay} hrs${hasHourlyTracking ? hourlyString : ''}`;
              }}
            </CardSession>
          )}
          content={task.sessions.length > 0 && <SessionLog task={task} />}
        />
      )}

      <PanelSection
        title={checklistTitle(task.checklist)}
        selectable={false}
        content={<Checklist task={task} />}
      />

      { !isComplete
      && (
        <PanelSection
          title="Last Active"
          value={task_props.lastActive}
          formattedValue={formatDate(task_props.lastActive)}
          editForm={DatePopup}
          dockedEdit={false}
          save={value => task.dateUpdate(value, 'lastActive')}
        />
      )}
    </Fragment>
  );
}

function checklistTitle(checklist) {
  let out = 'Checklist';
  const length = checklist.length;

  if (length === 0) return out;

  const completed = checklist.filter(a => a.complete);

  out += ` (${completed.length}/${length} Completed)`;

  return out;
}

function formatDate(ms) {
  return ms && new Date(ms).toDateString();
}

export default TaskDetail;
