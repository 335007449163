/**
 *
 * Upgrade
 *
 */ 

import React from 'react';

import './Upgrade.css';

import OwlPath from 'assets/images/origami-icons/owl-clear.png';
import SparrowPath from 'assets/images/origami-icons/sparrow-clear.png';
import PageHeader from '../../components/PageHeader/PageHeader';
import { isPremium, buyEMPro, Cloud } from 'utils/premium';
import { isWindows } from 'utils/utils';

function Upgrade() {

  return (
    <div className="page-wrapper">
      <PageHeader header="Features" />
      <div id="featurePageContent" className="vert-scroll page-body">
        <div id="featuresWrap">
          <h2 className="animationQueue welcome-header">Eclipse Manager to Suit You</h2>

          <div className="animationQueue welcome-mat">
            <p>Use Eclipse Core for free. Packed with essential features for all users.</p>
            <p className="br">When you’re ready to grow your productivity, so is Eclipse Pro.</p>
            {!isWindows() && (
              <>
                <p className="br warning">Sorry, it is currently only possible to upgrade through the Windows Store app.</p>
                <a className="upgrade em-button-theme" href="https://www.microsoft.com/en-us/p/eclipse-manager/9wzdncrdjmh1">Download here</a>
              </>
            )}
          </div>

          <div id="featureList" className="animationQueue row max-h">
            <div className="feature-wrap">
              <div className="feature-plan feature-plan-free">
                <h3 className="mid-a">
                  <img alt="" src={SparrowPath} />
                  <span>Core</span>
                </h3>

                <div className="min-a">
                  <div className="price-wrapper">
                    <span className="feature-price">FREE</span>
                  </div>
                  <div className="feature-plan-title min-t mid-b">Core Highlights:</div>
                  <ul className="feature-list min-b">
                    <li>Unlimited Projects & Tasks</li>
                    <li>Project Overview</li>
                    <li>Sync Across Devices</li>
                    <li>Time Tracking</li>
                  </ul>
                  <div className="spacer-solid" />
                  <button type="button" className="min-a upgradeOwned">(Current Plan)</button>
                </div>
              </div>
            </div>

            <div className="feature-wrap">
              <div className="feature-plan feature-plan-power">
                <h3 className="mid-a">
                  <img alt="" src={OwlPath} />
                  <span>Pro</span>
                </h3>

                <div className="min-a">
                  <div className="price-wrapper">
                    <span className="feature-currency">{ Cloud.cloudSyncPrice_Symbol }</span>
                    <span className="feature-price">{ Cloud.cloudSyncPrice_Month }</span>
                    <span className="feature-duration"> month</span>
                  </div>
                  <div className="feature-plan-title min-t mid-b">All Core Features, Plus:</div>
                  <ul className="feature-list min-b">
                    <li>Cost Tracking</li>
                    {/* <li>Offline Usage</li> */}
                    <li>Excel Export</li>
                    <li>Project Templates</li>
                    <li>Reports</li>
                    <li>Project Review</li>
                  </ul>
                  <div className="spacer-solid" />
                  { isPremium() ? (
                    <button type="button" className="min-a upgradeOwned">(Current Plan)</button>
                  ) : isWindows() ? ( 
                    <button onClick={buyEMPro} type="button" className="min-a">Upgrade</button>
                  ) : (
                    <a className="min-a" href="https://www.microsoft.com/en-us/p/eclipse-manager/9wzdncrdjmh1">Download on Windows</a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upgrade;
