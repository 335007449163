import 'react-app-polyfill/stable';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import 'react-day-picker/lib/style.css';
import 'react-dragula/dist/dragula.min.css';
import 'array-flat-polyfill';

import './assets/fonts/eclipse-icons.css';
import './assets/fonts/font-awesome.min.css';
import './assets/fonts/open-sans.css';

import './styles/reset.css';
import './styles/animation.css';
import './styles/index.css';
import './styles/form.css';
import './styles/page.css';

import 'utils/premium';
import 'utils/analytics';

import { register } from 'utils/registerServiceWorker';
import App from './containers/App/App';
import { AzureAD, AuthenticationState, IAccountInfo } from 'react-aad-msal';
import { authProvider } from 'utils/auth-wrapper';
import Login from 'containers/Login/Login';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { isWindows } from 'utils/utils';
import { localServer } from 'utils/config';
import { appInsights } from 'utils/analytics';

if (isWindows()) {
  console.debug('Windows Application')
}

const Root = () => {
  const [authState, setAuthState] = useState(authProvider.authenticationState);

  useEffect(() => {
    console.debug('Starting App', new Date().toLocaleTimeString());
    const handleAccountInfo = (accountInfo: IAccountInfo) => {
      const id = accountInfo?.account?.accountIdentifier;
      if (id) {
        appInsights.context.user.id = id;
        appInsights.setAuthenticatedUserContext(id);
      }
    }
    const handleAuthState = (authState: AuthenticationState) => {
      setAuthState(authState);
      if (authState === AuthenticationState.Authenticated) {
        appInsights.trackMetric({ name: 'authenticated-app-start', average: 1 });
      }
    }

    authProvider.registerAcountInfoHandler(handleAccountInfo);
    authProvider.registerAuthenticationStateHandler(handleAuthState);

    return () => {
      authProvider.unregisterAccountInfoHandler(handleAccountInfo);
      authProvider.unregisterAuthenticationStateHandler(handleAuthState);
    }
  }, [])

  console.debug('OnStartAuthState', authProvider.authenticationState);

  if (localServer) {
    return <App />
  }

  return (
    <LoadingWrapper loading={!authState} message="Logging In...">
      <AzureAD provider={authProvider} forceLogin={false}>
        {({ login, authenticationState }) => {
          console.debug(`Authentication State: ${authenticationState}`, new Date().toLocaleTimeString())

          if(authenticationState === AuthenticationState.InProgress) {
            return <LoadingWrapper loading={true} message="Logging In..." />
          }

          if (authenticationState !== AuthenticationState.Authenticated) {
            return <Login login={login} />
          }

          return <App />
        }}
      </AzureAD >
    </LoadingWrapper>
  )
}

ReactDOM.render(<Root/>, document.getElementById('root'));
register();
