/**
*
* CurrentSession
*
*/

import React, { PureComponent, Fragment } from 'react';
import './CurrentSession.css';

import { formatDuration } from 'utils/date';
import { formatCurrency } from '../../../utils/constants';

class CurrentSession extends PureComponent<any, any> {
  clock
  constructor(props) {
    super(props);
    const { project } = props;
    const duration = this.getDuration();

    this.state = {
      duration: formatDuration(duration, true),
      value: props.task.calculateSessionValue(duration, project.getDefaultRate()),
    };
  }

  componentDidMount() {
    this.clock = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clock);
  }

  getDuration() {
    const { task } = this.props;
    const { starttime } = task.properties;
    return Date.now() - new Date(starttime).getTime();
  }

  tick() {
    const { task, project } = this.props;
    const duration = this.getDuration();
    this.setState({
      duration: formatDuration(duration, true),
      value: task.calculateSessionValue(duration, project.getDefaultRate()),
    });
  }

  render() {
    const { duration, value } = this.state;
    const { project } = this.props;
    const hasHourlyTracking = project.hasHourlyTracking();

    const [hours, mins, secs] = duration.split(':');

    return (
      <div className="session-clock">
        <div>Current Session:</div>
        <div className="clock">
          {hours}
          {':'}
          {mins}
          <span className="seconds">
            {':'}
            {secs}
          </span>
        </div>
        { hasHourlyTracking && (
          <Fragment>
            <div>|</div>
            <div>
              {formatCurrency(value)}
            </div>
            <div>|</div>
            <div>
              {formatCurrency(project.getDefaultRate())}
              {'/hr'}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default CurrentSession;
