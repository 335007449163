/**
*
* ProjectDetail
*
*/

import React, { Fragment, PureComponent } from 'react';


import classNames from 'classnames';
import { ExportToCsv } from 'export-to-csv';

import { COST_TRACKING_OPTIONS, TIME_TRACKING_OPTIONS, COST_UPGRADE_MSG } from 'utils/constants';

import EmProgress from 'components/EmProgress/EmProgress';
// import TeamView from 'components/TeamView/TeamView';

import ProjectWidgetProgress from 'components/ProjectWidgets/ProjectWidgetProgress/ProjectWidgetProgress';
import ProjectWidgetValue from 'components/ProjectWidgets/ProjectWidgetValue/ProjectWidgetValue';
import ProjectWidgetTime from 'components/ProjectWidgets/ProjectWidgetTime/ProjectWidgetTime';
import ProjectWidgetRecent from 'components/ProjectWidgets/ProjectWidgetRecent/ProjectWidgetRecent';

import SidePanel from 'components/SidePanel/SidePanel';
import PanelSection from 'components/SidePanel/PanelSection/PanelSection';
import EditPanelText from 'components/SidePanel/EditPanelText/EditPanelText';
import EditPanelNumber from 'components/SidePanel/EditPanelNumber/EditPanelNumber';
import EditPanelTextarea from 'components/SidePanel/EditPanelTextarea/EditPanelTextarea';
import EditPanelOption from 'components/SidePanel/EditPanelOption/EditPanelOption';
import EditPanelDynamicSelect from 'components/SidePanel/EditPanelDynamicSelect/EditPanelDynamicSelect';

import Dashboard from 'components/Dashboard/Dashboard';
import DashCard from 'components/Dashboard/DashCard/DashCard';
import ProjectDeleteForm from '../ProjectDeleteForm/ProjectDeleteForm';
import { formatCurrency, HOURLY_TRACKING } from '../../../utils/constants';
import AddProjectCard from '../../Overview/AddProjectCard/AddProjectCard';

import './ProjectDetail.css';
import { formatDuration, formatyyyymmdd } from '../../../utils/date';

import { ScreenContext, IDataControllerState } from 'utils/context';
import { ITaskArray } from 'utils/models/taskModel';
import { IGroup } from 'utils/models/groupModel';
import { IProject, ICostTrackingTypes, IProjectUpdate } from 'utils/models/projectModel';
import { isPremium } from 'utils/premium';
import PremiumBlockModal from 'components/PremiumBlockModal/PremiumBlockModal';
import PremiumBlockCard from 'components/PremiumBlockCard/PremiumBlockCard';
import { isWindows } from 'utils/utils';

async function WindowsFileSave(data, title) {
  try {
    const Windows = (window as any).Windows;
    const savePicker = new Windows.Storage.Pickers.FileSavePicker();

    savePicker.suggestedStartLocation = Windows.Storage.Pickers.PickerLocationId.documentsLibrary;
    savePicker.fileTypeChoices.insert("Comma Seperated Values", [".csv"]);
    savePicker.suggestedFileName =title;

    //Pick File
    const file = await savePicker.pickSaveFileAsync()

    //No File Picked - Exit Silently
    if (!file) return false;

    //Lock File
    await Windows.Storage.CachedFileManager.deferUpdates(file);

    //Write File
    await Windows.Storage.FileIO.writeTextAsync(file, data, "data:text/csv;charset=utf-8")
    await Windows.Storage.CachedFileManager.completeUpdatesAsync(file)
  } catch(err) {
    console.log('Export Failed', err)
  }
}

interface IProjectDetail {
  project: IProject;
  tasks: ITaskArray;
  data: IDataControllerState;
  group: IGroup;
}

class ProjectDetail extends PureComponent<IProjectDetail, any> {
  constructor(props) {
    super(props);

    this.state = {
      addProject: false,
      confirmDelete: false,
      exportModal: false,
    };
  }

  updateCostTrackingType(value: ICostTrackingTypes) {
    const { project } = this.props;
    const updates: IProjectUpdate[] = [{ value, prop: 'costTracking' }];

    if (value === HOURLY_TRACKING) {
      updates.push({ prop: 'timeTracking', value: true });
    }

    project.simpleUpdates(updates);
  }

  exportProject() {
    const { tasks, project } = this.props;
    const date = new Date();

    const msHour = 1000 * 60 * 60;
    const hasTimeTracking = project.hasTimeTracking();
    const hasCostTracking = project.hasCostTracking();

    const data = tasks.map((task) => {
      const { properties } = task;
      const column = project.columns.find(col => col.id === properties.column);

      const row: any = {
        Title: properties.title,
        Notes: properties.description,
        Completed: properties.completed ? 'Yes' : 'No',
        'Completed Date': properties.completedDate || '',
        Column: column ? column.title : '',
        Deadline: properties.deadline || '',
      };

      if (hasTimeTracking) {
        row['Hours Spent'] = (task.stats.timespent / msHour).toFixed(2);
        row['Formatted Time'] = formatDuration(task.stats.timespent);
      }

      if (hasCostTracking) {
        row.Value = task.getValue();
      }

      return row;
    });

    const options = {
      filename: `${project.properties.title}_${formatyyyymmdd(date, '-')}`,
      title: `${project.properties.title} - ${date.toLocaleDateString()}`,
      showLabels: true,
      showTitle: true,
      useKeysAsHeaders: true,
    };

    if (data.length === 0) {
      // No Data - Return
      return;
    }

    const csvExporter = new ExportToCsv(options);

    if (isWindows()) {
      const csv = csvExporter.generateCsv(data, true);
      WindowsFileSave(csv, options.filename)
    } else {
      csvExporter.generateCsv(data);
    }
  }

  togglePremiumExportBlock(exportModal) { this.setState({ exportModal }); }

  toggleAddProject(addProject) { this.setState({ addProject }); }

  toggleProjetDelete(confirmDelete) { this.setState({ confirmDelete }); }

  render() {
    const { confirmDelete, addProject, exportModal } = this.state;
    const { project, data, group, tasks } = this.props;

    if (!project) return null;

    const projProps = project.properties;
    const projStats = project.stats;

    const completed = project.percentComplete();
    const tasksRemaining = project.remaining().toString();

    const hasTiming = projProps.timeTracking;
    const costTrackingType = projProps.costTracking;
    const hasCostTracking = project.hasCostTracking();
    const hasHourlyTracking = project.hasHourlyTracking();
    const hasAddedTracking = project.hasAddedTracking();

    const costTrackingOption = COST_TRACKING_OPTIONS.find(({ id }) => id === costTrackingType);
    const prettyCostTracking = costTrackingOption ? costTrackingOption.title : 'Please Select';

    return (
      <ScreenContext.Consumer>
        {screen => (
          <Fragment>
            <SidePanel className={classNames('detail-panel', (screen.isMobile || screen.mobileView) && 'full-width')}>
              <div className="section-group">
                <div className="section-header">Progress</div>
                <div className="panel-progress panel-row">
                  <div className="progress-stats">
                    <p>
                      <i className="fa fa-check" />
                      {completed}
                      {'% Completed'}
                    </p>
                    <p>
                      { tasksRemaining }
                      {' Task'}
                      { tasksRemaining !== '1' && 's'}
                      {' Remaining'}
                    </p>
                  </div>
                  <EmProgress progress={completed} />
                </div>
                { hasAddedTracking && (
                  <div className="panel-progress panel-row">
                    <div className="progress-stats">
                      <p>
                        <i className="fa fa-money" />
                        {`Recieved: ${formatCurrency(projStats.valueRecieved)}`}
                      </p>
                      <p>
                        {`Project Total: ${formatCurrency(projStats.value)}`}
                      </p>
                    </div>
                    <EmProgress progress={project.percentRecieved()} />
                  </div>
                )}
                { hasTiming && (
                  <div className="panel-progress panel-row">
                    <div className="progress-stats">
                      { hasHourlyTracking && (
                        <p>
                          <i className="fa fa-money" />
                          {`Recieved: ${formatCurrency(projStats.valueRecieved)}`}
                        </p>
                      )}
                      <p>
                        <i className="fa fa-clock-o" />
                        {`Total Time: ${project.timeDisplay()} hrs`}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="section-group">
                <div className="section-header">Detail</div>
                <PanelSection
                  title="Name"
                  inlineEdit={false}
                  value={projProps.title}
                  editForm={EditPanelText}
                  save={value => project.simpleUpdate(value, 'title')}
                />
                <PanelSection
                  renderProp
                  title="Association"
                  inlineEdit={false}
                  value={group.title}
                  save={value => project.moveGroup(value)}
                  editForm={renderProps => (
                    <EditPanelOption
                      {...renderProps}
                      options={data.groups.map(a => ({
                        id: a.id,
                        title: a.title,
                      }))}
                      value={group.id}
                    />
                  )}
                />
                {hasHourlyTracking && (
                  // Updating the rate doesnt backdate - correct?
                  <PanelSection
                    renderProp
                    title="Default Rate"
                    value={projProps.defaultRate}
                    formattedValue={`${formatCurrency(projProps.defaultRate)}`}
                    save={value => project.simpleUpdate(value, 'defaultRate')}
                    editForm={EditPanelNumber}
                  />
                )}
                <PanelSection
                  title="Description"
                  value={projProps.description}
                  editForm={EditPanelTextarea}
                  inline={false}
                  save={value => project.simpleUpdate(value, 'description')}
                />
              </div>

              {/* <div className="section-group">
                <div className="section-header">Team</div>
                <div className="panel-row">
                  <TeamView />
                </div>
              </div> */}

              <div className="section-group">
                <div className="section-header">Configuration</div>
                <PanelSection
                  renderProp
                  inlineEdit={false}
                  title="Cost Tracking"
                  value={prettyCostTracking}
                  save={value => this.updateCostTrackingType(value)}
                  editForm={renderProps => isPremium() ? (
                    <EditPanelDynamicSelect
                      {...renderProps}
                      options={COST_TRACKING_OPTIONS}
                      value={costTrackingType}
                    />
                  ) : (
                    <PremiumBlockCard
                      forwardRef={renderProps.forwardRef}
                      title={null}
                      info={COST_UPGRADE_MSG}
                      minimal={true}
                    />
                  )}
                />
                <PanelSection
                  renderProp
                  title="Time Tracking"
                  value={hasTiming ? 'On' : 'Off'}
                  save={value => project.saveBoolProperty(value, 'timeTracking')}
                  selectable={costTrackingType !== HOURLY_TRACKING}
                  editForm={renderProps => (
                    <EditPanelDynamicSelect
                      {...renderProps}
                      options={TIME_TRACKING_OPTIONS}
                      value={hasTiming ? 'on' : 'off'}
                    />
                  )}
                />
                {/* { section('Public', 'Off') } */}
              </div>

              <div className="section-group">
                <div className="section-header">Actions</div>
                { iconSection('Duplicate Project', 'fa-copy', () => this.toggleAddProject(true)) }
                { iconSection('Export to .csv', 'fa-upload', () => {
                  if (isPremium()) {
                    this.exportProject()
                  } else {
                    this.togglePremiumExportBlock(true)
                  }
                }) }
                { iconSection('Delete', 'fa-trash', () => this.toggleProjetDelete(true)) }
              </div>
            </SidePanel>

            {!screen.isMobile && !screen.mobileView && (
              <Dashboard horizontal>
                <DashCard>
                  <div className="card-header">Activity Log</div>
                  <div className="card-body overflow">
                    <ProjectWidgetRecent project={project} tasks={tasks} />
                  </div>
                </DashCard>

                <DashCard>
                  <div className="card-header">Progress</div>
                  <div className="card-body">
                    <ProjectWidgetProgress project={project} />
                  </div>
                </DashCard>

                { hasCostTracking
                  && (
                  <DashCard>
                    <div className="card-header">Task Value</div>
                    <div className="card-body">
                      <ProjectWidgetValue project={project} tasks={tasks} />
                    </div>
                  </DashCard>
                  )
                }

                { hasTiming
                  && (
                  <DashCard>
                    <div className="card-header">Time Spent</div>
                    <div className="card-body">
                      <ProjectWidgetTime tasks={tasks} />
                    </div>
                  </DashCard>
                  )
                }
              </Dashboard>
            )}

            { confirmDelete && (
              <ProjectDeleteForm
                close={() => this.toggleProjetDelete(false)}
                submit={() => project.delete()}
              />
            )}

            { addProject && (
              <AddProjectCard
                duplicate
                data={data}
                templateId={project.id}
                groupId={project.association_id}
                close={() => this.toggleAddProject(false)}
              />
            )}

            { exportModal && (
              <PremiumBlockModal
                info="This feature allows you to export your project so you can open it in excel."
                close={() => this.togglePremiumExportBlock(false)}
              />
            )}
          </Fragment>
        )}
      </ScreenContext.Consumer>
    );
  }
}

function iconSection(title, icon, event, disabled=false) {
  return (
    <div
      className={classNames('panel-section','can-hover', disabled && 'disabled')}
      onClick={event}
    >
      <div className="title-row">
        <i className={`fa ${icon}`} />
        {' '}
        { title }
      </div>
    </div>
  );
}


export default ProjectDetail;
