/**
*
* ColumnHeader
*
*/

import React from 'react';

// import './ColumnHeader.css';

function ColumnHeader(props) {
  return (
    <h4>{ props.group.title }</h4>
  );
}

export default ColumnHeader;
