/**
*
* TaskDeleteButton
*
*/

import React, { PureComponent } from 'react';
import WindowClickWrapper from '../../../components/WindowClickWrapper/WindowClickWrapper';

import './TaskDeleteButton.css';

interface ITaskDeleteButton {
  onDelete: () => void;
}

class TaskDeleteButton extends PureComponent<ITaskDeleteButton, any> {
  constructor(props) {
    super(props);

    this.state = {
      deleteMode: false,
    };
  }

  closeDeleteMode() {
    this.setState({ deleteMode: false });
  }

  render() {
    const props = this.props;

    if (!this.state.deleteMode) {
      return (
        <button type="button" className="control-btn" onClick={() => this.setState({ deleteMode: true })}>
          <i className="fa fa-trash" />
          {'Delete'}
        </button>
      );
    }

    return (
      <WindowClickWrapper outsideClick={() => this.closeDeleteMode()}>
        { renderProps => (
          <div ref={renderProps.ref} className="firstOfGroup taskDeleteButtonWrapper">
            <button type="button" className="control-btn" onClick={() => props.onDelete()}>
              <i className="fa fa-trash" />
              {'Confirm'}
            </button>
            <button type="button" className="control-btn" onClick={() => this.closeDeleteMode()}>
              <i className="fa fa-times" />
              {'Cancel'}
            </button>
          </div>
        )}
      </WindowClickWrapper>
    );
  }
}


export default TaskDeleteButton;
