/**
 *
 * Project
 *
 */

import React, { Fragment } from 'react';

import './Project.css';

import { useDataContext } from 'utils/context';
import { colourPicker } from 'utils/core';

import { Route, Redirect, Switch, NavLink, matchPath } from 'react-router-dom';

import PageHeader from 'components/PageHeader/PageHeader';

import ProjectDetail from './ProjectDetail/ProjectDetail';
import ProjectTasks from './ProjectTasks/ProjectTasks';
import TasksFilter from './TasksFilter/TasksFilter';


function Project(props) {
  const data = useDataContext();
  const location = props.location;
  const match = props.match;

  const projectId = parseInt(match.params.projectId, 10);
  const isTaskPage = matchPath(location.pathname, { path: `${match.url}/tasks` });

  if (!projectId) {
    return <Redirect to="/" />;
  }

  const project = data.projects.get(projectId);

  if (!project) {
    return <Redirect to="/" />;
  }

  const group = data.groups.get(project.association_id);
  const tasks = project.getChildren();
  const filteredTasks = project.getFilteredChildren();

  return (
    <div className="page-wrapper">
      <PageHeader
        header={project.properties.title}
        background={colourPicker[group.color]}
        menu={(
          <Fragment>
            <NavLink activeClassName="active" to={`${match.url}/overview`}>Project</NavLink>
            <NavLink activeClassName="active" to={`${match.url}/tasks`}>Tasks</NavLink>
          </Fragment>
        )}
        buttons={isTaskPage && <TasksFilter project={project} />}
      />
      <div className="page-body page-col">
        <Switch>
          <Route
            path={`${match.url}/overview`}
            render={() => <ProjectDetail data={data} group={group} project={project} tasks={tasks} />}
          />
          <Route
            path={`${match.url}/tasks`}
            render={renderProps => (
              <ProjectTasks
                group={group}
                project={project}
                filteredTasks={filteredTasks}
                tasks={tasks}
                {...renderProps}
              />
            )}
          />
          <Redirect to={`${match.url}/tasks`} />
        </Switch>
      </div>
    </div>
  );
}


export default Project;
