/**
*
* AssocHeader
*
*/

import React from 'react';

// import './AssocHeader.css';

function AssocHeader(props) {
  return (
    <h4>{ props.group.title }</h4>
  );
}

export default AssocHeader;
