/**
*
* FauxPopover
*
*/

import React from 'react';

// import './FauxPopover.css';

import { CSSTransition } from 'react-transition-group';

import WindowClickWrapper from '../WindowClickWrapper/WindowClickWrapper';

function FauxPopover(props: any) {
  const classList = ['em-popup', 'em-interactive'];

  if (props.className) classList.push(props.className);

  return (
    <CSSTransition in appear={!props.noAnimation} timeout={300} classNames="fade">
      <WindowClickWrapper outsideClick={props.outsideClick}>
        { renderProps => (
          <div
            className={classList.join(' ')}
            ref={renderProps.ref}
          >
            { props.children }
          </div>
        )}
      </WindowClickWrapper>
    </CSSTransition>
  );
}

export default FauxPopover;
