/**
*
* ReportTimelineChart
*
*/

import React, { useState, useEffect } from 'react';

import { Bar } from 'react-chartjs-2';

// import './ReportTimelineChart.css';

const buildOptions = (formatter, legend) => ({
  maintainAspectRatio: false,
  legend: { display: false },
  layout: {
    padding: { left: 0, right: 20, top: 20, bottom: 10 },
  },
  tooltips: {
    callbacks: { label: tooltipItem => `${legend}: ${formatter(tooltipItem.yLabel)}` }
  },
  scales: {
    yAxes: [{
      display: true,
      scaleLabel: { display: true, labelString: legend },
      ticks: { beginAtZero: true, callback: formatter }
    }]
  }
});

const buildDataSet = (dataObj, label) => {
  if (!dataObj) {
    return null;
  }

  const backgroundColor = 'rgba(0, 81, 179, 0.5)';
  const borderColor = 'rgba(0, 81, 179, 0.5)';
  const { labels, dataPoints: data } = dataObj;

  return {
    labels: labels.map(l => l.split('&&')),
    datasets: [{ label, data, backgroundColor, borderColor, borderWidth: 1 }]
  };
}

const ReportTimelineChart = ({ data, formatter, legend }) => {
  const [dataset, setDataSet] = useState(null);
  const [options, setOptions] = useState(null)

  useEffect(() => {
    setDataSet(buildDataSet(data, legend))
  }, [data, legend])

  useEffect(() => {
    setOptions(buildOptions(formatter, legend));
  }, [formatter, legend])

  if (!options || !dataset) {
    return null;
  }

  return <Bar data={dataset} options={options} />;
}

export default ReportTimelineChart;
