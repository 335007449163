/**
*
* MoveTaskForm
*
*/

import React, { useRef, useState, useEffect } from 'react';

// import './MoveTaskForm.css';

import { useDataContext } from 'utils/context';

import Popover from 'components/Popover/Popover';
import { ITask } from 'utils/models/taskModel';

interface IMoveTaskForm {
  task: ITask,
  close: () => void;
}

function MoveTaskForm({ task, close }: IMoveTaskForm) {
  let select = useRef();
  const data = useDataContext();
  const [projectId, setProjectId] = useState(task.project_id); 
  const [columnId, setColumnId] = useState(task.properties.column);
  const [projects, setProjects] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const projects = [];
    const columns = [];
  
    const project = data.projects.find(a => a.id === projectId);
  
    data.projects.forEach(({ id, properties }) => {
      projects.push(<option key={id} value={id}>{ properties.title }</option>);
    });
  
    project.columns.forEach(({ id, title }) => {
      columns.push(<option key={id} value={id}>{ title }</option>);
    });

    setProjects(projects);
    setColumns(columns);
  }, [data.projects, projectId])


  function changeProject(e) {
    const projectId = parseInt(e.target.value, 10);
    const project = data.projects.get(projectId);

    if (!project) return;

    const column = project.columns[0];

    setProjectId(projectId)
    setColumnId(column && column.id);
  }

  function changeColumn(e) {
    setColumnId(e.target.value);
  }

  function submit(e) {
    e.preventDefault();

    task.move(projectId, columnId);
    close();
  }

  return (
    <Popover
      windowBoundary
      outsideClick={close}
      className="compact-form wide"
    >
      <form onSubmit={submit}>
        <div className="form-body">
          <div className="basic-row">
            Project:
            <select
              ref={select}
              value={projectId}
              onChange={changeProject}
              disabled={projects.length === 1}
            >
              { projects }
            </select>
          </div>
          <div className="basic-row">
            Column:
            <select
              value={columnId}
              onChange={changeColumn}
              disabled={columns.length === 1}
            >
              { columns }
            </select>
          </div>
        </div>
        <div className="action-row">
          <button type="submit">
            <i className="fa fa-check" />
            {' Save'}
          </button>
          <button type="button" onClick={close}>
            <i className="fa fa-times" />
            {' Close'}
          </button>
        </div>
      </form>
    </Popover>
  );
}



export default MoveTaskForm;
