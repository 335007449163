/**
*
* SidePanel
*
*/

import React from 'react';

import './SidePanel.css';

import { CSSTransition } from 'react-transition-group';

function SidePanel(props) {
  const appear = !!props.appear;
  const inBool = props.in !== false;
  const classList = ['project-panel'];

  if (props.className) classList.push(props.className);

  return (
    <CSSTransition appear={appear} in={inBool} timeout={150} classNames="slide" unmountOnExit>
      <div className={classList.join(' ')}>{ props.children }</div>
    </CSSTransition>
  );
}


export default SidePanel;
