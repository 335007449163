/**
 *
 * User
 *
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './User.css';


import PageHeader from 'components/PageHeader/PageHeader';
import { dbPreferencesUpsert } from 'utils/api';
import LegacyMigrate from '../../components/LegacyMigrate/LegacyMigrate';
import PanelSection from 'components/SidePanel/PanelSection/PanelSection';
import EditPanelText from 'components/SidePanel/EditPanelText/EditPanelText';
import { userPreferences, useUserPreferences } from 'utils/preferences';
import { useDataContext } from 'utils/context';
import { authProvider } from 'utils/auth-wrapper';
import { isPremium, getPremiumExpiry, buyEMPro } from 'utils/premium';
import { localServer } from 'utils/config';
import { isWindows } from 'utils/utils';

const validateCcy = (val) => {
  if (val.length > 3) {
    return 'Too Long';
  }

  return undefined;
}


function User() {
  // const { loading, user, logout } = useAuth0();
  const [email, setEmail] = useState(null);
  const data = useDataContext();
  const [preferences] = useUserPreferences()
  const history = useHistory();

  useEffect(() => {
    if (localServer) {
      return;
    }

    authProvider.getIdToken().then(({ idToken }) => {
      const email = idToken.claims.emails[0];
      setEmail(email);
    });
  }, [])

  const updatePreferences = (next) => {
    const current = preferences;
    userPreferences.next(next)
    dbPreferencesUpsert({ preferences: next }).then(null, () => {
      userPreferences.next(current)
    });
  }

  const tryUpgrade = () => isWindows() ? buyEMPro : history.push('/upgrade');

  return (
    <div className="page-wrapper">
      <PageHeader header="Profile" />
      <div className="page-body vert-scroll">
        <div className="userWrapper">
          <div className="userBody">
            <h2>User Info</h2>
            <p>
              <span>User:</span>
              { email }
            </p>
            <p>
              <span>Account:</span>
              { isPremium() ? `Premium - Expires on ${getPremiumExpiry()}` : 'Standard' }
            </p>
            <div className="buttonWrapper">
              { !isPremium() ? <button onClick={tryUpgrade} type="button">Upgrade</button> : null}
              <button type="button" onClick={authProvider.logout}>Log Out</button>
            </div>
          </div>
          <div className="userBody">
            <h2>Edit Perferences</h2>
            <PanelSection
              title="Currency"
              value={preferences && preferences.currency}
              editForm={(renderProps) => <EditPanelText validate={validateCcy} {...renderProps} />}
              save={value => updatePreferences({ currency: value })}
            />
          </div>
          <div className="userBody">
            <h2>Help & Support</h2>
            <p>Learn more about using the app on our help page: <a href="https://www.eclipsemanager.com/guide">eclipsemanager.com/guide</a></p>
            <p>Get in touch: <b>info@eclipsemanager.com</b></p>
            <p><a href="https://eclipsemanager.com/terms">Terms of Use</a></p>
            <p><a href="https://eclipsemanager.com/privacy">Privacy Policy</a></p>
          </div>
          <div className="userBody">
            <h2>Migrate Data</h2>
            <p>If you used the previous version of the app unfortunately it is not possible to migrate your data automatically. To migrate your data click the migrate button below and login into your microsoft account.</p>
            <div className="buttonWrapper">
              <LegacyMigrate data={data} />
            </div>
          </div>
          {/* <div className="userBody">
            <h2>App Version</h2>
            <p>2.0.0</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default User;
