/**
*
* ListView
*
*/

import React, { PureComponent } from 'react';

import Dragula from 'react-dragula';
import './ListView.css';

import { ScreenState } from 'containers/ScreenController/ScreenController';
import ListviewItem from '../ListviewItem/ListviewItem';

class ListView extends PureComponent<any, any> {
  drake;
  boundAddItemBtn;
  boundAddItemForm;
  boundEditColumnForm;
  dragulaDecorator;

  constructor(props) {
    super(props);

    this.boundAddItemBtn = this.addItemBtn.bind(this);
    this.boundAddItemForm = this.addItemForm.bind(this);
    this.boundEditColumnForm = this.editColumnForm.bind(this);

    this.state = {
      addTaskId: -1,
      openEditColumnId: -1,
      showAddColumn: undefined,
    };
  }

  componentDidMount() {
    const { onMove } = this.props;

    let scrollable = true;
    document.addEventListener('touchmove', e => !scrollable && e.preventDefault(), { passive: false });

    if (!onMove) {
      return;
    }

    const options = {
      isContainer: el => el.classList.contains('listview-group-body'),
      moves: () => ScreenState.canDrag(),
    };
    const drake = Dragula([], options);

    drake.on('drag', () => {
      scrollable = false;
    });

    drake.on('drop', (el, target, source, sibling) => {
      // dont let drake manage our sort order, on drop find the new sort order
      drake.cancel(true);
      scrollable = true;

      if (!target || !target.dataset.groupId) {
        return false;
      }

      onMove(
        el.dataset.cardId,
        sibling && sibling.dataset.cardId,
        target.dataset.groupId,
      );
    });

    this.drake = drake;
  }

  componentWillUnmount() {
    this.drake && this.drake.destroy();
  }

  addItemBtn({ addText, groupKey }) {
    const className = 'listview-group-item listview-group-foot em-interactive';
    return (
      <div className={className} onClick={() => this.showAddItemForm(groupKey)}>
        <span className="listview-col-add">+</span>
        { addText }
      </div>
    );
  }

  editColumnForm({ form, id, group }) {
    const renderProps = {
      id,
      group,
      close: () => this.closeGroupConfigForm(id),
    };

    return form(renderProps);
  }

  addItemForm({ form, id }) {
    // Add close method to form
    const renderProps = {
      columnId: id,
      close: () => this.closeAddItemForm(id),
    };

    return <div className="listview-group-item em-interactive">{ form(renderProps) }</div>;
  }

  tailTemplate(tailName) {
    if (!tailName) return null;

    if (this.state.showAddColumn && this.props.addColumnForm) {
      const renderProps = {
        close: () => this.closeAddGroupForm(),
        targetName: tailName,
      };
      return (
        <div className="listview-group-tail">
          { this.props.addColumnForm(renderProps) }
        </div>
      );
    }
    return (
      <div className="listview-group-tail">
        <div onClick={e => this.showAddGroupForm(e)} className="tail-add em-interactive">
          <span className="listview-col-add">+</span>
            Add
          {' '}
          { tailName }
        </div>
      </div>
    );
  }

  groupTemplater = (groups) => {
    const state = this.state;
    const props = this.props;

    const { items, addText, groupRender, itemGroupSelector, groupKeySelector } = props;
    const { openEditColumnId } = state;
    const groupEditForm = props.groupEditForm;
    const groupIds = [];
    const itemMapping = {};

    items.forEach((item) => {
      let groupId = item[itemGroupSelector] || item.properties[itemGroupSelector] || null;
      const match = groups.find(group => group.id === groupId);

      // Item has found itself ungrouped. We can correct that here.
      if (!match) {
        console.debug('Ungrouped Item!');
        groupId = groups[0].id || null;
      }

      if (!itemMapping[groupId]) {
        itemMapping[groupId] = [];
        groupIds.push(groupId);
      }

      itemMapping[groupId].push(item);
    });

    return groups.map((group) => {
      const groupKey = group[groupKeySelector];
      const AddItemBtn = this.boundAddItemBtn;
      const AddItemForm = this.boundAddItemForm;
      const EditColumnForm = this.boundEditColumnForm;

      return (
        <div key={groupKey} className="listview-group">
          <div className="listview-group-header">
            { groupRender({ group }) }
            { groupKey && groupEditForm && (
              <div
                onClickCapture={e => this.showGroupConfigForm(e, groupKey)}
                className="listview-group-menu em-interactive"
              >
                { openEditColumnId === groupKey ? (
                  <i className="fa fa-times" />
                ) : (
                  <i className="fa fa-bars" />
                )}
              </div>
            )}
          </div>
          {
            groupEditForm
            && openEditColumnId === groupKey
            && <EditColumnForm form={groupEditForm} id={groupKey} group={group} />
          }
          <div className="listview-group-body" data-group-id={groupKey}>
            { this.itemTemplater(group, itemMapping[groupKey])}
          </div>
          { addText && (props.addItemForm && state.addTaskId === groupKey ? (
            <AddItemForm form={props.addItemForm} id={groupKey} />
          ) : (
            <AddItemBtn addText={addText} groupKey={groupKey} />
          ))}
        </div>
      );
    });
  }

  itemTemplater = (group, groupItems = []) => {
    const { itemRender } = this.props;

    return groupItems.map(item => (
      <ListviewItem
        key={item.id}
        item={item}
        itemRender={itemRender}
        onClick={e => this.itemClick(item)}
      >
        { itemRender({ item, group }) }
      </ListviewItem>
    ));
  }


  showGroupConfigForm(e, popoverId) {
    const openId = this.state.openEditColumnId;

    this.setState({
      openEditColumnId: popoverId === openId ? -1 : popoverId,
    });
  }

  showAddGroupForm(e) {
    this.setState({ showAddColumn: true });
  }

  showAddItemForm(popoverId) {
    const clickEvent = this.props.addClick;
    const hasForm = !!this.props.addItemForm;

    if (hasForm) this.setState({ addTaskId: popoverId });

    if (clickEvent) clickEvent(popoverId);
  }

  closeGroupConfigForm(id) {
    if (!id || this.state.openEditColumnId === id) {
      this.setState({ openEditColumnId: -1 });
    }
  }

  closeAddGroupForm() {
    this.setState({ showAddColumn: false });
  }

  closeAddItemForm(id) {
    if (!id || this.state.addTaskId === id) this.setState({ addTaskId: -1 });
  }

  itemClick(item) {
    const clickEvent = this.props.onClick;
    if (clickEvent) clickEvent(item);
  }

  surfaceClick(e) {
    const isSurfaceClick = isInClass(e.target, 'em-interactive');
    const emptyClick = this.props.emptyClick;

    if (!isSurfaceClick && emptyClick) emptyClick();
  }

  render() {
    const { groups, className, tailName, bodyComponent } = this.props;
    const classList = ['listview'];

    if (!groups || !groups.map) return null;

    if (className) classList.push(className);

    return (
      <div onClick={e => this.surfaceClick(e)} className={classList.join(' ')}>
        <div className="listview-surface" ref={this.dragulaDecorator}>
          { bodyComponent }
          { this.groupTemplater(groups) }
          { this.tailTemplate(tailName) }
        </div>
      </div>
    );
  }
}

function isInClass(startingNode, className) {
  let node = startingNode;
  while (node != null) {
    if (node.classList && node.classList.contains(className)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export default ListView;
