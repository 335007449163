/**
*
* AddProjectCard
*
*/

import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';

import './PremiumBlockCard.css';

function PremiumBlockCard({ history, info, forwardRef = undefined, title = 'Premium Required', minimal = false }) {

  return (
    <div ref={forwardRef} className={classNames('requires-premium-block',minimal && 'minimal')} >
      { title && <h4>{title}</h4> }
      { info && <p>{ info }</p> }
      <button onClick={() => history.push('/upgrade')} className="em-button-theme">Upgrade <i className="fa fa-star" /></button>
    </div>
  );
}

export default withRouter(PremiumBlockCard);
