/**
*
* ReviewPriority
*
*/

import React, { useState, useEffect } from 'react';

// import './ReviewPriority.css';
import ListView from 'components/ListView/ListView';

import { CSSTransition } from 'react-transition-group';
import { useDataContext } from 'utils/context';

import TaskCard from '../../Project/TaskCard/TaskCard';
import AssocHeader from '../../Overview/AssocHeader/AssocHeader';
import { PRIORITY_LOW, PRIORITY_MEDIUM, PRIORITY_HIGH } from '../../../utils/constants';
import EmptyReview from '../EmptyReview/EmptyReview';

const priorityGroupTemplate = [
  {
    id: PRIORITY_HIGH,
    title: PRIORITY_HIGH,
  },
  {
    id: PRIORITY_MEDIUM,
    title: PRIORITY_MEDIUM,
  },
  {
    id: PRIORITY_LOW,
    title: PRIORITY_LOW,
  },
];
const priorityGroupLength = priorityGroupTemplate.length;

function ReviewPriority() {
  const data = useDataContext();
  const [recentTasks, setRecentTasks] = useState(null);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    const activeTasks = data.tasks.filter(task => task.getPriorityValue() > 0);
    const dateGroups = priorityGroupTemplate.map(bucket => ({ ...bucket, count: 0 }));
    const nextRecentTasks = activeTasks.reduce((acc, task) => {
      for (let i = 0; i < priorityGroupLength; i++) {
        const bucket = dateGroups[i];
        if (task.properties.priority === bucket.id) {
          acc.push({ ...task, tempId: bucket.id });
          bucket.count += 1;
          break;
        }
      }

      return acc;
    }, []);
    const nextGroups = dateGroups.filter(bucket => bucket.count > 0);

    setRecentTasks(nextRecentTasks);
    setGroups(nextGroups);
  }, [data.tasks])

  if (!recentTasks) {
    return null;
  }

  if (recentTasks.length === 0) {
    return (
      <EmptyReview
        details={['This page shows tasks marked with priority - start adding priority to your tasks to see them here.']}
      />
    );
  }

  return (
    <CSSTransition in appear timeout={300} classNames="fade">
      <ListView
        className="recent-listview"
        items={recentTasks}
        groups={groups}
        groupKeySelector="id"
        itemGroupSelector="tempId"
        groupRender={renderProps => <AssocHeader {...renderProps} />}
        itemRender={(renderProps) => {
          const project = renderProps.item.getParent();
          const group = project.getParent();

          return (<TaskCard {...renderProps} project={project} group={group} explicitProject />);
        }}
      />
    </CSSTransition>
  );
}


export default ReviewPriority;
