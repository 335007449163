/**
*
* ProjectWidgetPlaceholder
*
*/

import React from 'react';

import './ProjectWidgetPlaceholder.css';

const defaultMessage = 'Nothing to display.';

function ProjectWidgetPlaceholder({ primary, secondary}: any) {
  return (
    <div className="projectWidgetPlaceholder">
      <p>{ primary || defaultMessage }</p>
      { secondary && (
        <p>{ secondary }</p>
      )}
    </div>
  );
}

export default ProjectWidgetPlaceholder;
