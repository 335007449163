/**
*
* ProjectDeleteForm
*
*/

import React from 'react';

import './ProjectDeleteForm.css';

import IconButton from 'components/TaskButtons/IconButton/IconButton';
import FullscreenModal from 'components/FullscreenModal/FullscreenModal';

function ProjectDeleteForm(props) {
  return (
    <FullscreenModal
      close={props.close}
      className="project-delete-form"
    >
      <h4>Delete project</h4>
      <br />
      <p>This action is permanent and cannot be undone.</p>
      <br />
      <IconButton
        onClick={props.submit}
        icon={<i className="fa fa-check" />}
        name="Confirm"
      />
      <IconButton
        onClick={props.close}
        icon={<i className="fa fa-times" />}
        name="Cancel"
      />
    </FullscreenModal>
  );
}

export default ProjectDeleteForm;
