/**
*
* AddGroupCard
*
*/

import React, { PureComponent } from 'react';
// import './AddGroupCard.css';

import { withFormik } from 'formik';
import FauxPopover from 'components/FauxPopover/FauxPopover';
import FormikInput from 'components/FormikInput/FormikInput';

import { colourPickerArray } from 'utils/core';

class AddGroupCard extends PureComponent<any, any> {
  render() {
    const {
      close,
      values,
      handleSubmit,
      handleChange,
      outsideClick,
      noAnimation,
      targetName = 'Task',
    } = this.props;
    return (
      <FauxPopover
        outsideClick={outsideClick || close}
        noAnimation={noAnimation}
        className="add-task compact-form"
      >
        <form onSubmit={handleSubmit}>
          <div className="form-body">
            <FormikInput
              focusOnMount
              name="name"
              placeholder={`${targetName} Name...`}
              formikBag={this.props}
            />
            <div className="basic-row">
              <div>Color:</div>
              <select name="color" value={values.color} onChange={handleChange}>
                { colourPickerArray.map(item => (
                  <option key={item} value={item}>{ item }</option>
                ))}
              </select>
            </div>
          </div>
          <div className="action-row">
            <button type="submit">
              <i className="fa fa-check" />
              {' Save'}
            </button>
            <button type="button" onClick={close}>
              <i className="fa fa-times" />
              {' Close'}
            </button>
          </div>
        </form>
      </FauxPopover>
    );
  }
}


const ValidatedGroupCard = withFormik({
  mapPropsToValues: ({ defaultColor, defaultText }: any) => ({
    color: defaultColor || 'blue',
    name: defaultText || '',
  }),

  // Custom sync validation
  validate: (values: any) => {
    const errors: any = {};
    if (!values.name || values.name === '') {
      errors.name = 'Required';
    }
    return errors;
  },

  handleSubmit: (values, { props, resetForm }: any) => {
    const { name, color } = values;
    const { submit, columnId, close } = props;

    if (!submit) {
      return;
    }

    submit(name, color, columnId, close);
    resetForm();
  },

  displayName: 'Add Group Form', // helps with React DevTools
})(AddGroupCard);

// AddGroupCard.propTypes = {
//   close: PropTypes.func.isRequired,
// };

export default ValidatedGroupCard;
