
import { formatDuration, formatDate } from 'utils/date';
import { reduceSum } from 'utils/manipulation';
import { dbUpdateTask, dbMultiUpdateTask, dbInsertTask, dbDeleteTask } from 'utils/api';
import modelCache from './modelCache';
import { isDateValid } from '../date';
import { PRIORITY_NONE, PRIORITY_VALUE } from '../constants';
import { parseId } from 'utils/utils';
import DataController from 'containers/DataController/DataController';
import { IProjectColumn, IProject } from './projectModel';

type dateKeys = 'deadline' | 'completedDate' | 'lastActive';
type priorityKeys = 'None' | 'Low' | 'Medium' | 'High';

export interface ITaskChecklist {
  id: number;
  title: string;
  complete: boolean;
}

interface ITaskStats {
  hourlyValue: number;
  timespent: number;
}

interface ITaskProperties {
  title: string;
  description: string;
  fixedValue: number;
  priority: priorityKeys;
  completed: boolean;
  active: boolean;
  column: string;
  pos: number;
  completedDate?: string;
  lastActive?: string;
  deadline?: string;
  starttime?: string;
  createdOn?: string;
}

interface ITaskSession {
  id: number;
  start: string;
  end: string;
  duration: number;
  rate: number;
  value: number;
}

export interface ITaskObject {
  id: number | string;
  project_id: number;
  properties: ITaskProperties;
  stats: ITaskStats;
  sessions: ITaskSession[];
  archived: boolean;
  checklist: ITaskChecklist[];
}

type ITaskPropKeys = keyof ITaskProperties; 

const getNextSessionId = (sessions: ITaskSession[]): number => {
  if (sessions.length === 0) {
    return 1;
  }

  return Math.max(...sessions.map(s => s.id)) + 1;
}

// A previous bug existed that would give duplicate ids to sessions.
// We use this function to self correct any bad data
const fixSessionPrimaryKey = (sessions: ITaskSession[]): ITaskSession[] => {
  const keyMap = new Map();
  const sessionsCopy = [...sessions];
  sessionsCopy.forEach((session, i) => {
    if (keyMap.has(session.id)) {
      const nextSafeId = getNextSessionId(sessionsCopy);
      sessionsCopy[i] = {
        ...session,
        id: nextSafeId,
      };
    }
    keyMap.set(session.id, true);
  });

  return sessionsCopy;
};

export interface ITask extends ITaskObject {
  getParent: () => IProject;
  calculateSessionValue: (msTime: number, rate: number) => number;
  getValue: () => number;
  getPriorityValue: () => number;
  isOverdue: () => boolean;
  markActive: () => void;
  changeState: (completed: boolean) => void;
  playPause: (active: boolean, dontSave: boolean) => ITask;
  addChecklistItem: (title: string) => void;
  removeChecklistItem: (id: number) => void;
  updateChecklistItem: (item: ITaskChecklist) => void;
  updateChecklistOrder: (next: ITaskChecklist[]) => void;
  upsertSession: (session: ITaskSession, start: Date, end: Date, duration: number, rate: number) => void;
  deleteSession: (id: number) => void;
  dateUpdate: (date: Date, prop: dateKeys) => void;
  simpleUpdate<T extends ITaskPropKeys>(value: ITaskProperties[T], prop: T): void;
  getColumn: () => IProjectColumn | undefined;
  move: (projectId: number, column: string) => void;
  reorder: (taskAfter: ITask, column: string) => void;
  deadline: () => string;
  timeDisplay: () => string;
  delete: (callback?: any) => void;
}

export interface ITaskArray extends Array<ITask> {
  get: (id: number | string | undefined) => ITask;
  multiUpdate: (tasks: ITask[], uncapturedProjects: number[], callback?) => void;
  update: (task: ITask, uncapturedProjects: number[], callback?) => void;
  insert: (taskNoId: ITaskObject, callback?) => void;
  delete: (project, callback?) => void;
  getProjectTasks: (id: number) => ITaskArray;
  getFilteredProjectTasks: (id: number, filters) => ITaskArray;
  mutate: (tasks: ITask[], effectedProjects?: number[]) => ITaskArray;
  projection?: boolean;
}

const msHour = 1000 * 60 * 60;

function calculateHourlyValue(task: ITask, sessions: ITaskSession[]) {
  const project = task.getParent();

  if (!project.hasHourlyTracking()) {
    return 0;
  }

  return sessions.reduce((acc, { value }) => reduceSum(acc, value), 0);
}

function updateTaskSessions(task: ITask, sessions: ITaskSession[]) {
  const timespent = sessions.reduce((acc, { duration }) => reduceSum(acc, duration), 0);
  const hourlyValue = calculateHourlyValue(task, sessions);

  return { timespent, hourlyValue };
}

export function taskArrayModel(taskArray: ITask[] = [], context: DataController, cache = modelCache(), projection = false): ITaskArray {
  if (!context) {
    throw new Error('Task Array Model Context Undefined');
  }

  if (!projection) console.debug('New Task Array');

  const localArray: ITaskArray = [...taskArray] as ITaskArray

  // Sort Tasks
  if (localArray.sort) {
    localArray.sort((taska, taskb) => {
      const { completed: ca, pos: pa } = taska.properties;
      const { completed: cb, pos: pb } = taskb.properties;
      const compa = ca ? 1 : 0;
      const compb = cb ? 1 : 0;

      if (compa === compb) {
        if (pa === pb) return 0;

        return pa > pb ? 1 : -1;
      }

      return compa > compb ? 1 : -1;
    });

    // Remove sort method to ensure order cannot be mutated
    localArray.sort = undefined;
  }

  // Local Methods
  // These methods refer to the localArray

  localArray.get = (unsafeId) => {
    const id = parseId(unsafeId)
    return localArray.find(a => a.id === id);
  }

  // Save Methods
  // These methods pass to functions which update the global state
  // The methods are kept seperate to avoid accidently using the localArray
  // this allows them to be called safely from projections

  localArray.multiUpdate = (tasks, uncapturedProjects, callback) => TaskArrayMultiUpdate(tasks, uncapturedProjects, context, callback);
  localArray.update = (task, uncapturedProjects, callback) => TaskArrayUpdate(task, uncapturedProjects, context, callback);
  localArray.insert = (taskNoId, callback) => TaskArrayInsert(taskNoId, context, callback);
  localArray.delete = (project, callback) => TaskArrayDelete(project, context, callback);

  // The following methods mutate the cache and can not be called from projections.

  if (!projection) {
    localArray.getProjectTasks = (id) => {
      if (cache[id]) {
        return cache[id];
      }

      console.debug(`Cache Project Tasks: ${id}`);

      // Always refer to localArray (not context.state.tasks) as it is
      // possible caching is performed before global tasks have been updated
      const projectTasks = taskArrayModel(localArray.filter(a => a.project_id === id), context, cache, true);

      // Here we cache an array projection. If any item in that array changes we must clear the cache.
      cache[id] = projectTasks;
      return projectTasks;
    };

    localArray.getFilteredProjectTasks = (id, filters = []) => {
      const projectTasks: ITaskArray = localArray.getProjectTasks(id);
      let filteredProjectTasks: ITask[] = projectTasks;

      // If there are no filters, we can avoid creating a new taskArrayModel
      if (filters.length === 0) {
        return projectTasks;
      }

      filters.forEach(({ filter }) => {
        filteredProjectTasks = filteredProjectTasks.filter(filter);
      });
      return taskArrayModel(filteredProjectTasks, context, cache, true);
    };

    // This function ensures array projections are always relevant
    localArray.mutate = (arr, effectedProjects) => {
      const nextCache = cache.mutate(effectedProjects);
      return taskArrayModel(arr, context, nextCache);
    };
  } else {
    localArray.projection = true;
  }

  // Remove mutating methods - these introduce hard to catch bugs.
  // Much easier to cause the app top fail fast by removing them.
  localArray.sort = undefined;
  localArray.splice = undefined;

  return localArray;
}

export function taskModel(taskObject: ITaskObject, context: DataController): ITask {
  const task: ITask = taskObject as ITask;
  const { stats, properties } = task;

  if (!context) {
    throw new Error('Task Model missing context');
  }

  task.changeState = (completed = true) => {
    let safeTask = task;

    if (completed) {
      // Ensure task is not active
      safeTask = task.playPause(false, true);
    }

    genericUpdate({
      ...safeTask,
      properties: {
        ...safeTask.properties,
        priority: PRIORITY_NONE,
        completed,
        completedDate: completed ? new Date().toISOString() : undefined,
        lastActive: new Date().toISOString(),
      },
    });
  };

  task.getParent = () => context.state.projects.get(task.project_id);

  task.calculateSessionValue = (msTime, rate) => {
    let msRate = rate || 0;

    if (rate) {
      msRate = rate / msHour;
    }

    return parseFloat((msTime * msRate).toFixed(2));
  };

  task.getValue = () => {
    const parent = task.getParent();

    if (parent.hasAddedTracking()) {
      return properties.fixedValue;
    }

    if (parent.hasHourlyTracking()) {
      return stats.hourlyValue;
    }

    return 0;
  };

  task.getPriorityValue = () => {
    const { priority } = task.properties;
    if (!priority || task.properties.completed) {
      return 0;
    }

    return PRIORITY_VALUE[priority];
  };

  task.isOverdue = () => {
    const { deadline, completed } = task.properties;

    if (!deadline || completed) return false;

    const date = new Date(deadline);

    return date.getTime() < Date.now();
  }

  task.upsertSession = (session, start, end, duration, rate) => {
    const { sessions } = task;
    const id = session ? session.id : getNextSessionId(task.sessions);
    let nextSessions: ITaskSession[];

    if (!Number.isFinite(duration)) {
      duration = 0
    }
    
    if (!Number.isFinite(rate)) {
      rate = 0
     }


    const sessionToUpsert: ITaskSession = {
      id,
      duration,
      rate,
      value: task.calculateSessionValue(duration, rate),
      start: start.toISOString(),
      end: end.toISOString(),
    };

    if (session) {
      nextSessions = sessions.map((a) => {
        if (a.id !== id) return a;
        return sessionToUpsert;
      });
    } else {
      nextSessions = [...sessions, sessionToUpsert];
    }

    const nextStats = updateTaskSessions(task, nextSessions);

    const next = {
      ...task,
      stats: nextStats,
      sessions: fixSessionPrimaryKey(nextSessions),
    };

    genericUpdate(next);
  };

  task.deleteSession = (id) => {
    const { sessions } = task;
    const nextSessions = sessions.filter(session => session.id !== id);

    const next = {
      ...task,
      stats: updateTaskSessions(task, nextSessions),
      sessions: fixSessionPrimaryKey(nextSessions),
    };

    genericUpdate(next);
  };

  task.playPause = (active = true, dontSave) => {
    const { sessions } = task;
    const { starttime } = properties;
    const parent = task.getParent();
    let nextSessions = sessions;
    let nextStats = task.stats;

    if (!active) {
      if (!starttime) {
        console.debug('Tried to stop and idle task');
        return task;
      }

      const endDate = new Date();
      const duration = new Date(endDate.getTime() - new Date(starttime).getTime()).getTime();
      const rate: number = parent.getDefaultRate();

      nextSessions = [...sessions, {
        id: getNextSessionId(sessions),
        duration,
        rate,
        value: task.calculateSessionValue(duration, rate),
        start: starttime,
        end: endDate.toISOString(),
      }];

      nextStats = updateTaskSessions(task, nextSessions);
    }

    const next = {
      ...task,
      stats: nextStats,
      properties: {
        ...properties,
        active,
        starttime: active ? new Date().toISOString() : undefined,
        lastActive: new Date().toISOString(),
      },
      sessions: fixSessionPrimaryKey(nextSessions),
    };

    if (dontSave) {
      return next;
    }

    genericUpdate(next);
  };

  task.markActive = () => {
    genericUpdate({
      ...task,
      properties: {
        ...task.properties,
        lastActive: new Date().toISOString(),
      },
    });
  };

  task.addChecklistItem = (title) => {
    genericUpdate({
      ...task,
      checklist: [
        ...task.checklist,
        {
          id: Date.now(),
          title,
          complete: false,
        },
      ],
    });
  };

  task.removeChecklistItem = (id) => {
    genericUpdate({
      ...task,
      checklist: task.checklist.filter(a => a.id !== id),
    });
  };

  task.updateChecklistItem = (newItem) => {
    const id = newItem.id;
    genericUpdate({
      ...task,
      properties: {
        ...task.properties,
        lastActive: new Date().toISOString(),
      },
      checklist: task.checklist.map(a => (a.id === id ? newItem : a)),
    });
  };

  task.updateChecklistOrder = (next) => {
    genericUpdate({
      ...task,
      checklist: next,
    });
  };

  task.dateUpdate = (date, prop) => {
    if (!isDateValid(date)) {
      console.debug('Invalid Date');
      return;
    }

    genericUpdate({
      ...task,
      properties: {
        ...task.properties,
        [prop]: date.toISOString(),
      },
    });
  };

  task.simpleUpdate = (value, prop) => {
    genericUpdate({
      ...task,
      properties: {
        ...task.properties,
        [prop]: value,
      },
    });
  };

  task.getColumn = () => {
    const project = task.getParent();
    const col = project && project.columns.find(col => col.id === task.properties.column);
    return col;
  }

  task.move = (projectId, column) => {
    const oldGroupId = task.project_id;
    const hasCol = !!task.getColumn();

    if (!hasCol) return;

    genericUpdate({
      ...task,
      project_id: projectId,
      properties: {
        ...task.properties,
        column,
      },
    }, [oldGroupId]);
  };

  task.reorder = (taskAfter, column) => {
    const project = task.getParent();
    let children = project.getChildren().map(a => a);

    if (!column) {
      return;
    }

    // First remove the item from the list
    const from = children.findIndex(child => child.id === task.id);
    let item = children.splice(from, 1)[0];
    item = {
      ...item,
      properties: {
        ...item.properties,
        column,
      },
    };

    // Calculate the index of where to insert now the old item is gone
    if (taskAfter) {
      const to = children.findIndex(child => child.id === taskAfter.id);
      children.splice(to, 0, item);
    } else {
      children.push(item);
    }

    children = children.map((child, index) => ({
      ...child,
      properties: {
        ...child.properties,
        pos: index,
      },
    }));
    genericMultiUpdate(children);
  };

  task.deadline = () => (properties.deadline ? formatDate(properties.deadline) : '');

  task.timeDisplay = () => formatDuration(stats.timespent);

  task.delete = (callback) => {
    context.state.tasks.delete(task, callback);
  };

  function genericUpdate(rawTask: ITaskObject, uncapturedProjects = []) {
    // Temporary tasks are given a string ID of temp_date.now()
    // Ensure they are not saved to the DB
    const id: number | string = (rawTask as any).id;
    if (typeof id === 'string' && id.includes('temp')) {
      return;
    }

    const state = context.state;
    const nextTask = taskModel(rawTask, context);

    state.tasks.update(nextTask, uncapturedProjects);
  }

  function genericMultiUpdate(rawTasks: ITaskObject[], uncapturedProjects = []) {
    const state = context.state;
    const nextTasks = rawTasks.map(rawTask => taskModel(rawTask, context));

    state.tasks.multiUpdate(nextTasks, uncapturedProjects);
  }

  return task;
}

export function newTask(projectId, properties: Partial<ITaskProperties>, checklist: ITaskChecklist[] = []): ITaskObject {
  return {
    // We give the task a temp id here, then override it later. Need to be consistent.
    id: -1 * Date.now(),
    project_id: projectId,
    properties: {
      title: properties.title,
      description: properties.description || '',
      completed: false,
      priority: properties.priority || 'None',
      pos: properties.pos,
      fixedValue: properties.fixedValue || 0,
      active: false,
      column: properties.column,
      createdOn: new Date().toISOString(),
    },
    stats: {
      timespent: 0,
      hourlyValue: 0,
    },
    checklist,
    sessions: [],
    archived: false,
  };
}

function TaskArrayMultiUpdate(updatedTasks: ITask[], uncapturedProjects: number[], context: DataController, callback) {
  const { tasks, projects } = context.state;
  const effectedProjects = [...uncapturedProjects];
  let nextTasks = tasks as ITask[];

  updatedTasks.forEach((task) => {
    const id = task.id;
    if (!effectedProjects.includes(task.project_id)) {
      effectedProjects.push(task.project_id);
    }
    nextTasks = nextTasks.map(a => (a.id === id ? task : a));
  });

  const nextTaskArray = tasks.mutate(nextTasks, effectedProjects);

  const { nextProjects, saveFunctions } = projects.updateStats(nextTaskArray, effectedProjects);

  console.debug('setState: Projects & Tasks');

  context.setState({
    projects: nextProjects,
    tasks: nextTaskArray,
  }, () => {
    const promiseArray = Promise.all([dbMultiUpdateTask(updatedTasks), ...saveFunctions()]);
    const onError = (err) => {
      console.debug('Error Updating Task.', err);
      context.setState({ tasks, projects });
    };

    callback && callback();
    promiseArray.then(null, onError);
  });
}

function TaskArrayUpdate(task: ITask, uncapturedProjects: number[], context: DataController, callback?) {
  const id = task.id;
  const effectedProjects = [task.project_id, ...uncapturedProjects];
  const { tasks, projects } = context.state;

  const nextTasks = tasks.mutate(tasks.map(a => (a.id === id ? task : a)), effectedProjects);
  const { nextProjects, saveFunctions } = projects.updateStats(nextTasks, effectedProjects);

  console.debug('setState: Projects & Tasks');

  context.setState({
    projects: nextProjects,
    tasks: nextTasks,
  }, () => {
    const promiseArray = Promise.all([dbUpdateTask(task), ...saveFunctions()]);
    const onError = (err) => {
      console.debug('Error Updating Task.', err);
      context.setState({ tasks, projects });
    };

    callback && callback();
    promiseArray.then(null, onError);
  });
}

function TaskArrayInsert(taskNoId: ITaskObject, context: DataController, callback?) {
  // todo - replace temp with negative int
  const tempId = `temp_${Date.now().toString()}`;
  const tempTask = taskModel({
    ...taskNoId,
    id: tempId,
  }, context);
  const effectedProjects = [tempTask.project_id];
  const { projects, tasks } = context.state;

  const nextTasks = tasks.mutate([...tasks, tempTask], effectedProjects);
  const { nextProjects, saveFunctions } = projects.updateStats(nextTasks, effectedProjects);

  console.debug('setState: Projects & Tasks');

  context.setState({
    tasks: nextTasks,
    projects: nextProjects,
  }, () => {
    callback && callback();
    const promiseArray = Promise.all([dbInsertTask({ ...taskNoId, id: undefined }), ...saveFunctions()]);

    promiseArray.then((success) => {
      const { data } = success[0];
      const { tasks: currentTasks } = context.state;
      const task = taskModel(data, context);

      // Replace temptask with the real thing
      const revisedTasks = currentTasks.mutate(
        currentTasks.map(a => (a.id === tempId ? task : a)),
        effectedProjects,
      );

      console.debug('setState: Tasks');

      context.setState({ tasks: revisedTasks });
    }, (err) => {
      console.debug('Error Inserting Task.', err);
      context.setState({ tasks, projects });
    });
  });
}

function TaskArrayDelete({ id, project_id: projectId }: ITask, context: DataController, callback?) {
  const effectedProjects = [projectId];
  const { projects, tasks } = context.state;

  const nextTasks = tasks.mutate(tasks.filter(a => a.id !== id), effectedProjects);
  const { nextProjects, saveFunctions } = projects.updateStats(nextTasks, effectedProjects);

  console.debug('setState: Projects & Tasks');

  context.setState({
    projects: nextProjects,
    tasks: nextTasks,
  }, () => {
    const promiseArray = Promise.all([...saveFunctions(), dbDeleteTask(id)]);
    const onError = (err) => {
      console.debug('Error Deleting Task.', err);
      context.setState({ projects });
    };

    callback && callback();
    promiseArray.then(null, onError);
  });
}
