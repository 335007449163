/**
*
* EditGroup
*
*/

import React, { PureComponent } from 'react';

// import './EditGroup.css';

import AddGroupCard from 'containers/Overview/AddGroupCard/AddGroupCard';
import IconButton from 'components/TaskButtons/IconButton/IconButton';
import FauxPopover from 'components/FauxPopover/FauxPopover';

import DeleteColumn from '../DeleteColumn/DeleteColumn';
import { IGroup } from 'utils/models/groupModel';

interface IEditGroup {
  group: IGroup;
  close: () => void;
}

class EditGroup extends PureComponent<IEditGroup, any> {
  constructor(props) {
    super(props);

    this.state = {
      tab: undefined,
      noAnimation: false,
    };
  }

  setTab(e, tab) {
    this.setState({ tab });
  }

  returnToMenu() {
    this.setState({
      tab: undefined,
      noAnimation: true,
    });
  }

  render() {
    const { group, close } = this.props;
    const { tab } = this.state;

    if (tab === 'edit') {
      return (
        <AddGroupCard
          noAnimation
          defaultColor={group.color}
          defaultText={group.title}
          outsideClick={close}
          submit={(title, color) => {
            group.update({ title, color });
            close();
          }}
          close={() => this.returnToMenu()}
        />
      );
    }

    if (tab === 'delete') {
      return (
        <DeleteColumn
          parent="association"
          child="projects"
          back={() => this.returnToMenu()}
          close={close}
          isEmpty={group.isEmpty()}
          submit={() => group.delete()}
        />
      );
    }

    return (
      <FauxPopover noAnimation={this.state.noAnimation} outsideClick={close}>
        <IconButton
          onClick={e => this.setTab(e, 'edit')}
          icon={<i className="fa fa-pencil" />}
          name="Edit Association"
        />
        <IconButton
          onClick={e => this.setTab(e, 'delete')}
          icon={<i className="fa fa-trash" />}
          name="Delete Association"
        />
      </FauxPopover>
    );
  }
}

export default EditGroup;
