import { getPreference } from "./preferences";

const decNumberFormatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const intNumberFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });

export const NO_TRACKING = 'none';
export const INCREMENTAL_TRACKING = 'added';
export const HOURLY_TRACKING = 'perhour';

export const TIMING_OFF = 'off';
export const TIMING_ON = 'on';

export const COST_TRACKING_OPTIONS = [
  { id: NO_TRACKING, title: 'Off' },
  { id: INCREMENTAL_TRACKING, title: 'Incremental' },
  { id: HOURLY_TRACKING, title: 'Hourly' },
];

export const TIME_TRACKING_OPTIONS = [
  { id: TIMING_OFF, title: 'Off' },
  { id: TIMING_ON, title: 'On' },
];

export const PRIORITY_IGNORE = 'Ignore';
export const PRIORITY_NONE = 'None';
export const PRIORITY_LOW = 'Low';
export const PRIORITY_MEDIUM = 'Medium';
export const PRIORITY_HIGH = 'High';

export const COST_UPGRADE_MSG = 'Cost Tracking is a Premium feature';

export const PRIORITY_VALUE = {
  [PRIORITY_IGNORE]: -1,
  [PRIORITY_NONE]: 0,
  [PRIORITY_LOW]: 1,
  [PRIORITY_MEDIUM]: 2,
  [PRIORITY_HIGH]: 3,
};

export const formatCurrency = (val, preferInt = false) => {
  const CURRENCY_PREFIX = getPreference('currency', '$');

  if (val === null) {
    return `${CURRENCY_PREFIX}`;
  }

  if (typeof val !== 'number') {
    console.debug('Value unexpectedly wrong type');
    return 'Error';
  }

  let localVal = val.toString();

  try {
    localVal = Number.isInteger(val) ? intNumberFormatter.format(val) : decNumberFormatter.format(val);
  } catch (e) {
    console.debug('toLocaleString not supported');
  }

  return `${CURRENCY_PREFIX}${localVal}`;
};
