/**
*
* ReportProjectRow
*
*/

import React, { useState } from 'react';


// import './ReportProjectRow.css';

function ReportProjectRow({ project, formatter }) {
  const [open, setOpen] = useState(false)
  const { projectId, projectTitle, value, tasks } = project
  return (
    <div key={projectId} className="projectGroup">
      <div onClick={() => setOpen(!open)} key={projectId} className="row">
        {open ? (
          <i className="fa fa-chevron-down"></i>
        ) : (
          <i className="fa fa-chevron-right"></i>
        )}
        <div className="title">{projectTitle}</div>
        <div>{formatter(value)}</div>
      </div>
      { open && (
        <div key={projectId} className="taskGroup">
          {(tasks.map(({ id, itemId, title, value }) => 
            <div key={id} className="row">
              <div className="title">{title}</div>
              <div>{formatter(value)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ReportProjectRow;
