
import React, { useContext } from 'react';
import { ITaskArray } from './models/taskModel';
import { IProjectArray } from './models/projectModel';
import { IGroupArray } from './models/groupModel';

export interface IDataControllerState {
    token: any;
    loading: boolean,
    loaded: boolean,
    tasks: ITaskArray,
    projects: IProjectArray,
    groups: IGroupArray,
    hasData: () => boolean,
    reload: () => void,
}

export const DataContext = React.createContext(({} as IDataControllerState));
export const useDataContext = () => useContext(DataContext);

export const ScreenContext = React.createContext(({} as any));
