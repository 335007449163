/**
*
* NumberSelect
*
*/

import React, { PureComponent } from 'react';

import './NumberSelect.css';

import FauxPopover from 'components/FauxPopover/FauxPopover';

class NumberSelect extends PureComponent<any, any> {
  select;

  constructor(props) {
    super(props);

    this.state = {
      value: props.selected || 1,
    };
  }

  render() {
    const props = this.props;

    const rows = [];

    for (let i = 1; i <= props.range; i++) {
      rows.push(<option key={i} value={i}>{i}</option>);
    }

    return (
      <FauxPopover
        outsideClick={props.outsideClick || props.close}
        noAnimation={props.noAnimation}
        className="compact-form number-select"
      >
        <form onSubmit={e => this.submit(e)}>
          <div className="form-body">
            <div className="basic-row">
              { props.targetName }
:
              <select
                ref={select => this.select = select}
                value={this.state.value}
                onChange={e => this.handleChange(e)}
              >
                { rows }
              </select>
            </div>
          </div>
          <div className="action-row">
            <button type="submit">
              <i className="fa fa-check" />
              {' Save'}
            </button>
            <button type="button" onClick={props.close}>
              <i className="fa fa-times" />
              {' Close'}
            </button>
          </div>
        </form>
      </FauxPopover>
    );
  }

  componentDidMount() {
    this.select.focus();
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  submit(e) {
    const value = this.state.value;
    const props = this.props;
    const { submit, columnId } = props;
    e.preventDefault();

    if (!submit) {
      return false;
    }

    this.setState({ value: props.selected || 1 });
    submit(value, columnId, props.close);
  }
}

export default NumberSelect;
