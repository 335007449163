/**
*
* EditPanelOption
*
*/

import React, { PureComponent } from 'react';
// import './EditPanelOption.css';

class EditPanelOption extends PureComponent<any, any> {
  select;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  save(e) {
    const { value } = this.state;
    const { save } = this.props;

    e.preventDefault();
    save(value);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  componentDidMount() {
    this.select.focus();
  }

  render() {
    const { forwardRef, close, options} = this.props;
    const state = this.state;

    return (
      <form
        ref={forwardRef}
        className="panel-edit-form inline"
        onSubmit={e => this.save(e)}
      >
        <select
          ref={ref => (this.select = ref)}
          value={state.value}
          onChange={e => this.handleChange(e)}
        >
          { options.map(option => (
            <option
              key={option.id}
              value={option.id}
              label={option.title}
            />
          )) }
        </select>
        <div className="form-buttons inline">
          <button
            type="submit"
            className="primary"
          >
            <i className="fa fa-check" />
          </button>
          <button
            onClick={close}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </form>
    );
  }
}

// EditPanelOption.defaultProps = {
//   save: () => {},
//   value: '',
// };

// EditPanelOption.propTypes = {
//   save: PropTypes.func,
//   value: PropTypes.string,
// };

export default EditPanelOption;
