/**
*
* ReportSummary
*
*/

import React from 'react';
import { reduceSum } from '../../../utils/manipulation';

import './ReportSummary.css';
import ReportProjectRow from '../../../components/ReportProjectRow/ReportProjectRow';
import ReportPieChart from '../../../components/ReportPieChart/ReportPieChart';

function ReportSummary({ data, formatter, axisFormatter }) {
  const dataLengh = data.length;
  return (
    <div className="reportSummary">
      <div className="summaryGrid">
        <div className="row header-row">
          <div className="title">Total</div>
          <div>{formatter(data.reduce((acc, { value }) => reduceSum(acc, value), 0))}</div>
        </div>
        {data.slice(0, 50).map((project) => (<ReportProjectRow key={project.projectId} project={project} formatter={formatter} />))}
        {dataLengh > 50 && (
          <div className="row">
            <div>(Plus {dataLengh - 50} More)</div>
          </div>
        )}
      </div>
      <div className="summaryChart wideScreenOnly">
        <ReportPieChart data={data} formatter={formatter} axisFormatter={axisFormatter} />
      </div>
    </div>
  );
}


export default ReportSummary;
