/**
*
* SessionItem
*
*/

import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';

// import './SessionItem.css';

import { formatDate, formatTime, formatDuration } from 'utils/date';

import { CSSTransition } from 'react-transition-group';
import { Manager, Reference } from 'react-popper';

import SessionItemEdit from '../SessionItemEdit/SessionItemEdit';
import { formatCurrency } from '../../../../utils/constants';

class SessionItem extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = { editMode: false };
  }

  toggleEdit() {
    this.setState({ editMode: !this.state.editMode });
  }

  render() {
    const { session, task } = this.props;
    const editMode = this.state.editMode;
    const parent = task.getParent();

    const dateDisplay = formatDate(session.start);
    const timeDisplay = formatTime(session.start);
    const endTimeDisplay = formatTime(new Date(session.start).getTime() + session.duration);
    const durationDisplay = formatDuration(session.duration);

    const classList = ['em-sessionlog-session'];

    if (editMode) {
      classList.push('open');
    }

    return (
      <CSSTransition in appear timeout={300} classNames="fade">
        <Manager>
          <Reference>
            { renderProps => (
              <div 
                ref={renderProps.ref}
                className={classList.join(' ')}
                onClick={() => this.toggleEdit()}
              >
                <div className="em-sessionlog-date">
                  { dateDisplay }
                </div>
                <div className="em-sessionlog-date">
                  { timeDisplay }
                  {' - '}
                  { endTimeDisplay }
                </div>
                <div className="em-sessionlog-date">
                  { `${durationDisplay} hrs` }
                </div>
                { parent.hasHourlyTracking() && (
                  <Fragment>
                    <div className="em-sessionlog-date">
                      { formatCurrency(session.value) }
                    </div>
                    <div className="em-sessionlog-date">
                      { `${formatCurrency(session.rate)}/hr` }
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </Reference>
          { editMode && ReactDOM.createPortal(
            <SessionItemEdit session={session} task={task} close={() => this.toggleEdit()} />,
            document.getElementById('root'),
          )}
        </Manager>
      </CSSTransition>
    );
  }
}


export default SessionItem;
