import { dbInsertGroup, dbDeleteGroup, dbUpdateGroup } from '../api';
import DataController from 'containers/DataController/DataController';

interface IGroupObj {
  id: number | null;
  title: string;
  color: string;
}

export interface IGroup extends IGroupObj {
  isEmpty: () => boolean;
  update: (props: Partial<IGroupObj>) => void;
  delete: () => void;
}

export interface IGroupArray extends Array<IGroup> {
  get: (id: number) => IGroup;
  mutate: (nextGroups: IGroup[]) => IGroupArray;
  update: (group: IGroup, callback?) => void;
  insert: (groupNoId: IGroupObj, callback?) => void;
  delete: (id: number, callback?) => void;
}

export const ungrouped = {
  id: null,
  title: 'My Projects',
  color: 'blue',
};

export function groupArrayModel(groupArray: IGroup[] = [], context) {
  const localArray = [...groupArray] as IGroupArray;

  localArray.sort((a, b) => {
    const { title: ATitle = '', id: AId } = a;
    const { title: BTitle = '', id: BId } = b;

    // My Projects will have a blank id - sort to front
    if (!AId || !BId) {
      return !AId ? -1 : 1;
    }
    
    const lcATitle = ATitle.toLowerCase();
    const lcBTitle = BTitle.toLowerCase();

    // If projects have matching titles, sort by Id
    if (lcATitle === lcBTitle) {
      return AId < BId ? -1 : 1;
    }

    // Sort by title
    return lcATitle < lcBTitle ? -1 : 1;
  })

  localArray.get = (id) => {
    const out = localArray.find(a => a.id === id);

    if (!out) {
      return groupModel(ungrouped, context);
    }

    return out;
  };

  localArray.mutate = arr => groupArrayModel(arr, context);

  localArray.update = (group, callback) => GroupArrayUpdate(group, context, callback);
  localArray.insert = (groupNoId, callback) => GroupArrayInsert(groupNoId, context, callback);
  localArray.delete = (id, callback) => GroupModelDelete(id, context, callback);

  return localArray;
}

export function groupModel(groupObj: IGroupObj, context) {
  const group = groupObj as IGroup;

  group.isEmpty = () => {
    const projects = context.state.projects;

    if (projects.find(a => a.association_id === group.id)) {
      return false;
    }

    return true;
  };

  group.update = (updates) => {
    const next = groupModel({
      ...group,
      ...updates,
    }, context);

    context.state.groups.update(next);
  };

  group.delete = () => {
    const id = group.id;

    if (group.isEmpty()) {
      context.state.groups.delete(id);
    }
  };

  return group;
}

export const newGroup = (title, color) => ({
  title,
  color,
  properties: {},
});

function GroupArrayUpdate(group: IGroup, context: DataController, callback?) {
  const { id } = group;
  const { groups } = context.state;
  const next = groups.map(existing => (id === existing.id ? group : existing));

  if (!id) {
    return;
  }

  context.setState({
    groups: groups.mutate(next),
  }, () => {
    const onError = (err) => {
      console.debug('Error Updating Project.', err);
      context.setState({ groups });
    };

    callback && callback();
    dbUpdateGroup(group).then(null, onError);
  });
}

function GroupArrayInsert(groupNoId: IGroupObj, context: DataController, callback?) {
  const tempId: number = (`temp_${Date.now().toString()}` as any);
  const tempGroup = groupModel({
    ...groupNoId,
    id: tempId,
  }, context);
  const { groups } = context.state;

  const nextGroups = groups.mutate([...groups, tempGroup]);

  console.debug('setState: Group');

  context.setState({
    groups: nextGroups,
  }, () => {
    callback && callback();

    dbInsertGroup(groupNoId).then((success) => {
      const { data } = success;
      const { groups: currentGroups } = context.state;
      const group = groupModel(data, context);

      // Replace temptask with the real thing
      const revisedGroups = currentGroups.mutate(currentGroups.map(a => (a.id === tempId ? group : a)));

      console.debug('setState: Groups');

      context.setState({ groups: revisedGroups });
    }, (err) => {
      console.debug('Error Inserting Group.', err);
      context.setState({ groups });
    });
  });
}

function GroupModelDelete(id: number, context: DataController, callback) {
  if (!id) {
    return;
  }

  const { groups } = context.state;
  const nextGroups = groups.filter(a => a.id !== id);

  context.setState({
    groups: groups.mutate(nextGroups),
  }, () => {
    const onError = (err) => {
      console.debug('Error Deleting Project.', err);
      context.setState({ groups });
    };

    callback && callback();
    dbDeleteGroup(id).then(null, onError);
  });
}
