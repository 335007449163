/**
*
* ProjectWidgetProgress
*
*/

import React from 'react';

// import './ProjectWidgetProgress.css';

import { Pie } from 'react-chartjs-2';
import ProjectWidgetPlaceholder from 'components/ProjectWidgets/ProjectWidgetPlaceholder/ProjectWidgetPlaceholder';

function ProjectWidgetProgress(props) {
  const { project } = props;
  const { stats } = project;

  const total = stats.length;
  const completed = stats.completedCount;
  const remaining = total - completed;

  const data = {
    labels: [
      'Completed',
      'Remaining',
    ],
    datasets: [{
      data: [completed, remaining],
      backgroundColor: [
        '#50C273',
        '#4E82AC',
      ],
      hoverBackgroundColor: [
        '#32AE58',
        '#336A98',
      ],
      borderWidth: 1,
    }],
  };

  if (total === 0) {
    return (<ProjectWidgetPlaceholder />);
  }

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          callbacks: {
            label: ({ index }, { labels, datasets }) => {
              const label = labels[index] || '';
              const value = datasets[0].data[index];

              return ` ${value} Task${value > 1 ? 's' : ''} ${label}`;
            },
          },
        },
      }}
    />
  );
}

export default ProjectWidgetProgress;
