/**
*
* PlayPauseButton
*
*/

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import './PlayPauseButton.css';

import { Manager, Reference } from 'react-popper';
import SessionItemEdit from 'components/SidePanel/SessionLog/SessionItemEdit/SessionItemEdit';

class PlayPauseButton extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = { editMode: false };
  }

  toggleEdit() {
    this.setState({ editMode: !this.state.editMode });
  }

  coreBtn() {
    const { isActive, task } = this.props;

    return (
      <button
        type="button"
        className="action-btn playPauseBtn"
        onClick={() => task.playPause(!isActive)}
      >
        { !isActive ? <i className="fa fa-play" /> : <i className="fa fa-stop" /> }
        { !isActive ? <span className="label">Start</span> : <span className="label">Stop</span> }
      </button>
    );
  }
  
  manualBtn() {
    const { task, isComplete } = this.props;
    const { editMode } = this.state;

    return (
      <Manager>
        <Reference>
          { renderProps => (
            <button
              ref={renderProps.ref}
              type="button"
              className="action-btn manualAdd"
              onClick={() => this.toggleEdit()}
            >
              <i className="fa fa-plus" />
              { isComplete && <span className="label">Session</span> }
            </button>
          )}
        </Reference>
        { editMode && ReactDOM.createPortal(
          <SessionItemEdit task={task} close={() => this.toggleEdit()} />,
          document.getElementById('root'),
        )}
      </Manager>
    );
  }

  render() {
    const { isComplete, hasTiming, manualOption } = this.props;

    if (!hasTiming) return null;

    if (!manualOption) {
      if (!isComplete) {
        return this.coreBtn();
      }

      return null;
    }

    if (isComplete) {
      return this.manualBtn();
    }

    return (
      <div className="playPauseWrapper">
        { this.coreBtn() }
        { this.manualBtn() }
      </div>
    );
  }
}


export default PlayPauseButton;
