/**
*
* LegacyMigrate
*
*/

import React, { useState, useEffect } from 'react';
import { dbMigrate } from 'utils/api';
import { withRouter } from 'react-router';
import { appInsights } from 'utils/analytics';

const global: any = (window as any);
let WLInit = 0;

const initWL = () => {
  if(WLInit === 0) {
    try {
      global.WL.init({
        client_id: '0000000048114127',
        redirect_uri: 'https://app.eclipsemanager.com/',
        scope: 'wl.signin',
        response_type: 'token',
      });
    } catch(err) {
      console.debug(err)
    }
    WLInit = 1;
  }
}

function LegacyMigrate({ data, history }) {
  const [info, setInfo] = useState('');
  const [formState, setFormState] = useState(0);
  const [authToken, setAuthToken] = useState(null)

  useEffect(() => {
    initWL();

    async function setUser() {
      const session = global.WL.getSession();
      setAuthToken(session && session.authentication_token);
    }

    if(global.WL.getSession()) {
      setUser()
    }
    global.WL.Event.subscribe('auth.sessionChange', setUser);

    return () => {
      global.WL.Event.unsubscribe('auth.sessionChange', setUser);
    }
  }, [])

  function startMigrate() {
    try {
      if (!authToken) {
        global.WL.login()
      }
      setFormState(1);
    } catch(err) {
      console.debug(err)
    }
  }

  async function confirmMigrate() {
    if (!authToken) {
      return false
    }

    setFormState(2);
    setInfo('');

    try {
      await migrateData(authToken);
      history.push('/');
      appInsights.trackEvent({ name: 'Migrate Success'})
    } catch (err) {
      console.debug(err);
      appInsights.trackEvent({ name: 'Migrate Error'}, { error: err })
      setFormState(0);
      setInfo("Migration failed - please contact info@eclipsemanager.com");

      try {
        global.WL.logout();
      } catch (err) {
        console.debug(err);
      }
    }
  }

  async function migrateData(token) {
    return dbMigrate(token).then(() => {
      data.reload();
    });
  }

  let combinedState = formState;
  if (combinedState === 1 && !authToken) {
    combinedState = 0;
  }

  return (
    <div>
      {combinedState === 0 && <button onClick={startMigrate}>Migrate</button>}
      {combinedState === 1 && <button onClick={confirmMigrate}>Confirm Migrate</button>}
      {combinedState === 2 && (
        <div>Migrating Data...</div>
      )}
      {info && <div>{info}</div> }
    </div>
  );
}

export default withRouter(LegacyMigrate);
