/**
*
* ReportPieChart
*
*/

import React from 'react';
import { ResponsivePie } from '@nivo/pie'

// import './ReportPieChart.css';

function ReportPieChart({ data, formatter, axisFormatter }) {

  const threshold = 10
  let chartData = data;

  if (data.length > threshold) {
    const summaryData = data.slice(0, threshold);
    const others = data.slice(threshold).reduce((a, v) => ({
      ...a,
      label: 'Other',
      value: a.value + v.value
    }))
    
    summaryData.push(others);

    chartData = summaryData;
  }

  return (
    <ResponsivePie
      data={chartData}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'nivo' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
      sliceLabel={a => axisFormatter(a.value)}
      tooltipFormat={v => formatter(v)}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableRadialLabels={false}
      radialLabel="label"
      radialLabelsLinkHorizontalLength={20}
      radialLabelsSkipAngle={20}
    />
  )
}

export default ReportPieChart;
