/**
*
* TimePicker
*
*/

import React, { PureComponent } from 'react';
//import './TimePicker.css';

function timeOptions(length, factor = 1) {
  const timeOptions = [];
  const count = length / factor;

  for (let i = 0; i < count; i++) {
    const num = i * factor;
    const padded = num.toString().padStart(2, '0');
    timeOptions.push(<option key={i} value={num}>{padded}</option>);
  }

  return timeOptions;
}

class TimePicker extends PureComponent<any, any> {
  select
  
  minChange(mins) {
    const { onChange, hours } = this.props;
    onChange({ mins, hours });
  }

  hourChange(hours) {
    const { onChange, mins } = this.props;
    onChange({ mins, hours });
  }

  render() {
    const {
      mins,
      hours,
    } = this.props;

    return (
      <div>
        <select
          ref={select => (this.select = select)}
          value={hours}
          onChange={e => this.hourChange(parseInt(e.target.value, 10))}
        >
          { timeOptions(24, 1) }
        </select>
        {':'}
        <select
          value={mins}
          onChange={e => this.minChange(parseInt(e.target.value, 10))}
        >
          { timeOptions(60, 1) }
        </select>
      </div>
    );
  }
}

// TimePicker.propTypes = {
//   mins: PropTypes.number.isRequired,
//   hours: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

export default TimePicker;
