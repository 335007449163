/**
*
* ReportCard
*
*/

import React from 'react';
import classNames from 'classnames';
import './ReportCard.css';

function ReportCard({ fixed, header, children, className }: any) {
  return (
    <div className={classNames('reportCard', fixed && 'fixedWidth', className)}>
      { header && <div className="cardHeader">{ header }</div> }
      <div className="cardBody">{ children }</div>
    </div>
  );
}

export default ReportCard;
