import { isWindows } from "./utils";
import { appInsights } from "./analytics";


const Windows = (window as any).Windows;
const syncProducts = ['cloudSync', 'Pro_Month', 'Pro_Year'];
const blankProduct = {
    isActive: false,
    expirationDate: new Date(),
};

const licenseControl = licenseController();
getLocalPrices();

const storeIds = {
    trends: "9WZDNCRCRTPD",
    cloudSync: "9WZDNCRCRTPF",
    Pro_Month: "9PKV0PZ91PFM",
    Pro_Year: "9N3HC1TX9JLV",
    //cloudSync_test: "9P791ZQSSLLH",
    //Pro_Test: "9NT07VW9V2FK",
};

export const Cloud = {
    cloudSyncPrice_Symbol: "$",

    cloudSyncPrice: "$23.99",
    cloudSyncPrice_Month: "1.99",
    cloudSyncPrice_Month_Formatted: "$1.99",

    cloudSyncBasePrice: "$23.99",
    cloudSyncBasePrice_Month: "1.99",
    cloudSyncBasePrice_Month_Formatted: "$1.99",
};

// TODO: get isPremium from postgres
export function isPremium() {
    //return true;
    //return false;

    if (!isWindows() || !licenseControl) {
        return false;
    }

    return licenseControl.returnActiveProduct(syncProducts).isActive;
};

export function isTrends() {
    //Used for Legacy Access to Trends Page

    if (!isWindows() || !licenseControl) {
        return false;
    }

    //return true;
    //return false;
    return isPremium() || licenseControl.lookup("trends").isActive;
};

export function getPremiumExpiry() {

    if (!isWindows() || !licenseControl) {
        return;
    }

    // TODO: Use Moment to Format
    const newDate = licenseControl.returnActiveProduct(syncProducts).expirationDate;
    return newDate.toLocaleDateString();
};


export function buyEMPro(obj) {

    if (!isWindows() || !licenseControl) {
        return false;
    }

    // const successObj = { userType: "new" };

    //Request Product
    licenseControl.requestPurchase("cloudSync").done(function checkPurchase(args) {
        //Check user now owns product
        if ((args && args.status <= 1) || isPremium()) {
            // TODO: Update Postgres with new User
            console.debug('Success:', args);
            appInsights.trackEvent({ name: 'upgrade-success' });
        } else {
            console.debug('Error:', args);
            appInsights.trackEvent({ name: 'upgrade-fail' }, { error: args });
        };
    }, (err) => {
        console.debug(err);
        appInsights.trackEvent({ name: 'upgrade-fail' }, { error: err });
    });
};

function licenseController() {

    if (!isWindows()) {
        return
    }

    const storeContext = Windows.Services.Store.StoreContext.getDefault();

    let licenseInformation = null;
    let associatedStoreProducts = null;
    const addOnLicenses = {};

    storeContext.addEventListener("offlineLicensesChanged", setLicenseInformation);

    function setLicenseInformation() {
        return storeContext.getAppLicenseAsync().then(function (StoreAppLicense) {
            licenseInformation = StoreAppLicense.addOnLicenses;

            Object.values(licenseInformation).forEach(function (product: any) {
                addOnLicenses[product.inAppOfferToken] = product.skuStoreId;
            });
        });
    };

    function lookup(product) {
        const productId = addOnLicenses[product];
        const hasKey = licenseInformation && licenseInformation.hasKey(productId);
        return (productId && hasKey) ? licenseInformation.lookup(productId) : blankProduct;
    };

    function returnActiveProduct(array) {
        let out = { ...blankProduct };
        const arrayLength = array.length;

        for (let i = 0; i < arrayLength; i++) {
            const target = array[i];
            const product = lookup(target);
            if (product.isActive) {
                out = product;
                break;
            };
        };

        return out;
    };

    function getProductInformation(cache = false) {

        if (associatedStoreProducts && cache !== false) return Promise.resolve(associatedStoreProducts);

        return storeContext.getAssociatedStoreProductsAsync(["Durable"]).then(function (StoreProductQueryResult) {
            associatedStoreProducts = StoreProductQueryResult.products;
            return StoreProductQueryResult;
        });
    };

    function requestPurchase(product) {
        const storeId = storeIds[product];
        return storeContext.requestPurchaseAsync(storeId);
    };

    setLicenseInformation();

    return {
        lookup: lookup,
        returnActiveProduct: returnActiveProduct,
        requestPurchase: requestPurchase,
        getProductInformation: getProductInformation,
    };
};

function getLocalPrices() {

    if (!isWindows() || !licenseControl) {
        return
    }
    
    const networkInfo = Windows.Networking.Connectivity.NetworkInformation;   
    
    licenseControl.getProductInformation().done(function (StoreProductQueryResult) {
        const hasKey = StoreProductQueryResult.products.hasKey(storeIds.cloudSync);
        if (hasKey) {

            const cloudSync = StoreProductQueryResult.products.lookup(storeIds.cloudSync);

            //const user = Windows.System.UserProfile.GlobalizationPreferences;
            const listingBasePrice = cloudSync.price.formattedBasePrice;
            const listingPrice = cloudSync.price.formattedPrice;

            let localCurrencyEngine = null;
            let listingBaseValue = null;
            let listingValue = null;
            let currencySymbol = null;

            //Ensure Prices Exist
            if (!listingPrice || !listingBasePrice) return false;
            //Set Year Prices
            Cloud.cloudSyncBasePrice = listingBasePrice;
            Cloud.cloudSyncPrice = listingPrice;

            //Try and Parse Currency to Set Month Prices
            try {
                localCurrencyEngine = Windows.Globalization.NumberFormatting.CurrencyFormatter(cloudSync.price.currencyCode);

                currencySymbol = getSymbol(listingBasePrice);
                listingBaseValue = localCurrencyEngine.parseDouble(listingBasePrice);
                listingValue = localCurrencyEngine.parseDouble(listingPrice);
            } catch (error) {
                //Failed
                return false;
            };

            //Check Currency Has Parsed Correctly
            if (!listingValue || !listingBaseValue || !currencySymbol) return false;

            //Set Symbol
            Cloud.cloudSyncPrice_Symbol = currencySymbol;

            //Set Base EM Price
            listingBaseValue = (listingBaseValue / 12).toFixed(localCurrencyEngine.fractionDigits || 2);
            Cloud.cloudSyncBasePrice_Month_Formatted = localCurrencyEngine.format(listingBaseValue);
            Cloud.cloudSyncBasePrice_Month = Cloud.cloudSyncBasePrice_Month_Formatted.replace(currencySymbol, "");

            //Set Current EM Price
            listingValue = (listingValue / 12).toFixed(localCurrencyEngine.fractionDigits || 2);
            Cloud.cloudSyncPrice_Month_Formatted = localCurrencyEngine.format(listingValue);
            Cloud.cloudSyncPrice_Month = Cloud.cloudSyncPrice_Month_Formatted.replace(currencySymbol, "");
        };

        networkInfo.removeEventListener('networkstatuschanged', getLocalPrices);
    }, function getPricingError(err) {
        networkInfo.addEventListener('networkstatuschanged', getLocalPrices);
    });


    function getSymbol(listingBasePrice) {

        let currencySymbol = null;

        //Attempt To Explode Currency
        const firstNum = listingBasePrice.search(/\d/);
        const lastNum = listingBasePrice.search(/(\d)[^\d]*$/) + 1;

        if (firstNum > 0) {
            currencySymbol = listingBasePrice.substr(0, firstNum);
        } else if (lastNum !== listingBasePrice.length) {
            currencySymbol = listingBasePrice.substr(lastNum);
        };

        return currencySymbol;
    };
};


