/**
*
* SyncStatusTray
*
*/

import React from 'react';

import './SyncStatusTray.css';

import { CSSTransition } from 'react-transition-group';
import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';
import { useIsSaving, useSavingError } from 'utils/api';

function SyncStatusTray({close, show}) {
  const [saving] = useIsSaving();
  const [savingErr] = useSavingError();

  return (
    <CSSTransition unmountOnExit appear in={show} timeout={150} classNames="fade">
      <WindowClickWrapper outsideClick={close}>
        {renderProps => (
          <div ref={renderProps.ref} className="syncTray">
            <h4>Sync Status</h4>
            {saving && <p><i className="fa fa-refresh fa-spin"></i> Saving...</p> }
            {savingErr && <p><i className="fa fa-warning"></i> The Last Save Failed. Please check your internet connection.</p> }
            {!savingErr && !saving && <p><i className="fa fa-check"></i> Everything Up to Date.</p>}
          </div>
        )}
      </WindowClickWrapper>
    </CSSTransition>
  )
}


export default SyncStatusTray;
