/**
*
* FullscreenModal
*
*/

import React, { PureComponent } from 'react';

import './FullscreenModal.css';

import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';

import { CSSTransition } from 'react-transition-group';

class FullscreenModal extends PureComponent<any, any> {
  render() {
    const props = this.props;
    const classList = ['fullscreen-modal'];

    if (props.className) {
      classList.push(props.className);
    }

    return (
      <CSSTransition unmountOnExit in appear timeout={300} classNames="fade">
        <div className="fullscreen-modal-wrapper">
          <WindowClickWrapper outsideClick={props.close}>
            { renderProps => (
              <div ref={renderProps.ref} className={classList.join(' ')}>
                { props.children }
              </div>
            )}
          </WindowClickWrapper>
        </div>
      </CSSTransition>
    );
  }
}

export default FullscreenModal;
