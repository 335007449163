
export const isWindows = () => !!(window as any).Windows;

export function parseId(id) {
    if (typeof id === 'number') {
      return id;
    }
  
    if (typeof id !== 'string') {
      return undefined;
    }
  
    const isnum = /^\d+$/.test(id);
  
    if (isnum) {
      return parseInt(id, 10);
    }
  
    if (id.includes('temp_')) {
      return id;
    }
  
    return undefined;
  }
