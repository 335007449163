/**
*
* SimpleItemForm
*
*/

import React, { PureComponent } from 'react';

import { withFormik } from 'formik';

import FormikInput from 'components/FormikInput/FormikInput';
import FauxPopover from 'components/FauxPopover/FauxPopover';

import './SimpleItemForm.css';

class SimpleItemForm extends PureComponent<any, any> {
  render() {
    const {
      close,
      handleSubmit,
      outsideClick,
      noAnimation,
      targetName = 'Task'
    } = this.props;

    return (
      <FauxPopover
        outsideClick={outsideClick || close}
        noAnimation={noAnimation}
        className="add-task compact-form"
      >
        <form onSubmit={handleSubmit}>
          <div className="form-body">
            <FormikInput
              focusOnMount
              name="name"
              placeholder={`${targetName} Name...`}
              formikBag={this.props}
            />
          </div>
          <div className="action-row">
            <button type="submit">
              <i className="fa fa-check" />
              {' Save'}
            </button>
            <button type="button" onClick={close}>
              <i className="fa fa-times" />
              {' Close'}
            </button>
          </div>
        </form>
      </FauxPopover>
    );
  }
}

const ValidatedTaskCard = withFormik({
  mapPropsToValues: (props: any) => ({
    name: props.defaultText || '',
  }),

  // Custom sync validation
  validate: (values: any) => {
    const errors: any = {};
    if (!values.name || values.name === '') {
      errors.name = 'Required';
    }
    return errors;
  },

  handleSubmit: (values, { props, resetForm }) => {
    const { name } = values;
    const { submit, columnId, close } = props;
    resetForm();
    submit(name, columnId, close);
  },

  displayName: 'Add Item', // helps with React DevTools
})(SimpleItemForm);

// SimpleItemForm.propTypes = {
//   close: PropTypes.func.isRequired,
// };

// SimpleItemForm.defaultProps = {
//   columnId: undefined,
//   defaultText: '',
// };

export default ValidatedTaskCard;
