/**
*
* EmProgress
*
*/

import React from 'react';

import './EmProgress.css';

import { CSSTransition } from 'react-transition-group';

function EmProgress(props) {
  const progress = `${props.progress ? props.progress : 0}%`;
  const classList = ['progress-wrapper'];

  if (props.className) classList.push(props.className);

  return (
    <div className={classList.join(' ')}>
      <CSSTransition in appear timeout={500} classNames="progress">
        <div style={{ width: progress }} className="progress-pcnt" />
      </CSSTransition>
    </div>
  );
}

export default EmProgress;
