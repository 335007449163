/**
*
* Popover
*
*/

import React, { PureComponent } from 'react';

// import './Popover.css';

import { Popper } from 'react-popper';
import WindowClickWrapper from '../WindowClickWrapper/WindowClickWrapper';

class Popover extends PureComponent<any> {
  node;

  constructor(props) {
    super(props);

    this.node = React.createRef();
  }

  render() {
    const props = this.props;
    const classList = ['em-popup'];
    const popperModifiers: any = {
      preventOverflow: {
        boundariesElement: props.windowBoundary ? 'window' : 'scrollParent',
      },
    };

    if (props.className) classList.push(props.className);

    return (
      <Popper
        modifiers={popperModifiers}
        placement={props.placement || 'bottom'}
      >
        { popperProps => (
          <WindowClickWrapper innerRef={popperProps.ref} outsideClick={props.outsideClick}>
            { clickProps => (
              <div
                className={classList.join(' ')}
                ref={clickProps.ref}
                style={popperProps.style}
                data-placement={popperProps.placement}
              >
                { props.children }
              </div>
            )}
          </WindowClickWrapper>
        )}
      </Popper>
    );
  }
}

export default Popover;
