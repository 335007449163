
export default function modelCache(cache: any = {}) {
  cache.mutate = (mutatedProjects = []) => {
    const next = { ...cache };
    mutatedProjects.forEach(a => (next[a] = undefined));
    return modelCache(next);
  };

  cache.addId = (id, items) => (cache[id] = items);

  return cache;
}
