export const formikClassError = (formikBag, name) => {
  const {
    errors,
    touched,
  } = formikBag;

  return errors[name] && touched[name] && 'input-error';
};

export const isNumber = (test) => {
  return /^(-?\d+\.\d+)$|^(-?\d+)$/.test(test);
};
