/**
*
* Checklist
*
*/

import React, { Component } from 'react';


import Dragula from 'react-dragula';
import classNames from 'classnames';

import { ScreenState } from 'containers/ScreenController/ScreenController';
import ChecklistTask from './ChecklistTask/ChecklistTask';
import { ITask } from 'utils/models/taskModel';

import './Checklist.css';

interface ICheclist {
  task: ITask
}

class Checklist extends Component<ICheclist, any> {
  drake;
  dragContainer;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };
  }

  componentDidMount() {
    let scrollable = true;
    document.addEventListener('touchmove', e => !scrollable && e.preventDefault(), { passive: false });

    const options = {
      moves: () => ScreenState.canDrag(),
    };
    console.log(this.dragContainer)
    const drake = Dragula([this.dragContainer], options);

    drake.on('drag', () => {
      scrollable = false;
    });

    drake.on('drop', (el, _target, _source, sibling) => {
      // dont let drake manage our sort order, on drop find the new sort order
      drake.cancel(true);
      scrollable = true;

      const { checklist, updateChecklistOrder } = this.props.task;
      const next = checklist.map(a => a);

      const from = next.findIndex(item => item.id === parseInt(el.dataset.itemId, 10));

      if (from === -1) {
        return false;
      }

      const item = next.splice(from, 1)[0];
      const to = sibling ? next.findIndex(item => item.id === parseInt(sibling.dataset.itemId, 10)) : null;

      if (to !== null && to !== -1) {
        next.splice(to, 0, item);
      } else {
        next.push(item);
      }

      updateChecklistOrder(next);
    });

    this.drake = drake;
  }

  componentWillUnmount() {
    this.drake && this.drake.destroy();
  }

  addTask(e) {
    e.preventDefault();

    const { task } = this.props;

    task.addChecklistItem(this.state.text);
    this.setState({
      text: '',
    });
  }

  handleChange(e) {
    this.setState({
      text: e.target.value,
    });
  }

  render() {
    const state = this.state;
    const props = this.props;
    const task = props.task;
    const checklistItems = task.checklist;
    const empty = checklistItems && checklistItems.length === 0;

    return (
      <div className="em-checklist">
        <form onSubmit={e => this.addTask(e)} className="input-row">
          <input
            type="text"
            placeholder="New Item..."
            value={state.text}
            onChange={e => this.handleChange(e)}
          />
          <button type="submit" className="btn">Add</button>
        </form>
        {/* We cant unmount this div or dragula will lose ref to it. So instead we just hide it with css */}
        <div ref={ref => (this.dragContainer = ref)} className={classNames("em-checklist-surface", empty && "checklist-empty")}>
          { checklistItems.map(item => <ChecklistTask key={item.id} parent={task} item={item} />)}
        </div>
      </div>
    );
  }
}

export default Checklist;
