/**
*
* MoveButton
*
*/

import React, { PureComponent } from 'react';

// import './MoveButton.css';

import { Manager, Reference } from 'react-popper';
import MoveTaskForm from '../MoveTaskForm/MoveTaskForm';

class MoveButton extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      popup: false,
    };
  }

  render() {
    const open = this.state.popup;
    const props = this.props;

    return (
      <Manager>
        <Reference>
          { renderProps => (
            <button
              ref={renderProps.ref}
              type="button"
              className="action-btn"
              onClick={() => this.setState({ popup: !open })}
            >
              <i className="fa fa-mail-forward" />
            Move
            </button>
          )}
        </Reference>
        { open && <MoveTaskForm task={props.task} close={() => this.setState({ popup: false })} /> }
      </Manager>
    );
  }
}


export default MoveButton;
