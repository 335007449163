/**
*
* ChecklistTask
*
*/

import React, { PureComponent } from 'react';

// import './ChecklistTask.css';

import { CSSTransition } from 'react-transition-group';
import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';
import { ITaskChecklist, ITask } from 'utils/models/taskModel';


function getStatus(complete) {
  const classList = ['fa', 'fa-check-square', 'task-status'];

  if (complete) classList.push('complete');

  return <i className={classList.join(' ')} />;
}

interface IChecklistTask {
  item: ITaskChecklist
  parent: ITask
}

class ChecklistTask extends PureComponent<IChecklistTask, any> {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      delete: false,
      editText: '',
    };
  }

  selecteTemplate(className, editMode, deleteMode, item) {
    if (editMode) {
      return this.editTemplate(className);
    } if (deleteMode) {
      return this.deleteTemplate(className, item);
    }
    return this.taskTemplate(className, item);
  }

  taskTemplate(className, { title, complete, id }) {
    return (
      <div className={className} data-item-id={id}>
        <div onClick={() => this.complete()} className="task-body">
          { getStatus(complete) }
          { title || '(unnamed)' }
        </div>
        <div className="edit-box">
          <button type="button" onClick={() => this.toggleEdit()}>
            <i className="fa fa-pencil" />
          </button>
          <button type="button" onClick={() => this.toggleDelete()}>
            <i className="fa fa-trash" />
          </button>
        </div>
      </div>
    );
  }

  editTemplate(className) {
    return (
      <WindowClickWrapper outsideClick={() => this.toggleEdit()}>
        { renderProps => (
          <form ref={renderProps.ref} onSubmit={(e) => this.updateTitle(e)} className={className}>
            <input type="text" value={this.state.editText} onChange={e => this.handleChange(e)} />
            <div className="edit-box">
              <button type="submit" className="btn-confirm">
                <i className="fa fa-check" />
              </button>
              <button type="button" onClick={() => this.toggleEdit()}>
                <i className="fa fa-times" />
              </button>
            </div>
          </form>
        )}
      </WindowClickWrapper>
    );
  }

  deleteTemplate(className, { title, complete }) {
    return (
      <WindowClickWrapper outsideClick={() => this.toggleDelete()}>
        { renderProps => (
          <div ref={renderProps.ref} className={className}>
            <div className="task-body">
              { getStatus(complete) }
              { title || '(unnamed)' }
            </div>
            <div className="edit-box">
              <button type="submit" className="btn-danger" onClick={() => this.confirmDelete()}>
                Confim
                <i className="fa fa-trash" />
              </button>
              <button type="button" onClick={() => this.toggleDelete()}>
                Cancel
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
        )}
      </WindowClickWrapper>
    );
  }

  handleChange(e) {
    this.setState({
      editText: e.target.value,
    });
  }

  toggleEdit() {
    this.setState({
      edit: !this.state.edit,
      delete: false,
      editText: this.props.item.title,
    });
  }

  toggleDelete() {
    this.setState({
      delete: !this.state.delete,
      edit: false,
    });
  }

  complete() {
    const props = this.props;
    const task = props.parent;
    const item = props.item;

    task.updateChecklistItem({
      ...item,
      complete: !item.complete,
    });
  }

  updateTitle(e) {
    e.preventDefault()
    const props = this.props;
    const task = props.parent;
    const item = props.item;

    task.updateChecklistItem({
      ...item,
      title: this.state.editText,
    });

    this.toggleEdit();
  }

  confirmDelete() {
    const props = this.props;
    const task = props.parent;
    const item = props.item;

    task.removeChecklistItem(item.id);
  }

  render() {
    const state = this.state;
    const props = this.props;
    const item = props.item;
    const classList = ['em-checklist-task'];
    const complete = item.complete === true;
    const editMode = state.edit;
    const deleteMode = state.delete;

    if (complete) classList.push('complete');

    if (editMode) classList.push('edit-mode');
    else if (deleteMode) classList.push('delete-mode');
    else classList.push('display-mode');

    return (
      <CSSTransition in appear timeout={300} classNames="fade">
        { this.selecteTemplate(classList.join(' '), editMode, deleteMode, item) }
      </CSSTransition>
    );
  }
}


export default ChecklistTask;
