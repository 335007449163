export function objectsMatch(a, b) {
  const keys = Object.keys(a);
  const keyLength = keys.length;

  for (let i = 0; i < keyLength; i++) {
    const key = keys[i];

    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

export function reduceSum(acc, val) {
  if (typeof val !== 'number' || Number.isNaN(val)) {
    return acc;
  }

  return acc + val;
}


export function getTopValues(data, limit = 5) {
  const filteredData = data.filter(a => a.value !== 0);
  const sortedData = filteredData.sort((a, b) => {
    if (a.value < b.value) return 0;

    return a.value < b.value ? 1 : -1;
  });
  const topData = sortedData.slice(0, limit);

  const topValues = topData.map(a => a.value);
  const topLabels = topData.map(a => a.label);

  if (topData.length !== sortedData.length) {
    const removedData = sortedData.slice(limit).map(a => a.value);
    const removedValue = removedData.reduce(reduceSum);

    topValues.push(removedValue);
    topLabels.push('Other');
  }

  return {
    topValues,
    topLabels,
  };
}
