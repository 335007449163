/**
*
* AddProjectCard
*
*/

import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';

import FormikInput from 'components/FormikInput/FormikInput';
import DynamicSelect from 'components/DynamicSelect/DynamicSelect';
import FullscreenModal from 'components/FullscreenModal/FullscreenModal';

import { newProject } from 'utils/models/projectModel';
import { newTask } from 'utils/models/taskModel';
import { isNumber, formikClassError } from 'utils/validators';
import {
  formatCurrency,
  NO_TRACKING,
  HOURLY_TRACKING,
  TIMING_ON,
  COST_TRACKING_OPTIONS,
  TIME_TRACKING_OPTIONS,
  COST_UPGRADE_MSG,
} from 'utils/constants';
import { TIMING_OFF } from '../../../utils/constants';

import './AddProjectCard.css';
import { IDataControllerState } from 'utils/context';
import { isPremium } from 'utils/premium';
import PremiumBlockCard from 'components/PremiumBlockCard/PremiumBlockCard';
import { isDurationValid } from 'utils/date';

const saveElements = (isSubmitting, close) => (
  <div className="action-row">
    { isSubmitting ? (
      <button className="faux-submit" type="button">
        <i className="fa fa-spinner fa-spin" />
        {' Saving'}
      </button>
    ) : (
      <Fragment>
        <button type="submit">
          <i className="fa fa-check" />
          {' Save'}
        </button>
        <button type="button" onClick={() => close()}>
          <i className="fa fa-times" />
          {' Close'}
        </button>
      </Fragment>
    )}
  </div>
)

const newProjectElements = (formikBag, data) => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    close
  } = formikBag;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-block">
        <label>Name</label>
        <FormikInput
          focusOnMount
          name="title"
          formikBag={formikBag}
        />
      </div>

      <div className="form-block">
        <label>Association</label>
        <select
          name="groupId"
          value={values.groupId}
          onChange={handleChange}
        >
          {data.groups.map(({ id, title }) => <option key={id} value={id}>{ title }</option>)}
        </select>
      </div>

      <div className="form-block">
        <label>Description</label>
        <textarea
          name="description"
          value={values.description}
          onChange={handleChange}
        />
      </div>

      <div className="form-block">
        <div className="inline">
          <label>Cost Tracking</label>
          <DynamicSelect
            options={COST_TRACKING_OPTIONS}
            onSelect={(value) => {
              setFieldValue('costTracking', value);
              if (value === HOURLY_TRACKING && isPremium()) {
                setFieldValue('timeTracking', true);
              }
            }}
            selected={values.costTracking}
          />
        </div>
        { isPremium() && values.costTracking === HOURLY_TRACKING && (
          <div className="inline">
            <label>
              { `Default Rate (${formatCurrency(null)}/hr)` }
            </label>
            <input
              type="number"
              min="0"
              step="0.01"
              value={values.rate}
              onChange={e => {
                const rate = parseFloat(e.target.value);
                if (isDurationValid(rate)) {
                  setFieldValue('rate', rate);
                }
              }}
              className={formikClassError(formikBag, 'rate')}
            />
          </div>
        )}
        { !isPremium() && values.costTracking !== NO_TRACKING && (
          <PremiumBlockCard title={null} info={COST_UPGRADE_MSG} minimal={true} />
        )}
      </div>

      <div className="form-block inline">
        <label>Time Tracking</label>
        <DynamicSelect
          disabled={isPremium() && values.costTracking === HOURLY_TRACKING}
          options={TIME_TRACKING_OPTIONS}
          onSelect={value => setFieldValue('timeTracking', value === TIMING_ON)}
          selected={values.timeTracking ? TIMING_ON : TIMING_OFF}
        />
      </div>
      
      {saveElements(isSubmitting, close)}
    </form>
  );
};

const duplicateProjectElements = (formikBag, data) => {
  const {
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    close,
  } = formikBag;
  
  if (!isPremium()) {
    return (
      <PremiumBlockCard
        info="This feature allows you to use an existing project as a template to create a new project."
      />
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-block">
        <label>Template</label>
        <select name="templateId" value={values.templateId} onChange={handleChange}>
          { data.groups.map((group) => {
            const groupId = group.id;
            const filteredProjects = data.projects.filter(a => a.association_id === groupId);
            return (
              <optgroup key={groupId} label={group.title}>
                { filteredProjects.map((project) => {
                  const id = project.id;
                  return <option key={id} value={id}>{ project.properties.title }</option>;
                })}
              </optgroup>
            );
          })}
        </select>
      </div>

      <div className="form-block">
        <label>Name</label>
        <FormikInput
          focusOnMount
          name="title"
          formikBag={formikBag}
        />
      </div>

      <div className="form-block">
        <label>Association</label>
        <select name="groupId" value={values.groupId} onChange={handleChange}>
          { data.groups.map((group) => {
            const id = group.id;
            return <option key={id} value={id}>{ group.title }</option>;
          }) }
        </select>
      </div>

      <div className="form-block">
        <label>Description</label>
        <textarea
          name="description"
          value={values.description}
          onChange={handleChange}
        />
      </div>

      {saveElements(isSubmitting, close)}
    </form>
  );
};

function AddProjectCard(props: any) {
  const {
    close,
    values,
    setFieldValue,
    data
  } = props;

  return (
    <FullscreenModal close={close} className="add-project-form">
      <h2>Add Project</h2>

      {data.hasData() && (
        <div className="form-nav">
          <button
            type="button"
            onClick={() => setFieldValue('newProjectForm', true, false)}
            className={values.newProjectForm ? 'active' : undefined}
          >
          New
          </button>
          <button
            type="button"
            onClick={() => setFieldValue('newProjectForm', false, false)}
            className={!values.newProjectForm ? 'active' : undefined}
          >
          Copy Existing
          </button>
        </div>
      )}
      { values.newProjectForm ? (
        newProjectElements(props, data)
      ) : (
        duplicateProjectElements(props, data) 
      )}
    </FullscreenModal>
  );
}

const ValidatedProjectCard = withFormik({
  mapPropsToValues: ({ duplicate, templateId, groupId }: any) => ({
    newProjectForm: !duplicate,
    templateId,
    groupId,
    title: '',
    description: '',
    rate: 0,
    costTracking: NO_TRACKING,
    timeTracking: false,
  }),

  // Custom sync validation
  validate: (values: any) => {
    const errors: any = {};
    if (!values.title || values.title === '') {
      errors.title = 'Required';
    }
    if (values.costTracking === HOURLY_TRACKING && !isNumber(values.rate)) {
      errors.rate = 'Must be a valid number';
    }
    return errors;
  },

  handleSubmit: (values, { props }) => {
    const { newProjectForm, groupId, templateId, title, description } = values;
    const { close, data }: { close: any; data: IDataControllerState } = props;

    if (values.costTracking !== NO_TRACKING && !isPremium()) {
      values.costTracking = NO_TRACKING;
    }

    if (newProjectForm) {
      const next = newProject(groupId, values);

      data.projects.insert(next, null, close.bind(this));
    } else {
      const { history } = props;
      const projectId = templateId || data.projects[0].id;
      const { properties, columns } = data.projects.get(projectId) || { properties: null, columns: null };

      if (!properties || !columns) {
        console.error('Could not find template');
        return;
      }

      const propsTemplate = { ...properties, title, description };
      const taskTemplates = data.tasks.getProjectTasks(projectId);

      const nextProject = newProject(groupId, propsTemplate, columns);
      const nextTasks = taskTemplates.map(taskTemplate => {
        const checklistCopy = taskTemplate.checklist.map(item => ({ ...item, complete: false }));
        return newTask(null, taskTemplate.properties, checklistCopy)
      });

      data.projects.insert(nextProject, nextTasks, (id) => {
        history.push(`/${id}`);
        props.close();
      });
    }
  },

  displayName: 'Add Project Form', // helps with React DevTools
})(AddProjectCard);

export default withRouter(ValidatedProjectCard);
