/**
 *
 * AuthWrapper
 *
 */

import React from 'react';

// import styles from './AuthWrapper.css';

function AuthWrapper({ loading, message, children = null }) {

  if (loading) {
    return (
      <div className="data-loading">
        <div className="loading-message">
          <i className="fa fa-spinner fa-spin" />
          <h4>
            { message }
          </h4>
        </div>
      </div>
    );
  }

  return children;
}

export default AuthWrapper;
