/**
*
* ProjectWidgetValue
*
*/

import React from 'react';
// import './ProjectWidgetValue.css';

import { Pie } from 'react-chartjs-2';
import ProjectWidgetPlaceholder from 'components/ProjectWidgets/ProjectWidgetPlaceholder/ProjectWidgetPlaceholder';

import { formatCurrency } from 'utils/constants';
import { reduceSum, getTopValues } from 'utils/manipulation';

function ProjectWidgetValue({ tasks, project }) {
  const hourly = project.hasHourlyTracking();
  const getValue = hourly ? (obj => obj.stats.hourlyValue) : (obj => obj.properties.fixedValue);
  const pieData = tasks.map(a => ({
    label: a.properties.title,
    value: getValue(a),
  }));

  const { topValues, topLabels } = getTopValues(pieData);

  const data = {
    labels: topLabels,
    datasets: [{
      data: topValues,
      backgroundColor: [
        '#4E82AC',
        '#76A4CA',
        '#50C273',
        '#78D895',
        '#FF7E69',
        '#FF9D8E',
      ],
      hoverBackgroundColor: [
        '#1A5484',
        '#336A98',
        '#15983D',
        '#32AE58',
        '#15983D',
        '#EB5B44',
      ],
      borderWidth: 1,
    }],
  };

  if (topValues.length === 0 || topValues.reduce(reduceSum, 0) === 0) {
    return (<ProjectWidgetPlaceholder />);
  }

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        labels: {
          usePointStyle: true,
        },
        tooltips: {
          callbacks: {
            label: ({ index }, { labels, datasets }) => {
              const label = labels[index] || '';
              const value = datasets[0].data[index];

              return ` ${label} ${formatCurrency(value, true)}`;
            },
          },
        },
      }}
    />
  );
}

// ProjectWidgetValue.propTypes = {
//   tasks: PropTypes.any.isRequired,
//   project: PropTypes.any.isRequired,
// };

export default ProjectWidgetValue;
