import React, { useState } from 'react';
import {
  Route, Switch, BrowserRouter as Router, Redirect,
} from 'react-router-dom';

import Overview from 'containers/Overview/Overview';
import Project from 'containers/Project/Project';
import Review from 'containers/Review/Review';
import Reports from 'containers/Reports/Reports';
import Upgrade from 'containers/Upgrade/Upgrade';
import User from 'containers/User/User';
import DataController from 'containers/DataController/DataController';
import ScreenController from 'containers/ScreenController/ScreenController';

import NavBar from './NavBar/NavBar';
import SyncStatusTray from './SyncStatusTray/SyncStatusTray';
import ProjectTray from './ProjectTray/ProjectTray';
import LoadingWrapper from '../../components/LoadingWrapper/LoadingWrapper';

import './App.css';

function App() {
  const [showSync, setShowSync] = useState(false);
  const [showTray, setShowTray] = useState(false);

  const toggleSyncStatus = (e) => {
    e.stopPropagation();
    setShowSync(!showSync);
  };

  const toggleTray = (e) => {
    e.stopPropagation();
    setShowTray(!showTray);
  };

  const main = (screen) => {
    const { mobileNavOpen, mobileView } = screen;

    return (
      <div className="appGrid">
        {(!mobileView || mobileNavOpen) && (
          <NavBar
            toggleTray={toggleTray}
            toggleSyncStatus={toggleSyncStatus}
            showTray={showTray}
            showSync={showSync}
            screen={screen}
          />
        )}
        <div className="main">
          <Switch>
            <Route path="/projects" component={Overview} />
            <Route path="/summary" component={Review} />
            <Route path="/reports" component={Reports} />
            <Route path="/upgrade" component={Upgrade} />
            <Route path="/user" component={User} />
            <Route path="/:projectId" component={Project} />
            <Redirect to="/projects" />
          </Switch>
          <ProjectTray show={showTray} close={toggleTray} />
          <SyncStatusTray show={showSync} close={toggleSyncStatus} />
        </div>
      </div>
    );
  };

  return (
    <ScreenController>
      { screen => (
        <DataController isAuthenticated={true} token={true}>
          { data => (
            <LoadingWrapper loading={!data.loaded} message={"Loading App..."}>
              <Router>
                { main(screen) }
              </Router>
            </LoadingWrapper>
          )}
        </DataController>
      )}
    </ScreenController>
  );
}

export default App;
