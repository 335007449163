/**
*
* TaskCard
*
*/

import React, { Fragment } from 'react';

import './TaskCard.css';

import { useDataContext } from 'utils/context';
import { colourPicker } from 'utils/core';

import { withRouter, matchPath } from 'react-router';

import CompleteButton from 'components/TaskButtons/CompleteButton/CompleteButton';
import PlayPauseButton from 'components/TaskButtons/PlayPauseButton/PlayPauseButton';
import CardSession from '../CardSession/CardSession';

import { formatCurrency, PRIORITY_VALUE } from '../../../utils/constants';
import { ITask } from 'utils/models/taskModel';
import { IProject } from 'utils/models/projectModel';
import { IGroup } from 'utils/models/groupModel';

function priorityIcon(isComplete, priority) {
  const val = PRIORITY_VALUE[priority];
  const out = [];

  if (isComplete || !val) return null;

  if (val < 0) {
    out.push(<i key={val} className="fa fa-long-arrow-down" />);
  } else if (val > 0) {
    for (let i = 0; i < val; i++) {
      out.push(<i key={`${val}_${i}`} className="fa fa-long-arrow-up" />);
    }
  }

  return (
    <div className="stat priorityStat">
      { out }
    </div>
  );
}

interface ITaskCard {
  item: ITask;
  project: IProject;
  group: IGroup;
  history: any;
  location: any;
  explicitProject: boolean
}

function TaskCard(props: ITaskCard) {
  const {
    item: task,
    project,
    group,
    location,
    explicitProject,
  } = props;
  const data = useDataContext();
  const { pathname } = location;

  const taskProps = task.properties;

  const title = taskProps.title;

  const classList = ['task-card', 'a-block'];
  const destination = `/${task.project_id}/tasks/${task.id}`;
  const selected = pathActive(pathname, destination);
  const style: any = { };
  const actionStyle = { };

  const checklistLength = task.checklist.length;
  const checklistComplete = task.checklist.filter(a => a.complete).length;
  const hasDeadline = taskProps.deadline;
  const isComplete = taskProps.completed;
  const isActive = taskProps.active;
  const hasTiming = project.hasTimeTracking();
  const hasAdded = project.hasAddedTracking();
  const column = task.getColumn();

  const value = task.getValue();

  // Check Active
  if (selected) classList.push('selected');

  if (isActive) classList.push('active');

  // Check Complete
  if (isComplete) {
    classList.push('complete');
  } else {
    const colorKey = group.color;
    if (colorKey) {
      style.borderColor = colourPicker[colorKey];
      style.backgroundColor = colourPicker[colorKey];
    }
  }

  return (
    <div
      style={style}
      className={classList.join(' ')}
      onClick={navigate}
    >
      <div className="card-main">
        <div className="header">
          <div>{ title }</div>
        </div>
        <div className="card-stats">
          { !isComplete && hasDeadline && inlineDate(task.isOverdue(), taskProps.deadline) }
          <CardSession hideIfZero task={task} project={project}>
            { ({ shortDuration, value: sessionValue }) => (
              <Fragment>
                <div className="stat">
                  {`${shortDuration} hrs`}
                </div>
                { project.hasHourlyTracking() && (
                  <div className="stat">
                    { formatCurrency(sessionValue, true) }
                  </div>
                )}
              </Fragment>
            )}
          </CardSession>
          { hasAdded && value > 0 && (
            <div className="stat">
              { formatCurrency(value, true) }
            </div>
          )}
          { priorityIcon(isComplete, taskProps.priority) }
          { checklistSummary(checklistComplete, checklistLength) }
        </div>
        { explicitProject && (
          <div className="card-stats card-subtitle">
            {project.properties.title}{column && ` - ${column.title}`}
          </div>
        )}
        <div onClick={stopPropagation} className="action-panel" style={actionStyle}>
          <CompleteButton task={task} isComplete={isComplete} />
          <PlayPauseButton
            task={task}
            data={data}
            hasTiming={!isActive && hasTiming}
            isComplete={isComplete}
            isActive={isActive}
          />
        </div>
      </div>
      { isActive && (
        <div onClick={stopPropagation} className="dummy-action-panel" style={actionStyle}>
          <PlayPauseButton
            task={task}
            data={data}
            hasTiming={hasTiming}
            isComplete={isComplete}
            isActive={isActive}
          />
        </div>
      )}
    </div>
  );

  function navigate() {
    const { history, explicitProject } = props;
    if (selected) {
      history.goBack();
      return;
    }

    const rootLoc = `/${task.project_id}/tasks`;
    const isRoot = pathActive(pathname, rootLoc);

    if (isRoot || explicitProject) {
      history.push(destination);
    } else {
      history.replace(destination);
    }
  }

  function pathActive(target, path) {
    return matchPath(target, { path, exact: true, strict: false });
  }

  function inlineDate(bool, dateStr) {
    const dateClassList = ['stat'];

    if (bool) {
      dateClassList.push('overdue');
    }

    return <div className={dateClassList.join(' ')}>{ new Date(dateStr).toLocaleDateString() }</div>;
  }

  function checklistSummary(complete: number, length: number) {

    if (length === 0) {
      return null
    }

    return <div className="stat"><i className="fa fa-check-square" /> {complete}/{length}</div>;
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }
}

export default withRouter(TaskCard);
