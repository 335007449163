/**
*
* IconButton
*
*/

import React from 'react';
import './IconButton.css';

function IconButton(props) {
  return (
    <div className="iconbutton-wrapper em-interactive" onClick={props.onClick}>
      <div className="icon">{ props.icon }</div>
      <div className="name">{ props.name }</div>
    </div>
  );
}

// IconButton.propTypes = {
//   onClick: PropTypes.func,
//   icon: PropTypes.node,
//   name: PropTypes.string,
// };

export default IconButton;
