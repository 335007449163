/**
*
* DeleteColumn
*
*/

import React from 'react';

// import './DeleteColumn.css';

import IconButton from 'components/TaskButtons/IconButton/IconButton';
import FauxPopover from 'components/FauxPopover/FauxPopover';

function DeleteColumn(props: any) {
  if (!props.isEmpty) {
    return (
      <FauxPopover outsideClick={props.close} noAnimation>
        <p>{`Please remove all of the ${props.child} from the ${props.parent} to delete it.`}</p>
        <br />
        <button onClick={props.close} type="button" className="btn">OK</button>
      </FauxPopover>
    );
  }

  return (
    <FauxPopover outsideClick={props.close} noAnimation>
      <IconButton
        onClick={() => props.submit()}
        icon={<i className="fa fa-check" />}
        name="Confirm"
      />
      <IconButton
        onClick={props.back}
        icon={<i className="fa fa-times" />}
        name="Cancel"
      />
    </FauxPopover>
  );
}

export default DeleteColumn;
