/**
 *
 * DataController
 *
 */

import React, { PureComponent } from 'react';
// import './DataController.css';

import { groupArrayModel, groupModel, ungrouped } from 'utils/models/groupModel';
import { projectArrayModel, projectModel } from 'utils/models/projectModel';
import { taskArrayModel, taskModel } from 'utils/models/taskModel';
import { getData } from 'utils/api';
import { DataContext, IDataControllerState } from 'utils/context';
import { userPreferences } from 'utils/preferences';

const getInitialState = () => ({
  loading: false,
  loaded: false,
  tasks: null,
  projects: null,
  groups: null,
});

interface IDataControllerProps {
  isAuthenticated: boolean;
  token: boolean;
}

class DataController extends PureComponent<IDataControllerProps, IDataControllerState> {
  constructor(props) {
    super(props);

    // Blank State
    this.state = {
      token: undefined,
      ...getInitialState(),
      hasData() {
        return this.loaded && this.projects && (this.projects.length > 0);
      },
      reload: () => this.loadData(),
    };
    
    // networkInfo.addEventListener('networkstatuschanged', reSyncApp);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;

    if (isAuthenticated && !prevProps.isAuthenticated) {
      this.loadData();
    } else if (!isAuthenticated && prevProps.isAuthenticated) {
      this.setState(getInitialState());
    } else {
      this.checkReTry();
    }
  }

  checkReTry() {
    const { loaded, token } = this.state;

    if (!loaded && token !== this.props.token) {
      this.loadData();
    }
  }

  loadData() {
    const { isAuthenticated, token } = this.props;

    if (!isAuthenticated || !token) {
      return;
    }

    this.setState({ loading: true, token });

    getData().then(({ data }) => {
      // Wrap response in data model
      const groups = [ungrouped, ...data.associations];
      const tasksAsModel = data.tasks.map(a => taskModel(a, this));
      const projectsAsModel = data.projects.map(a => projectModel(a, this));
      const groupsAsModel = groups.map(a => groupModel(a, this));

      console.debug('App Initialised!');

      userPreferences.next(data.preferences);

      // Update State
      this.setState({
        loading: false,
        loaded: true,
        tasks: taskArrayModel(tasksAsModel, this),
        projects: projectArrayModel(projectsAsModel, this),
        groups: groupArrayModel(groupsAsModel, this),
      });
    }).catch(() => {
      this.setState(getInitialState(), () => this.checkReTry());
    });
  }

  render() {
    const { children }: any = this.props;
    return (
      <DataContext.Provider value={this.state}>
        { children(this.state) }
      </DataContext.Provider>
    );
  }
}

export default DataController;
