/**
*
* CurrentSession
*
*/

import { PureComponent } from 'react';
import './CardSession.css';

import { formatDuration } from 'utils/date';

class CurrentSession extends PureComponent<any, any> {
  clock;

  constructor(props) {
    super(props);

    this.state = this.getState();
  }

  componentDidMount() {
    this.checkTick(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.task !== this.props.task) {
      this.tick();
      this.checkTick(this.props, prevProps);
    }
  }

  componentWillUnmount() {
    clearInterval(this.clock);
  }

  getSessionDuration() {
    const { task } = this.props;
    const { starttime } = task.properties;
    let activeDuration = 0;

    if (starttime) {
      activeDuration = Date.now() - new Date(starttime).getTime();
    }

    return activeDuration;
  }

  getDuration(activeDuration) {
    const { task } = this.props;
    return task.stats.timespent + activeDuration;
  }

  getValue(activeDuration) {
    const { task, project } = this.props;
    return task.stats.hourlyValue + task.calculateSessionValue(activeDuration, project.getDefaultRate());
  }

  getState() {
    const sessionDuration = this.getSessionDuration();
    const rawDuration = this.getDuration(sessionDuration);

    const state = {
      rawDuration,
      duration: formatDuration(rawDuration, true),
      value: this.getValue(sessionDuration),
    };

    return state;
  }

  checkTick(props, prevProps?) {
    const { active } = props.task.properties;
    const prevActive = prevProps && prevProps.task.properties.active;

    if (active === prevActive) {
      return;
    }

    if (active) {
      clearInterval(this.clock);
      this.clock = setInterval(() => this.tick(), 1000);
    } else {
      clearInterval(this.clock);
    }
  }

  tick() {
    const nextState = this.getState();
    this.setState(nextState);
  }

  render() {
    const { rawDuration, duration, value } = this.state;
    const { children, task, hideIfZero }: any = this.props;
    const { active } = task.properties;
    const [hours, mins] = duration.split(':');
    const shortDuration = `${hours}:${mins}`;
    const durationDisplay = active ? duration : shortDuration;

    if (!rawDuration && hideIfZero) return null;

    return children({ shortDuration, duration, durationDisplay, value });
  }
}

export default CurrentSession;
