/**
*
* TaskTitleCard
*
*/

import React, { PureComponent } from 'react';
import './TaskTitleCard.css';

import { colourPicker } from 'utils/core';

import EditPanelText from 'components/SidePanel/EditPanelText/EditPanelText';
import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';
import { ITask } from 'utils/models/taskModel';

interface ITaskTitleCard {
  task: ITask;
  color: string;
}

class TaskTitleCard extends PureComponent<ITaskTitleCard, any> {
  input;

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
    };
  }

  openEdit() {
    this.setState({ editMode: true });
  }

  closeEdit() {
    this.setState({ editMode: false });
  }

  save(value: string) {
    const { task } = this.props;
    task.simpleUpdate(value, 'title');
    this.closeEdit();
  }

  render() {
    const { editMode } = this.state;
    const { task, color } = this.props;
    const { title, completed } = task.properties;
    const style = {
      backgroundColor: completed ? colourPicker.green : colourPicker[color],
    };

    if (editMode) {
      return (
        <WindowClickWrapper outsideClick={() => this.closeEdit()}>
          { renderProps => (
            <EditPanelText
              className="dummy-task"
              forwardRef={renderProps.ref}
              style={style}
              value={title}
              close={() => this.closeEdit()}
              save={e => this.save(e)}
            />
          )}
        </WindowClickWrapper>
      );
    }

    return (
      <div
        onClick={() => this.openEdit()}
        className="dummy-task closed"
        style={style}
      >
        <h4>{ title }</h4>
        <span className="fa fa-pencil" />
      </div>
    );
  }
}

// TaskTitleCard.defaultProps = {
//   task: { title: 'Error' },
//   color: colourPicker.blue,
// };

// TaskTitleCard.propTypes = {
//   task: PropTypes.any,
//   color: PropTypes.string,
// };

export default TaskTitleCard;
