/**
*
* DashCard
*
*/

import React, { PureComponent } from 'react';

// import './DashCard.css';

class DashCard extends PureComponent<any> {
  render() {
    const props = this.props;
    const classList = ['dash-card'];

    if (props.className) classList.push(props.className);

    return (
      <div className={classList.join(' ')}>{ this.props.children }</div>
    );
  }
}

export default DashCard;
