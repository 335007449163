/**
*
* PanelSection
*
*/

import React, { PureComponent } from 'react';

import './PanelSection.css';

import { CSSTransition } from 'react-transition-group';

import { Manager, Reference } from 'react-popper';
import WindowClickWrapper from 'components/WindowClickWrapper/WindowClickWrapper';

class PanelSection extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };
  }

  toggleEditMode(bool) {
    const { selectable, editForm } = this.props;
    if (selectable !== false && editForm) {
      this.setState({ edit: bool });
    }
  }

  save(e) {
    const save = this.props.save;

    if (save) save(e);

    this.toggleEditMode(false);
  }

  renderBody(value, formattedValue, content, editMode, docketEdit) {
    if (editMode && docketEdit) {
      const { editForm: EditForm, renderProps } = this.props;

      let renderEditForm = renderProps => (
        <EditForm
          forwardRef={renderProps.ref}
          value={value}
          close={() => this.toggleEditMode(false)}
          save={value => this.save(value)}
        />
      );

      if (renderProps) {
        renderEditForm = EditForm
      }

      return (
        EditForm
        && (
        <WindowClickWrapper outsideClick={() => this.toggleEditMode(false)}>
          { renderEditForm }
        </WindowClickWrapper>
        )
      );
    } if (value) {
      return <div className="section-value">{ formattedValue }</div>;
    } if (!content) {
      return <div className="section-value no-value">(none)</div>;
    }

    return null;
  }

  renderPopover(value, editMode, dockedEdit) {
    if (editMode && !dockedEdit) {
      const { editForm: EditForm, renderProps } = this.props;

      if (renderProps) {
        return EditForm({
          value,
          close: () => this.toggleEditMode(false),
          save: newVal => this.save(newVal),
        });
      }

      return (
        <EditForm
          value={value}
          close={() => this.toggleEditMode(false)}
          save={newVal => this.save(newVal)}
        />
      );
    }

    return null;
  }

  render() {
    const state = this.state;
    const props = this.props;
    const classList = ['panel-section'];

    const title = props.title;
    const value = props.value;
    const formattedValue = props.formattedValue === undefined ? value : props.formattedValue;
    const content = props.content;
    const className = props.className;

    const editMode = state.edit === true;
    const selectable = props.selectable !== false;
    const bodyscroll = props.bodyscroll === true;
    const inlineEdit = props.inlineEdit;
    const dockedEdit = props.dockedEdit !== false;
    let inline = props.inline !== false;

    if (inlineEdit !== undefined && editMode) {
      inline = inlineEdit;
    } else if (!editMode && !value && !content) {
      inline = true;
    }

    if (!editMode && selectable) classList.push('can-hover');
    if (bodyscroll) classList.push('bodyscroll');
    if (className) classList.push(className);

    return (
      <CSSTransition in appear timeout={300} classNames="fade">
        <div
          onClick={() => !editMode && this.toggleEditMode(true)}
          className={classList.join(' ')}
        >
          <Manager>
            <Reference>
              { renderProps => (
                <div ref={renderProps.ref} className={inline ? 'title-row inline' : 'title-row'}>
                  { (!editMode || !props.fullWidthEdit) && <div className="section-title">{ title }</div> }
                  { this.renderBody(value, formattedValue, content, editMode, dockedEdit) }
                </div>
              )}
            </Reference>
            { this.renderPopover(value, editMode, dockedEdit) }
          </Manager>
          { content && <div className="panel-section-body">{ content }</div> }
        </div>
      </CSSTransition>
    );
  }
}


export default PanelSection;
