/**
*
* CompleteButton
*
*/

import React from 'react';
import { ITask } from 'utils/models/taskModel';

// import './CompleteButton.css';

interface ICompleteButton {
  task: ITask;
  isComplete: boolean;
}

function CompleteButton({ task, isComplete }: ICompleteButton) {
  return (
    <button
      type="button"
      className="action-btn"
      onClick={() => task.changeState(!isComplete)}
    >
      { !isComplete ? <i className="fa fa-check" /> : <i className="fa fa-undo" /> }
      { !isComplete ? <span className="label">Complete</span> : <span className="label">Unfinish</span> }
    </button>
  );
}

export default CompleteButton;
