/**
*
* ReportWrapper
*
*/

import React, { useEffect, useState } from 'react';


import { useDataContext } from 'utils/context';
import moment from 'moment';

import ReportSummary from '../ReportSummary/ReportSummary';
import ReportCard from '../ReportCard/ReportCard';
// import ReportBreakdown from '../ReportBreakdown/ReportBreakdown';
import { formatDuration, isDateInRange } from '../../../utils/date';
import { formatCurrency } from '../../../utils/constants';
import { rollups, sum } from 'd3-array';

import './ReportWrapper.css';
import ReportTimelineChart from '../../../components/ReportTimelineChart/ReportTimelineChart';

export const ROUTE_PROGRESS = 'progress';
export const ROUTE_FINANCE = 'finance';
export const ROUTE_TIME = 'time';

const dFormat = 'YYYY-MM-DD';

const msDay = 3600000;
const buildDataPoint = (id, item, date, value) => {
  const project = item.getParent();
  return {
    id,
    itemId: item.id,
    projectId: project.id,
    projectTitle: project.properties.title,
    title: item.properties.title,
    item,
    project,
    date,
    value,
    x: moment(date).format(dFormat),
    y: value
  }
}

const completedFilter = (data, startDate, endDate, valueFunctor) => {
  const filteredData = data.filter(({ properties }) => isDateInRange(properties.completedDate, startDate, endDate));
  const mappedData = filteredData.map((item) => {
    const { properties } = item;
    const value = valueFunctor(item);
    const date = new Date(properties.completedDate);

    return buildDataPoint(item.id, item, date, value);
  });

  return mappedData;
}

const timeFilter = (startDate, endDate) => (({ properties, sessions, ...task }) => {
  if (!sessions || !sessions.length){
    return false;
  }

  const sessionL = sessions.length;
  for (let i = 0; i < sessionL; i++) {
    if (isDateInRange(sessions[i].end, startDate, endDate)) {
      return true;
    }
  }

  return false;
});

const valueGetter = {
  [ROUTE_PROGRESS]: ({ properties }) => (properties.completed ? 1 : 0),
  [ROUTE_FINANCE]: ({ stats, properties, getParent }) => {
    const project = getParent();
    const costTrackingType = project.properties.costTracking;

    if (costTrackingType === 'added') {
      return properties.fixedValue;
    }

    if (costTrackingType === 'perhour') {
      return stats.hourlyValue;
    }

    return 0;
  },
  [ROUTE_TIME]: ({ duration, end }) => duration && end ? duration / msDay : 0,
};

const legendGetter = {
  [ROUTE_PROGRESS]: "Completed",
  [ROUTE_FINANCE]: "Currency Recieved",
  [ROUTE_TIME]: "Time Spent",
}

const totalFormatGetter = {
  [ROUTE_PROGRESS]: value => `${value} Completed`,
  [ROUTE_FINANCE]: value => formatCurrency(value),
  [ROUTE_TIME]: value => `${formatDuration(value * msDay)} hrs`,
};

const axisFormatter = {
  [ROUTE_PROGRESS]: value => value,
  [ROUTE_FINANCE]: value => formatCurrency(value, true),
  [ROUTE_TIME]: decHour => {

    const decMin = decHour * 60;
    const hours = Math.floor(decHour % 24);

    if (hours >= 1) {
      const minutes = Math.floor(decMin % 60).toString();
      const paddedMins = minutes === '0' ? '' : `:${minutes.padStart(2, '0')}`;
      return `${hours}${paddedMins} hr${decHour !== 1 ? 's' : ''}`;
    }

    if (decMin >= 1) {
      return `${Math.round(decMin * 100) / 100} m`;
    }

    const decSecs = decMin * 60;
    return `${Math.round(decSecs * 100) / 100} s`;
  }
};

const sortGetter = {
  date: (a, b) => b.date - a.date,
  [ROUTE_PROGRESS]: (a, b) => b.date - a.date,
  [ROUTE_FINANCE]: (a, b) => b.value - a.value,
  [ROUTE_TIME]: (a, b) => b.value - a.value,
};

const coreDataGetter = {
  [ROUTE_PROGRESS]: (data, startDate, endDate) => completedFilter(data, startDate, endDate, valueGetter[ROUTE_PROGRESS]),
  [ROUTE_FINANCE]: (data, startDate, endDate) => completedFilter(data, startDate, endDate, valueGetter[ROUTE_FINANCE]),
  [ROUTE_TIME]: (data, startDate, endDate) => {
    const valueFunctor = valueGetter[ROUTE_TIME];
    const filteredData = data.filter(timeFilter(startDate, endDate))
    const mappedDate = filteredData.map(item => {
      const validSessions = item.sessions.filter(({ end }) => isDateInRange(end, startDate, endDate));

      return validSessions.map((session, i) => {
        const date = new Date(session.end);
        const value = session.end ? valueFunctor(session) : 0;
        return buildDataPoint(`${item.id}_${i}`, item, date, value);
      })
    }).flat();
    return mappedDate;
  },
}

const timeBuckets = (mStart, mEnd, steps, bucket) => {
  const thisYear = moment().format('YYYY');
  const today = moment().format(dFormat);

  const base = { mStart, mEnd,  steps };
  const buckets = {
    quarter: {
      ...base,
      keyFunctor: (d) => moment(d, dFormat).startOf('quarter').format(dFormat),
      labelFormatter: (d) => {
        const mDate = moment(d, dFormat);
        return `${mDate.format('YYYY')} Q${mDate.quarter()}`;
      },
      step: function (date, step) {
        
        if (step === 0) return date.format(dFormat);

        const next = moment(date).add(step, 'Q');
        return next.format(dFormat)
      },
    },
    month: {
      ...base,
      keyFunctor: (d) => moment(d, dFormat).startOf('month').format(dFormat),
      labelFormatter: (d) => {
        const mDate = moment(d, dFormat);
        const month = mDate.format('MMMM');
        const year = mDate.format('YYYY');

        if (year === thisYear) {
          return month;
        }

        return `${month}&&${year}`;
      },
      step: function (date, step) {
        
        if (step === 0) return date.format(dFormat);

        const next = moment(date).add(step, 'M');
        return next.format(dFormat)
      },
    },
    week: {
      ...base,
      keyFunctor: (d) => moment(d, dFormat).startOf('isoWeek').format(dFormat),
      labelFormatter: (d) => {
        const wStart = moment(d, dFormat).startOf('isoWeek')
        const wEnd = moment(d, dFormat).endOf('isoWeek');
        const sMnth = wStart.format('MMM');
        const sDay = wStart.format('Do');
        const eMnth = wEnd.format('MMM');
        const eDay = wEnd.format('Do');
        const eYear = wEnd.format('YYYY');

        let strBuilder = `${sMnth}`;

        if (sMnth !== eMnth) {
          strBuilder += ` ${sDay}&&- ${eMnth} ${eDay}`;
        } else {
          strBuilder += `&&${sDay} - ${eDay}`;
        }

        if (eYear !== thisYear) {
          strBuilder += `&&${eYear}`;
        }

        return strBuilder;
      },
      step: function (date, step) {
        
        if (step === 0) return date.format(dFormat);

        const next = moment(date).add(step * 7, 'd');
        return next.format(dFormat)
      },
    },
    day: {
      ...base,
      keyFunctor: (d) => d,
      labelFormatter: (d) => {
        if (d.x === today) {
          return 'Today';
        }

        const date = moment(d, dFormat);
        const year = date.format('YYYY');
        const pretty = date.format('MMM Do');

        if (year === thisYear) {
          return pretty;
        }

        return `${pretty}&&${year}`
      },
      step: function (date, step) {
        
        if (step === 0) return date.format(dFormat);

        const next = moment(date).add(step, 'd');
        return next.format(dFormat)
      },
    },
  }

  return buckets[bucket];
};

function calculateFullScale(rangeConfig) {
  const out = [];
  for (let i = 0; i < rangeConfig.steps; i++) {
    out.push({ x: rangeConfig.step(rangeConfig.mStart, i), y: 0 })
  }
  return out;
}

function bucketData(data, dStart, dEnd) {

  const monthsDiff = Math.abs(dStart.diff(dEnd, 'months', true));
  const daysDiff = Math.abs(dStart.diff(dEnd, 'days', true));
  let functor;

  if (monthsDiff > 18) {
    functor = timeBuckets(dStart, dEnd, Math.ceil(monthsDiff / 4), 'quarter')
  } else  if (monthsDiff > 4) {
    functor = timeBuckets(dStart, dEnd, monthsDiff, 'month')
  } else if (daysDiff > 31) {
    functor = timeBuckets(dStart, dEnd, daysDiff / 7, 'week')
  } else {
    functor = timeBuckets(dStart, dEnd, daysDiff, 'day')
  }

  const scale = [...calculateFullScale(functor), ...data];
  const nested_data = rollups(scale, v => sum(v, d => d.y ), ({ x }) => functor.keyFunctor(x))
  nested_data.sort(([a],[b]) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  })

  const labels = [];
  const dataPoints = [];
  nested_data.forEach(valueTuple => { 
    labels.push(functor.labelFormatter(valueTuple[0]));
    dataPoints.push(valueTuple[1]);
  });

  return {
    labels,
    dataPoints
  };
}

const groupData = (data) => {
  const groupTemplate = {
    value: 0,
    tasks: []
  }

  const reduceGroups = (acc, task) => ({
    id: task.projectId.toString(),
    projectId: task.projectId,
    label: task.projectTitle,
    projectTitle: task.projectTitle,
    value: acc.value + task.value,
    tasks: [...(acc.tasks || []), task]
  })

  const reduceTasks = (acc, task) => ({
    ...acc,
    value: acc.value + task.value
  })

  const groupedTasks = rollups(data, v => v.reduce(reduceTasks), d => d.itemId);
  const flatTasks = groupedTasks.map(group => group[1])
  const groupedData = rollups(flatTasks, v => v.reduce(reduceGroups, groupTemplate), d => d.projectId)
  
  return groupedData.map(group => group[1]).sort((a, b) => b.value - a.value);
}

function ReportWrapper({ value, startDate, endDate }) {
  const data = useDataContext();
  const [groupedData, setGroupedData] = useState([]);
  // const [reportData, setReportData] = useState([]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const getCoreData = coreDataGetter[value];
    const coreData = getCoreData(data.tasks, startDate, endDate, value);
    const nextReportData = [...coreData].filter(({ value }) => value > 0).sort(sortGetter['date']);
    const nextGroupedData = groupData(nextReportData);
    const nextChartData = bucketData(nextReportData, startDate, endDate);

    setGroupedData(nextGroupedData);
    setChartData(nextChartData);
    // setReportData(nextReportData);
  }, [data.tasks, startDate, endDate, value]);

  return (
    <div className="reportWrapper">
      <div className="reportRow">
        <ReportCard>
          <div style={{ height: '300px' }}>
            <ReportTimelineChart
              legend={legendGetter[value]}
              formatter={axisFormatter[value]}
              data={chartData}
            />
          </div>
        </ReportCard>
      </div>
      <div className="reportRow">
        <ReportCard>
          <ReportSummary axisFormatter={axisFormatter[value]} formatter={totalFormatGetter[value]} data={groupedData} />
        </ReportCard>
      </div>
    </div>
  );
}

export default ReportWrapper;
